import React, { useEffect } from "react";
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import QueueAnim from "rc-queue-anim";
import DataGrid, {
  Column, Export, FilterRow, 
  GroupPanel, HeaderFilter,
  Pager, Paging, SearchPanel,
  Sorting,
  Summary, Lookup, GroupItem
} from "devextreme-react/data-grid";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import { handleError } from "../../../util/Contexts/ErrorContext";
import { hasRole } from "../../../util/Contexts/UserContext";
import {API_BASE_URL, API_AUTENTICATION_HEADERS } from "../../../assets/constants";
import axios from 'axios';
import { makeStyles } from "@material-ui/core/styles";
import {primaryColor, secondaryColor} from "../../../assets/jss/material-dashboard-react";
import {TraceSpinner} from "react-spinners-kit";
import esMessages from 'devextreme/localization/messages/es.json';
import { locale, loadMessages } from 'devextreme/localization';
import {getQuestions} from "../../../util/Contexts/QualifyContext";
import {getTicketsQualify} from "../../../util/Contexts/TicketContext";

const styles = {
  cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
          color:  primaryColor[0],
          margin: "0",
          fontSize: "14px",
          marginTop: "0",
          marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
          color:  primaryColor[0]
      }
  },
  cardTitleWhite: {
      color:  primaryColor[0],
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
          color: "#777",
          fontSize: "65%",
          fontWeight: "400",
          lineHeight: "2"
      }
  },
  formControl: {
      margin: 1,
      minWidth: 140,
      maxWidth: "100%",
  },
  chips: {
      display: 'flex',
      flexWrap: 'wrap',
  },
  chip: {
      margin: 2,
  },
  margin: {
      margin: 1,
  }
};

const useStyles = makeStyles(styles);
loadMessages(esMessages);
locale("es");
export default function TicketList() {
  const classes = useStyles();
  const [questions, setQuestions] = React.useState([]);
  const [data, setData] = React.useState();
  const [state, setState] = React.useState({
    reportId: 0,
    newCheckBox: false,
    myTicketsCheckBox: false,
    closeCheckBox: false,
    allCheckBox: true,
    displayRole: hasRole("ADMIN") || hasRole("SUPER_USER"),
    isAsesor: hasRole("OPERATOR")
  });




  function getAllTickets() {
    getTicketsQualify().then(respuesta => {
      setData(respuesta.data.data);
    })
  }

  const getAllQuestions= async () => {
    getQuestions().then(respuesta => {
      setQuestions(respuesta.data.data);
    })
  }

    useEffect(value => {
    getAllTickets();
    getAllQuestions();
  }, [state.allCheckBox,
     state.newCheckBox, 
     state.closeCheckBox, 
     state.myTicketsCheckBox]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="secondary" spacing={10}>
            <GridContainer>
              <GridItem xl={8} md={8} xs={8}>
                <h4 className={classes.cardTitleWhite}>Evaluaciones</h4>
                <p className={classes.cardCategoryWhite}></p>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <QueueAnim>
              <div>

                <DataGrid
                  dataSource={data}
                  showBorders={true}
                  keyExpr="id"
                  wordWrapEnabled={true}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                >
                    <Export enabled={true} fileName={"Evaluaciones"} texts={{exportAll: "Exportar todos lo datos"}}
                      />
                    <FilterRow visible={true} applyFilter={"auto"} />
                    <GroupPanel visible={true} emptyPanelText={"Arrastre una columna para agrupar"} />
                    <SearchPanel visible={true}
                      width={240}
                      placeholder="Buscar..." />

                    <Paging defaultPageSize={10} />

                    <Pager
                      showPageSizeSelector={true}
                      allowedPageSizes={[5, 10, 20]}
                      showInfo={true} />
                    
                    <Sorting mode="multiple" />
                    
                    <Column dataField="ticket.id"
                    width={"70"}
                    alignment="left"
                    caption="Folio"/>

                    <Column dataField="author.completeName"
                    width={"115"} 
                      caption="Alumno"/>

                    <Column dataField="ticket.owner.completeName"
                    width={"115"}
                    visible={!state.isAsesor}
                    caption="Responsable"/>

                    <Column dataField="ticket.department.name"
                    caption="Departamento"
                    width={"115"}/>

                    {
                        questions.map((q,index) => (
                            q.questionType.id !== 1 ?
                                <Column dataField={`answers[${index}].answer`}
                                    alignment="center"
                                    cellRender={(cellInfo) => q.questionType.id === 1 ?  cellInfo.data.answers[index].comments : cellInfo.data.answers[index].answer}
                                    caption={q.title}
                            >
                            </Column>
                                :
                                <Column dataField={`answers[${index}].comments`}
                                        alignment="center"
                                        cellRender={(cellInfo) => q.questionType.id === 1 ?  cellInfo.data.answers[index].comments : cellInfo.data.answers[index].answer}
                                        caption={q.title}
                                >
                                </Column>
                        ))
                    }



                    <Column dataField="createDate"
                      dataType="date"
                      caption="Fecha"
                      allowHeaderFiltering = {false}
                    />



                </DataGrid>
              </div>
            </QueueAnim>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>

  );
}