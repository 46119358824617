import {
  defaultFont,
  container,
  primaryColor,
  grayColor,
  blackColor
} from "../../material-dashboard-react";

const footerStyle = {
  block: {
    color: "inherit",
    padding: "15px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
    ...defaultFont,
    fontWeight: "500",
    fontSize: "12px"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "10px 0",
    margin: "0",
    fontSize: "14px",
    float: "right!important"
  },
  center: {
    padding: "20px 0",
    margin: "0",
    fontSize: "25px",
    textAlign: "center!important"
  },
  footer: {
    bottom: "0",
    backgroundColor: grayColor[1],
    // borderTop: "120px solid " + grayColor[1],
    padding: "40px 0",
    ...defaultFont
  },
  container,
  a: {
    color: primaryColor,
    textDecoration: "none",
    backgroundColor: "transparent",
    fontFamily: 'Avenir Next LT Pro ULTLTCN',
    fontSize: '18px'
  },
  title: {
    color: primaryColor,
    textDecoration: "none",
    backgroundColor: "transparent",
    fontFamily: 'Avenir Next LT Pro Cn'
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  }
};
export default footerStyle;
