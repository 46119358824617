import React, {useEffect} from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Group from "@material-ui/icons/Group";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
// core components
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import CardIcon from "../../../components/Card/CardIcon.js";
import { Link } from 'react-router-dom';
import styles from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import {handleError} from "../../../util/Contexts/ErrorContext";
import Typography from '@material-ui/core/Typography';
import {getDataDashboard, getCampusBarChartData, getDepartmentBarChartData, getPriorityPieChartData, getTicketBarChartData, getCategoriasByUser } from "../../../util/Contexts/DashboardContext";
import Divider from '@material-ui/core/Divider';
import Grid from "@material-ui/core/Grid";
import {
  IconButton,
  Popover,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
  Checkbox
} from "@material-ui/core";
//Graficas
import PieChartPriorityReports from "./PieChartPriorityReports";
import AreaChartRecordReports from "./AreaChartRecordReports";
import BarChartReports from "./BarChartReports";
//DatePicker
import { format } from 'date-fns';
import {es} from "date-fns/locale";
import { DateRangePicker } from 'react-date-range';
import { defaultInputRanges, defaultStaticRanges} from "../../../util/customRange";
import InputAdornment from "@material-ui/core/InputAdornment";
import {CalendarToday} from "@material-ui/icons";
import { startOfMonth } from 'date-fns';

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    allUsers: 0,
    allTickets: 0,
    ticketsActive: 0,
    ticketsClose: 0,
    serviceSheets: 0,
    chartDepartment: [],
    labelDepartmen: ""
  });
  const [ticketsDepartment, setTicketsDepartment] = React.useState({
    data: [],
    labels: []
  });
  const [ticketsCampus, setTicketsCampus] = React.useState({
    data: [],
    labels: []
  });
  const [ticketPriority, setTicketPriority] = React.useState({
    data: [],
    labels: [],
    colors: []
  });
  const[categories, setCategories] = React.useState([]);
  const [selectedCategories, setSelectedCategories] = React.useState([]);
  const[lineChartData, setLineChartData] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  //Por motivos de demostracion, se cambio el rango por defecto a la fecha de inicio, para regresar a la
  //logica de mostrar el mes actual descomentar el startDate y eliminar el que se esta usando en este momento
  const [range, setRange] = React.useState([
    {
      // startDate: startOfMonth(new Date()),
      startDate: new Date('2021-06-14T00:00:00'),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const refOne = React.useRef(null);

  const handleChange = (event, child) => {
    const value = child.props.value;
    const currentIndex = selectedCategories.indexOf(value[0]);
    let newSelected = [...selectedCategories];

    if (currentIndex === -1) {
      newSelected = newSelected.concat(value);
    } else {
      for (let i = 0; i < value.length; i++) {
        const index = newSelected.indexOf(value[i]);
        newSelected.splice(index, 1);
      }
    }
    setSelectedCategories(newSelected);
  };

  function getData() {
    getCampusBarChartData(range, selectedCategories).then(({data}) => {
      console.log(data);
      const campuses = data.data;
      const campusLabels = campuses.map(campus => campus.campusName);
      const ticketsCampus = [
        {
          name: 'Sin asignar',
          data: campuses.map(campus => campus.ticketsSinAsignar)
        },
        {
          name: 'Activos',
          data: campuses.map(campus => campus.ticketsActivos)
        },
        {
          name: 'Cerrados',
          data: campuses.map(campus => campus.ticketsCerrados)
        }
      ];
      setTicketsCampus({
        data: ticketsCampus,
        labels: campusLabels
      });
    });

    getPriorityPieChartData(range, selectedCategories).then(({data}) => {
      console.log(data.data);
      const categorias = data.data
      setTicketPriority({
        data: categorias.map(count => count.ticketCount),
        labels: categorias.map(categoria => categoria.priorityName),
        colors: categorias.map(color => color.color)
      })
    })

    getTicketBarChartData(range, selectedCategories).then(({data}) => {
      console.log(data.data);
      setLineChartData(data.data);
    })

    getDepartmentBarChartData(range, selectedCategories).then(({data}) => {
      const departments = data.data;
      const categoriesDepartamentos = departments.map(department => department.departmentName);
      const dataDepartamentos = [
        {
          name: 'Sin asignar',
          data: departments.map(department => department.ticketsSinAsignar)
        },
        {
          name: 'Activos',
          data: departments.map(department => department.ticketsActivos)
        },
        {
          name: 'Cerrados',
          data: departments.map(department => department.ticketsCerrados)
        }
      ];
      setTicketsDepartment({
        data: dataDepartamentos,
        labels: categoriesDepartamentos
      });
    });
    getDataDashboard(range, selectedCategories).then(({data}) => {
      setState({
        ...state,
        allUsers: data.data.userCount,
        allTickets: data.data.allTicketCount,
        ticketsActive: data.data.ticketsActive,
        ticketsClose: data.data.ticketClose,
        serviceSheets: data.data.serviceSheets
      });
    })
  }

  function applyFilter(){
    getData();
  }

  useEffect(value =>{
    getData();
    getCategoriasByUser().then(({data}) => {
      console.log(data.data);
      setCategories(data.data);
    })
  },[]);

  return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Grid container justifyContent="flex-end" spacing={3}>
              <Grid item>
                <FormControl style={{width: '240px'}}>
                  <TextField
                      id='dates'
                      name='dates'
                      label={"Fechas"}
                      value={(range[0].startDate != null && range[0].endDate != null) ? `${format(range[0].startDate, 'yyyy-MM-dd')} a ${format(range[0].endDate, 'yyyy-MM-dd')}` : ''}
                      readOnly
                      onClick={ (event) => setAnchorEl(event.currentTarget) }
                      InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                              <CalendarToday />
                            </InputAdornment>
                        ),
                      }}
                  />
                  <div ref={{ refOne }}>
                    {anchorEl &&
                        <Popover
                            open={anchorEl ?? false}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(null)}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                        >
                          <DateRangePicker
                              staticRanges={defaultStaticRanges}
                              inputRanges={defaultInputRanges}
                              locale={es}
                              onChange={item => setRange([item.selection])}
                              editableDateInputs={true}
                              moveRangeOnFirstSelection={false}
                              ranges={range}
                              showPreview={false}
                              showSelectionPreview={false}
                              shownDate={false}
                              showDateDisplay={false}
                              maxDate={new Date()}
                              months={2}
                              rangeColors={['#ffd000']}
                              direction='horizontal'
                          />
                        </Popover>
                    }
                  </div>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl style={{width: '250px'}}>
                  <InputLabel id="demo-mutiple-name-label">Categorías</InputLabel>
                  <Select
                      labelId="demo-mutiple-name-label"
                      id="demo-mutiple-name"
                      multiple
                      value={selectedCategories}
                      onChange={handleChange}
                      renderValue={(selected) => {
                        return categories
                            .filter(category =>
                                category.categoryIds.some(categoryId => selected.includes(categoryId)))
                            .map(category => category.name)
                            .join(', ');
                      }}
                  >
                    {categories.map((category, index) => (
                        <MenuItem key={index} value={category.categoryIds}>
                          <Checkbox checked={category.categoryIds.some(categoryId => selectedCategories.includes(categoryId))} />
                          {category.name}
                        </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item style={{ marginTop: '16px' }}>
                <Button variant="contained" color="secondary" onClick={applyFilter}>
                  Aplicar Filtro
                </Button>
              </Grid>
            </Grid>
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <Link to="/admin/users">
              <Card>
                <CardHeader color="info" stats icon>
                  <CardIcon color="info">
                    <Icon>
                      <Group/>
                    </Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>Total de Alumnos</p>
                  <h3 className={classes.cardTitle}>
                    {state.allUsers}
                  </h3>
                </CardHeader>
              </Card>
            </Link>
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <Link to="/admin/request?filter=all">
              <Card>
                <CardHeader color="success" stats icon>
                  <CardIcon color="success">
                    <Icon>
                      <ConfirmationNumberIcon/>
                    </Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>Total de solicitudes</p>
                  <h3 className={classes.cardTitle}>{state.allTickets}</h3>
                </CardHeader>
              </Card>
            </Link>
          </GridItem>

          <GridItem xs={12} sm={6} md={6}>
            <Link to='/admin/request?filter=open'>
              <Card>
                <CardHeader color="warning" stats icon>
                  <CardIcon color="warning">
                    <Icon>
                      <LockOpenIcon/>
                    </Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>Solicitudes activas</p>
                  <h3 className={classes.cardTitle}>{state.ticketsActive}</h3>
                </CardHeader>
              </Card>
            </Link>
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <Link to='/admin/request?filter=closed'>
              <Card>
                <CardHeader color="primary" stats icon>
                  <CardIcon color="primary">
                    <Icon>
                      <LockIcon/>
                    </Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>Solicitudes cerradas</p>
                  <h3 className={classes.cardTitle}>{state.ticketsClose}</h3>
                </CardHeader>
              </Card>
            </Link>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <Divider />

          </GridItem>

          <GridItem xs={12} sm={6} md={6}>
            <PieChartPriorityReports data={ticketPriority.data} labels={ticketPriority.labels} colors={ticketPriority.colors} />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <AreaChartRecordReports data={lineChartData} />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <BarChartReports data={ticketsCampus.data} categories={ticketsCampus.labels} title={"Reporte por Campus"} />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <BarChartReports data={ticketsDepartment.data} categories={ticketsDepartment.labels} title={"Reporte por Departamentos"} />
          </GridItem>
        </GridContainer>


      </div>
  );
}