import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh"
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${theme.drawerWidth}px)`
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...theme.transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  content: {
    marginTop: "70px",
    padding: "30px 30px",
    minHeight: "calc(100vh - 123px)"
  },
  ...theme.customContainer.layoutContainer,
  map: {
    marginTop: "70px"
  }
}));
