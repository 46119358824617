import React, {useEffect} from "react";
import htmlToDraft from 'html-to-draftjs';
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import CardBody from "../../../components/Card/CardBody.js";
import QueueAnim from "rc-queue-anim";
import Alert from "react-s-alert";
import {primaryColor, secondaryColor} from "../../../assets/jss/material-dashboard-react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl, InputLabel, Select,
    TextField
} from "@material-ui/core";
import DataGrid, {
    Column, Export, FilterRow,
    GroupPanel, HeaderFilter,
    Pager, Paging, Editing,
    SearchPanel, Sorting
} from "devextreme-react/data-grid";
import {TraceSpinner} from "react-spinners-kit";
import {addCategory, getAllCategory, updateCategory} from "../../../util/Contexts/CategoryContext";
import {handleError} from "../../../util/Contexts/ErrorContext";
import {getAllDepartmets, getAllDepartmentsEnabled} from "../../../util/Contexts/DepartmentContext";
import esMessages from 'devextreme/localization/messages/es.json';
import { locale, loadMessages } from 'devextreme/localization';
import {
    addFrequentAnswers,
    getAllFrequentAnswers,
    updateFrequentAnswers
} from "../../../util/Contexts/FrequentAnswersContext";
import {Editor} from "react-draft-wysiwyg";
import {TOOLBAR_OPTIONS} from "../../../assets/constants";
import {ContentState, EditorState} from "draft-js";
import {hasRole} from "../../../util/Contexts/UserContext";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color:  primaryColor[0],
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color:  primaryColor[0]
        }
    },
    cardTitleWhite: {
        color: primaryColor[0],
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const useStyles = makeStyles(styles);


export default function FrequentAnswersList() {

    const classes = useStyles();
    const [data, setData] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [state, setState] = React.useState({
        stateSelected: 0,
        filter: "",
        name: "",
        id: 0,
        description: EditorState.createEmpty(),
        edit: false,
        officeAsigned: 1
    });
    const [offices, setOffices] = React.useState([]);

    loadMessages(esMessages);
    locale("es");

    function handleClickOpen(row) {
        var item = null;
        if(row.row != null){
            for(let i = 0; i < data.length; i++){
                if(row.row.key === data[i].id){
                    item = data[i]
                }
            }
        }
        item ? setState({
            ...state,
            name: item.title,
            id: item.id,
            description:  EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(item.content))),
            officeAsigned: item.department.id,
            edit: true
        }) :  setState({
            ...state,
            name: "",
            description: EditorState.createEmpty(),
            officeAsigned: 1,
            edit: false
        });
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    const handleChange = event => {
        setState( {
            ...state,
            [event.target.name] : event.target.value
        });
    };

    function enable(rowData) {
        var data = {
            title: rowData.row.data.title,
            content: EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(rowData.row.data.content))),
            enabled:  !rowData.row.data.enabled,
            department: rowData.row.data.department
        }
        updateFrequentAnswers(rowData.row.data.id, data).then(respuesta => {
            Alert.success(respuesta.data.message);
            handleClose();
            getAllData();
        } )
    }


    const updateData =  (event)  => {
        event.preventDefault();
        var item = null;
        for(let i = 0; i < offices.length; i++){
            if(state.officeAsigned == offices[i].id){
                item = offices[i]
            }
        }
        let newData = {
            title: state.name,
            content: state.description,
            enabled: true,
            department: item
        }
        updateFrequentAnswers(state.id,newData).then(respuesta => {
            Alert.success(respuesta.data.message);
            handleClose();
            getAllData();
        } )
    };

    const getAllData = () => {
        getAllFrequentAnswers().then(respuesta => {
            setData(respuesta.data.data);
        } )
    };

    useEffect(value => {
        getAllData();
        getDepartments()
    }, []);

    const addData = (event) => {
        event.preventDefault();
        addFrequentAnswers(state).then(respuesta => {
            Alert.success(respuesta.data.message);
            setState({
                ...state,
                name: "",
                description: EditorState.createEmpty()
            });
            handleClose();
            getAllData();
        } )
    };

    function getDepartments() {
        getAllDepartmentsEnabled().then(respuesta => {
            setOffices(respuesta.data.data);
        })

    }

    function onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'plus',
                onClick: handleClickOpen.bind()
            }
        });
    }
    const onContentStateChange = (contentState) => {
        setState({
            ...state,
            description: contentState,
        });
    };

    return (
        <div>
                    <GridContainer>
                        <Dialog open={open} onClose={handleClose}>
                            <DialogTitle id="form-dialog-title">{state.edit? "Editar respuesta rápida" : "Agregar respuesta rápida"}</DialogTitle>
                            <form onSubmit={state.edit? updateData: addData}>
                                <DialogContent>
                                    <TextField
                                        required={true}
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        name="name"
                                        value={state.name}
                                        onChange={handleChange}
                                        label="Título"
                                        type="text"
                                        fullWidth
                                    />
                                    <Editor
                                        wrapperClassName="wrapper-class"
                                        editorClassName="editor-class"
                                        toolbarClassName="toolbar-class"
                                        placeholder="Descripción"
                                        editorState={state.description}
                                        onEditorStateChange={onContentStateChange}
                                        toolbar={TOOLBAR_OPTIONS}

                                    />
                                    <FormControl fullWidth required>
                                        <InputLabel htmlFor="officeAsigned">Departamentos</InputLabel>
                                        <Select
                                            required
                                            value={state.officeAsigned}
                                            onChange={handleChange}
                                            inputProps={{
                                                name: 'officeAsigned',
                                                id: 'officeAsigned',
                                            }}
                                        >
                                            <option value="" />
                                            {offices.map(function(value) { return (<option key={value.name} value={value.id} > {value.name}</option> );})}
                                        </Select>
                                    </FormControl>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} color="primary">
                                        Cancelar
                                    </Button>
                                    <Button type={"submit"}  color="primary">
                                        Guardar
                                    </Button>
                                </DialogActions>
                            </form>
                        </Dialog>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="secondary">
                                    <h4 className={classes.cardTitleWhite}>Listado de respuestas rápidas</h4>
                                    <p className={classes.cardCategoryWhite}>

                                    </p>
                                </CardHeader>
                                <CardBody>
                                    <QueueAnim>
                                        <DataGrid
                                            dataSource={data}
                                            showBorders={true}
                                            keyExpr="id"
                                            allowColumnReordering={true}
                                            allowColumnResizing={true}
                                            onToolbarPreparing={onToolbarPreparing}>

                                            {/* <Export enabled={true} fileName={"Tickets"} texts={{exportAll: "Exportar todos lo datos"}}/>   */}

                                            <FilterRow visible={true} applyFilter={"auto"} />

                                            <HeaderFilter visible={true} />

                                            {/*<GroupPanel visible={true} emptyPanelText={"Arrastre una columna para agrupar"} />*/}

                                            <SearchPanel visible={true}
                                                         width={240}
                                                         placeholder="Buscar..." />

                                            <Paging defaultPageSize={10} />

                                            <Pager
                                                showPageSizeSelector={true}
                                                allowedPageSizes={[5, 10, 20]}
                                                showInfo={true} />

                                            <Sorting mode="multiple" />

                                            <Column
                                                dataField="title"
                                                caption="Título"/>

                                            <Column
                                                dataField="content"
                                                cellRender={({row}) => <div align={"left"} > {row.data.content.replace(/<\/?[^>]+(>|$)/g, "")}</div>}
                                                caption="Contenido"/>

                                            <Column
                                                dataField="department.name"

                                                caption="Departemento"/>

                                            <Column
                                                dataField="statusEnum.description"
                                                caption="Estatus"/>

                                            <Column type="buttons"
                                                    visible={hasRole("SUPER_USER")}
                                                    caption="Acciones"
                                                    width={80}
                                                    buttons={ [{
                                                        hint: 'Deshabilitar',
                                                        icon: 'remove',
                                                        visible: (e) => (e.row.data.enabled && hasRole("SUPER_USER")),
                                                        onClick: enable
                                                    },
                                                        {
                                                            hint: 'Habilitar',
                                                            icon: 'check',
                                                            visible: (e) => (!e.row.data.enabled && hasRole("SUPER_USER")),
                                                            onClick: enable
                                                        }, {
                                                            hint: 'Editar',
                                                            icon: 'edit',
                                                            visible: hasRole("SUPER_USER"),
                                                            onClick: handleClickOpen
                                                        }]}/>
                                        </DataGrid>
                                    </QueueAnim>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>

        </div>


    );
}