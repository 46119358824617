import {
    API_AUTENTICATION_HEADERS,
    API_BASE_URL,
    API_BASIC_HEADERS,
    API_MULTIPART_HEADERS
} from "../../assets/constants";
import axios from "axios";
import {trackPromise} from "react-promise-tracker";

const headers = API_AUTENTICATION_HEADERS;
const headersBasic = API_MULTIPART_HEADERS;

function getAllPrioritys() {
    const url = API_BASE_URL + "/api/prioritys";
   let result;
    trackPromise(
        result = axios.get(url, {headers} )
    )
    return result;
}

function updatePriority (state) {
    const url = API_BASE_URL + "/api/priority/" + state.id;
    console.log(state)
   let result;
    trackPromise(
        result = axios.put(url,Object.assign({}, {name: state.name, description: state.description, lowHour: state.hourSilder[0], highHour: state.hourSilder[1], enabled: state.enabled, color: state.color, emails: state.emails}),{headers} )
    )
    return result;
}

function enablePriority(state){
    const url = API_BASE_URL + "/api/priority/enable/" + state.id;
    console.log(state)
    var bodyFormData = new FormData();
    bodyFormData.append("enabled", state.enabled);
   let result;
    trackPromise(
        result = axios.put(url, bodyFormData, {headers} )
    )
    return result;
}

function addPriority (state) {
    const url = API_BASE_URL + "/api/priority";
   let result;
    trackPromise(
        result = axios.post(url, Object.assign({}, {name: state.name, description: state.description, lowHour: state.hourSilder[0], highHour: state.hourSilder[1], enabled: true, color: state.color, emails: state.emails})  ,{headers} )
    )
    return result;
}

function getAllPrioritysEnabled (){
    const url = API_BASE_URL + "/api/prioritys/enabled";
    const headers = API_AUTENTICATION_HEADERS;
   let result;
    trackPromise(
        result = axios.get(url, {headers} )
    )
    return result;
}

function changePriority (id, priority){
    var bodyFormData = new FormData();
    bodyFormData.append("priority", priority);
    const url = API_BASE_URL + "/api/ticket/" + id +"/priority";
    const headers = headersBasic;
   let result;
    trackPromise(
        result = axios.put(url, bodyFormData,{headers} )
    )
    return result;
}

function changeCategory (id, priority){
    var bodyFormData = new FormData();
    bodyFormData.append("category", priority);
    const url = API_BASE_URL + "/api/ticket/" + id +"/category";
    const headers = headersBasic;
   let result;
    trackPromise(
        result = axios.put(url, bodyFormData,{headers} )
    )
    return result;
}

function removePriority(id){
    const url = API_BASE_URL + "/api/priority/" + id;

   let result;
    trackPromise(
        result = axios.delete(url,{headers} )
    )
    return result;
}

export {  getAllPrioritys, updatePriority, addPriority, getAllPrioritysEnabled, changePriority, enablePriority, removePriority, changeCategory};