import axios from 'axios'
import { ACCESS_TOKEN, USER_SESSION } from "../assets/constants";
import Alert from "react-s-alert";

function errorResponseHandler(error) {
    // check for errorHandle config

    // if has response show the error
    if (error.response) {
            if (error.response.status === 401) {
              localStorage.removeItem(ACCESS_TOKEN);
              localStorage.removeItem(USER_SESSION);
              localStorage.clear();
              window.location.reload();
            } else if (error.response.status === 404){
              Alert.warning("No se obtuvo respuesta. Inténtelo más tarde");
            }else if (error.response.data) {

              Alert.warning(error.response.data.message);
            }else {
                Alert.warning(error.message);
            }
    }
    return Promise.reject(error);
}



export default errorResponseHandler;