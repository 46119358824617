import {
    API_AUTENTICATION_HEADERS,
    API_BASE_URL
} from "../../assets/constants";
import axios from "axios";
import {trackPromise} from "react-promise-tracker";
import {format} from "date-fns";


function getDataDashboard(range, categoryIds) {
    const url = API_BASE_URL + "/api/dashboard";
    const headers = API_AUTENTICATION_HEADERS;

    const params = new URLSearchParams();

    params.append('startDate', range[0].startDate != null ? format(range[0].startDate, 'yyyy-MM-dd') : null);

    params.append('endDate', range[0].endDate != null ? format(range[0].endDate, 'yyyy-MM-dd') : null);

    if (categoryIds && Array.isArray(categoryIds) && categoryIds.length) {
        categoryIds.forEach(id => params.append('categoryIds', id));
    } else {
        params.append('categoryIds', -1)
    }

    let result;
    trackPromise(
        result = axios.get(url, { headers, params })
    )
    return result;
}
function getCampusBarChartData (range, selectedCategories) {
    const url = API_BASE_URL + "/api/dashboard/getBarChartDataByCampus";
    const headers = API_AUTENTICATION_HEADERS;

    const params = new URLSearchParams();
    params.append('startDate', range[0].startDate != null ? format(range[0].startDate, 'yyyy-MM-dd') : null);
    params.append('endDate', range[0].endDate != null ? format(range[0].endDate, 'yyyy-MM-dd') : null);

    if (selectedCategories && Array.isArray(selectedCategories) && selectedCategories.length) {
        selectedCategories.forEach(id => params.append('categoryIds', id));
    } else {
        params.append('categoryIds', -1)
    }

    let result;
    trackPromise(
        result = axios.get(url, { headers, params })
    )
    return result;
}

function getDepartmentBarChartData (range, selectedCategories) {
    const url = API_BASE_URL + "/api/dashboard/getBarChartDataByDepartment";
    const headers = API_AUTENTICATION_HEADERS;

    const params = new URLSearchParams();
    params.append('startDate', range[0].startDate != null ? format(range[0].startDate, 'yyyy-MM-dd') : null);
    params.append('endDate', range[0].endDate != null ? format(range[0].endDate, 'yyyy-MM-dd') : null);

    if (selectedCategories && Array.isArray(selectedCategories) && selectedCategories.length) {
        selectedCategories.forEach(id => params.append('categoryIds', id));
    } else {
        params.append('categoryIds', -1)
    }

    let result;
    trackPromise(
        result = axios.get(url, { headers, params })
    )
    return result;
}

function getTicketBarChartData(range, selectedCategories){
    const url = API_BASE_URL + "/api/dashboard/getBarChartData";

    const headers = API_AUTENTICATION_HEADERS;

    const params = new URLSearchParams();
    params.append('startDate', range[0].startDate != null ? format(range[0].startDate, 'yyyy-MM-dd') : null);
    params.append('endDate', range[0].endDate != null ? format(range[0].endDate, 'yyyy-MM-dd') : null);

    if (selectedCategories && Array.isArray(selectedCategories) && selectedCategories.length) {
        selectedCategories.forEach(id => params.append('categoryIds', id));
    } else {
        params.append('categoryIds', -1)
    }

    let result;
    trackPromise(
        result = axios.get(url, { headers, params })
    )
    return result;
}

function getPriorityPieChartData(range, selectedCategories) {
    const url = API_BASE_URL + "/api/dashboard/getPieChartData";
    const headers = API_AUTENTICATION_HEADERS;

    const params = new URLSearchParams();
    params.append('startDate', range[0].startDate != null ? format(range[0].startDate, 'yyyy-MM-dd') : null);
    params.append('endDate', range[0].endDate != null ? format(range[0].endDate, 'yyyy-MM-dd') : null);

    if (selectedCategories && Array.isArray(selectedCategories) && selectedCategories.length) {
        selectedCategories.forEach(id => params.append('categoryIds', id));
    } else {
        params.append('categoryIds', -1)
    }

    let result;
    trackPromise(
        result = axios.get(url, { headers, params })
    )
    return result;
}


function getCategoriasByUser(){
    const url = API_BASE_URL + "/api/dashboard/getCategories";
    const headers = API_AUTENTICATION_HEADERS;

    let result;
    trackPromise(
        result = axios.get(url, {headers})
    )
    return result;
}

export {getDataDashboard, getCampusBarChartData, getDepartmentBarChartData, getPriorityPieChartData, getTicketBarChartData, getCategoriasByUser}