import React from 'react';
import Chart from 'react-apexcharts';
import { es } from 'date-fns/locale';
import {format} from "date-fns";

class AreaChartRecordReports extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                colors: ['#ffd000'],
                chart: {
                    id: 'historial-reportes',
                    type: 'area',
                    height: 350,
                    toolbar: {
                        show: true
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        formatter: (value) => {
                            return format(new Date(value), 'PP', { locale: es })
                        }
                    }
                },
                title: {
                    text: 'Historial de Reportes',
                    align: 'center',
                    style: {
                        fontSize: '20px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 'bold',
                    }
                }
            },
            series: [{
                name: 'reportes',
                data: []
            }]
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            const newData = this.props.data.map(item => {
                return {
                    x: new Date(item.date).getTime(),
                    y: item.ticketCount
                };
            });

            this.setState({
                series: [{
                    name: 'reportes',
                    data: newData
                }]
            });
        }
    }

    render() {
        return (
            <div style={{
                backgroundColor: 'white',
                boxShadow: '0px 3px 15px rgba(0,0,0,0.2)',
                borderRadius: '10px',
                padding: '20px',
                margin: '20px',
                backgroundImage: 'linear-gradient(to bottom right, #fff, #eee)'
            }}>
                <Chart
                    options={this.state.options}
                    series={this.state.series}
                    type="area"
                    height="350"
                />
            </div>
        )
    }
}

export default AreaChartRecordReports;
