import {API_AUTENTICATION_HEADERS, API_BASE_URL} from "../../assets/constants";
import axios from "axios";
import {trackPromise} from "react-promise-tracker";

const headers = API_AUTENTICATION_HEADERS;


function getAllDepartmets () {
    const url = API_BASE_URL + "/api/departments/all";
    let result;
    trackPromise(
        result = axios.get(url, { headers } )
    )
    return result;
}

function getAllDepartmentsEnabled () {
    const url = API_BASE_URL + "/api/departments/enabled";
    let result;
    trackPromise(
        result = axios.get(url, { headers } )
    )
    return result;
}

function updateDepartment(id, newData) {
    const url = API_BASE_URL + "/api/department/" + id;

    let result;
    trackPromise(
        result = axios.put(url,newData,{ headers } )
    )
    return result;
}

function removeDepartment(id){
    const url = API_BASE_URL + "/api/department/" + id;

    let result;
    trackPromise(
        result = axios.delete(url,{ headers } )
    )
    return result;
}

function addDepartment (state) {
    const url = API_BASE_URL + "/api/department";
    let result;
    trackPromise(
        result = axios.post(url, Object.assign({}, {name: state.name, description: state.description, enabled: true})  ,{ headers } )
    )
    return result;

}

export { getAllDepartmets, updateDepartment, addDepartment, removeDepartment, getAllDepartmentsEnabled};