/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
// core components
import styles from "../../assets/jss/material-dashboard-react/components/footerStyle.js";
import cercanaLogo from "../../assets/img/CercanaLogo.png"
import { TextArea } from "devextreme-react";
const useStyles = makeStyles(styles);

export default function Footer(props) {
    const classes = useStyles();
    return (
        <footer className={classes.footer}>
            <div className={classes.container}>
                <div className={classes.left}>
                </div>
                <p className={classes.center}>
                    <span className={classes.title}>
                    Aviso de Privacidad
                        <a
                            href="https://www.cetys.mx/aviso-de-privacidad"
                            target="_blank"
                            className={classes.a}
                        >
                            <br /> https://www.cetys.mx/aviso-de-privacidad
                        </a> 
                    </span>
                </p>
                <p className={classes.right}>
                    <span>
                        Powered by <a
                            href="https://www.certuit.com/"
                            target="_blank"
                            className={classes.a}
                        >
                            <img src={cercanaLogo} style={{ width: "45px", heght: "15px" }}></img>
                        </a>
                    </span>
                </p>
            </div>
        </footer >
    );
}
