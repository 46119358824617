import {
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  title,
} from "../../material-kit-react.js";

const infoStyle = {
  infoArea: {
    maxWidth: "360px",
    margin: "0 auto",
    padding: "0px",
  },
  iconWrapper: {
    float: "left",
    marginTop: "24px",
    marginRight: "10px",
  },
  primary: {
    color: primaryColor,
  },
  warning: {
    color: warningColor,
  },
  danger: {
    color: dangerColor,
  },
  success: {
    color: successColor,
  },
  info: {
    color: infoColor,
  },
  rose: {
    color: roseColor,
  },
  gray: {
    color: grayColor,
  },
  icon: {
    width: "36px",
    height: "36px",
  },
  descriptionWrapper: {
    color: grayColor,
    overflow: "hidden",
  },
  description: {
    color: grayColor,
    overflow: "hidden",
    marginTop: "0px",
    fontSize: "14px",
  },
  iconWrapperVertical: {
    float: "none",
  },
  iconVertical: {
    width: "61px",
    height: "61px",
  },
  subtitle: {
    marginTop: "5px",
    minHeight: "32px",
    textDecoration: "none",
    color: '#000000',
    fontFamily: 'Avenir Next LT Pro Cn',
    fontSize: "25px",
    fontStyle: 'normal'
  },
  content: {
    marginTop: "5px",
    marginRight: "20px",
    marginLeft: "20px",
    minHeight: "32px",
    textDecoration: "none",
    fontSize: "20px",
    color: '#B4B4B4',
    fontFamily: 'Avenir Next LT Pro Cnit',
  },
};

export default infoStyle;
