import React, { useEffect } from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../components/Grid/GridContainer.js";
import Card from "../../../../components/Card/Card.js";
import CardHeader from "../../../../components/Card/CardHeader.js";
import CardBody from "../../../..//components/Card/CardBody.js";
import axios from 'axios';
import Alert from "react-s-alert";
import { API_BASE_URL, API_AUTENTICATION_HEADERS } from "../../../../assets/constants";
import { primaryColor, secondaryColor } from "../../../../assets/jss/material-dashboard-react";
import {
    Button, Dialog, DialogActions,
    DialogContent, DialogTitle, Checkbox,
    Input, InputLabel, NativeSelect, ListItemText,
    Select, FormControl, TextField, Typography
} from "@material-ui/core";
import DataGrid, {
    Column, Export, FilterRow,
    GroupPanel, HeaderFilter,
    Pager, Paging, Editing,
    SearchPanel, Sorting
} from "devextreme-react/data-grid";
import QueueAnim from "rc-queue-anim";
import MenuItem from '@material-ui/core/MenuItem';
import { TraceSpinner } from "react-spinners-kit";
import { handleError } from "../../../../util/Contexts/ErrorContext";
import esMessages from 'devextreme/localization/messages/es.json';
import { locale, loadMessages } from 'devextreme/localization';
import { createAdmin, editAdmin, enabledUserWith, getUser, getUsersAdmin } from "../../../../util/Contexts/UserContext";
import { getAllOfficesEnabled } from "../../../../util/Contexts/OfficeContext";
import { getAllRolesWithoutUser } from "../../../../util/Contexts/RolesContext";
import { getAllDepartmentsEnabled } from "../../../../util/Contexts/DepartmentContext";
import ChangePasswordDialog from "../users/ChangePasswordDialog.js";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: primaryColor[0],
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: primaryColor[0]
        }
    },
    cardTitleWhite: {
        color: primaryColor[0],
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const useStyles = makeStyles(styles);


export default function Admins() {

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [openChangePasswordDialog, setOpenChangePasswordDialog] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [roles, setRoles] = React.useState([]);
    const [offices, setOffices] = React.useState([]);
    const [departments, setDepartments] = React.useState([]);
    const [userId, setUserId] = React.useState(-1);

    const [state, setState] = React.useState({
        users: [],
        filter: "",
        rolSelected: 0,
        stateSelected: 0,
        officeAsigned: [],
        departmentsSelected: [],
        id: 0,
        name: "",
        lastname: "",
        password: "",
        confirmPassword: "",
        phone: "",
        email: "",
        assesorId: 0,
        edit: false,
        buttons: [{
            hint: 'Habilitar/Deshabilitar',
            icon: 'pinright',
            onClick: enable
        }, {
            hint: 'Editar',
            icon: 'edit',
            onClick: handleClickOpen
        }]
    });

    loadMessages(esMessages);
    locale("es");

    function handleClickOpen(row) {
        var item = null;
        if (row.row != null) {
            for (let i = 0; i < state.users.length; i++) {
                if (row.row.data.id === state.users[i].id) {
                    item = state.users[i]
                }
            }
        }
        item ? setState({
            ...state,
            id: item.id,
            name: item.name,
            phone: item.phone,
            lastname: item.lastName,
            email: item.email,
            rolSelected: item.roles[0].id,
            officeAsigned: item.admin.campuses ? item.admin.campuses.map(({ id }) => id) : [],
            assesorId: item.assesorId,
            departmentsSelected: item.admin.departments ? item.admin.departments.map(({ id }) => id) : [],
            edit: true

        }) : setState({
            ...state,
            id: 0,
            name: "",
            lastname: "",
            email: "",
            phone: "",
            officeAsigned: [],
            stateSelected: 0,
            rolSelected: 0,
            assesorId: 0,
            password: "",
            confirmPassword: "",
            departmentsSelected: [],
            edit: false

        });
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    const handleChange = event => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        });
    };


    const customRoles = (cellInfo) => {
        var roles = ""
        cellInfo.row.data.roles.forEach(element => {
            roles = roles + " " + element.showName
        });
        return (
            <div>
                {roles}
            </div>
        )
    }

    const customOffice = (cellInfo) => {
        var areas = ""
        cellInfo.row.data.admin.campuses.forEach(element => {
            areas = areas + "\n" + element.name
        });
        return (
            <div>
                {areas}
            </div>
        )
    }

    const customDepartments = (cellInfo) => {
        var areas = ""
        cellInfo.row.data.admin.departments.forEach(element => {
            areas = areas + "\n" + element.name
        });
        return (
            <div>
                {areas}
            </div>
        )
    }

    function enable(rowData) {
        enabledUser(!rowData.row.data.enabled, rowData.row.data.id)
    }

    const getAllOffices = () => {
        getAllOfficesEnabled().then(respuesta => {
            console.log(respuesta.data.data)
            setOffices(respuesta.data.data);
        })
    };

    function onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'plus',
                onClick: handleClickOpen.bind()
            }
        });
    }

    const enabledUser = async (enabled, id) => {
        enabledUserWith(id, enabled).then(respuesta => {
            Alert.success(respuesta.data.message);
            getAllUsers();
        })
    };

    const getAllUsers = async () => {
        getUsersAdmin().then(respuesta => {
            setState({
                ...state,
                users: respuesta.data.data
            });
        })
    };

    const getAllRoles = () => {
        getAllRolesWithoutUser().then(respuesta => {
            setRoles(respuesta.data.data);
        })
    };

    const getAllDepartmets = () => {
        getAllDepartmentsEnabled().then(respuesta => {
            setDepartments(respuesta.data.data);
        })
    };

    function getAllData() {

        getAllRoles();
        getAllDepartmets();
        getAllUsers();
        getAllOffices();
    }

    useEffect(value => {
        getAllData();
    }, []);

    const addUser = (event) => {
        event.preventDefault();
        if (state.password.localeCompare(state.confirmPassword) !== 0) {
            Alert.warning("Las contraseñas no coinciden")
            return
        }
        createAdmin(state).then(respuesta => {
            if (respuesta.data.success) {
                Alert.success(respuesta.data.message);
                setState({
                    ...state,
                    name: "",
                    phone: "",
                    departmentsSelected: [],
                    officeAsigned: [],
                    stateSelected: 0,
                    lastname: "",
                    password: "",
                    confirmPassword: "",
                    email: "",
                    rolSelected: 0,
                });
                handleClose();
                getAllData();
            } else {
                Alert.warning(respuesta.data.message);
            }

        })
    };

    const updateUser = (event) => {
        event.preventDefault();

        editAdmin(state).then(respuesta => {
            if (respuesta.data.success) {
                Alert.success(respuesta.data.message);
                setState({
                    ...state,
                    name: "",
                    phone: "",
                    departmentsSelected: [],
                    officeAsigned: [],
                    stateSelected: 0,
                    lastname: "",
                    password: "",
                    confirmPassword: "",
                    email: "",
                    rolSelected: 0,
                });
                handleClose();
                getAllData();
            } else {
                Alert.warning(respuesta.data.message);
            }

        })
    };

    function handleClickEditPassword(row) {
        console.log(row.row)
        setUserId(row.row.data.id)
        setOpenChangePasswordDialog(true)
    }

    return (
        <div>
            <GridContainer>
                <Dialog open={open} onClose={handleClose}>
                    <form onSubmit={state.edit ? updateUser : addUser} autoComplete={"off"}>
                        <DialogTitle id="form-dialog-title">{state.edit ? "Editar responsable" : "Agregar responsable"}</DialogTitle>
                        <DialogContent dividers="paper">
                            <FormControl required="true" fullWidth>
                                <InputLabel htmlFor="state-native-simple">Rol</InputLabel>
                                <NativeSelect
                                    native="true"
                                    fullWidth
                                    disabled={state.id === getUser().id}
                                    required={true}
                                    value={state.rolSelected}
                                    onChange={handleChange}
                                    inputProps={{
                                        name: 'rolSelected',
                                        id: 'state-native-simple'
                                    }}
                                >
                                    <option value="" />
                                    {roles.map(function (value) { return (<option key={value.id} value={value.id} > {value.showName}</option>); })}
                                </NativeSelect>

                            </FormControl >
                            <Typography variant="body" color="primary" align={"justify"}>
                                {
                                    state.rolSelected === "2" && "Responsable de la administración de los catálogos que alimentan el seguimiento de solicitudes, de los usuarios y de todas las solicitudes."
                                }
                                {
                                    state.rolSelected === "4" && "Responsable de las solicitudes correspondientes al o lops departamentos que se le asignen."
                                }
                                {
                                    state.rolSelected === "3" && "Responsable de dar seguimiento a las solicitudes que se le asignen."
                                }
                                {
                                    state.rolSelected === "5" && "Responsable de dar seguimiento a las solicitudes que se le asignen"
                                }
                            </Typography>

                            <FormControl required="true" fullWidth>
                                <InputLabel id="select-multiple-officeAsigned">Campus</InputLabel>
                                <Select
                                    labelId={"select-multiple-officeAsigned"}
                                    multiple
                                    fullWidth
                                    required
                                    value={state.officeAsigned}
                                    disabled={state.id === getUser().id}
                                    onChange={handleChange}
                                    input={<Input id="select-multiple-officeAsigned" />}
                                    renderValue={(selected) => offices.filter(o => selected.indexOf(o.id) > -1).map(o => o.name).join(", ")}
                                    MenuProps={MenuProps}
                                    inputProps={{
                                        name: 'officeAsigned'
                                    }}
                                >
                                    {offices.map((value) => (
                                        <MenuItem color={"secondary"} key={value.id} value={value.id}>
                                            <Checkbox checked={state.officeAsigned.find(o => o === value.id) != null} />
                                            <ListItemText primary={value.name} />
                                        </MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                            <FormControl required="true" fullWidth>
                                <InputLabel id="select-multiple-departmens">Departamentos</InputLabel>
                                <Select
                                    multiple
                                    fullWidth
                                    labelId={"select-multiple-departmens"}
                                    required
                                    value={state.departmentsSelected}
                                    disabled={state.id === getUser().id}
                                    onChange={handleChange}
                                    input={<Input id="select-multiple-departmens" />}
                                    renderValue={(selected) => departments.filter(o => selected.indexOf(o.id) > -1).map(o => o.name).join(", ")}
                                    MenuProps={MenuProps}
                                    inputProps={{
                                        name: 'departmentsSelected'
                                    }}
                                >
                                    {departments.map((value) => (
                                        <MenuItem color={"secondary"} key={value.id} value={value.id}>
                                            <Checkbox checked={state.departmentsSelected.find(o => o === value.id) != null} />
                                            <ListItemText primary={value.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                required
                                margin="dense"
                                id="name"
                                name="name"
                                value={state.name}
                                onChange={handleChange}
                                label="Nombre"
                                type="text"
                                fullWidth
                            />
                            <TextField
                                required
                                margin="dense"
                                id="lastname"
                                name="lastname"
                                value={state.lastname}
                                onChange={handleChange}
                                label="Apellidos"
                                type="text"
                                fullWidth
                            />
                            <TextField
                                fullWidth
                                required
                                id="email-input"
                                label="Correo electónico"
                                type="email"
                                name="email"
                                value={state.email}
                                onChange={handleChange}
                                margin="normal"
                            />
                            {
                                !state.edit && (
                                    <div>
                                        <TextField
                                            fullWidth
                                            required
                                            id="password-input"
                                            label="Contraseña"
                                            type="password"
                                            name="password"
                                            value={state.password}
                                            onChange={handleChange}
                                            autoComplete="current-password"
                                            margin="normal"
                                        />
                                        <TextField
                                            fullWidth
                                            required
                                            id="confirm-password-input"
                                            label="Confirmar contraseña"
                                            type="password"
                                            name="confirmPassword"
                                            value={state.confirmPassword}
                                            onChange={handleChange}
                                            autoComplete="current-password"
                                            margin="normal"
                                        />
                                    </div>
                                )
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancelar
                            </Button>
                            <Button type={"submit"} color="primary">
                                Guardar
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
                <ChangePasswordDialog
                    open={openChangePasswordDialog}
                    userId={userId}
                    onChangedPassword={() => { setOpenChangePasswordDialog(false); }}
                    onChangePasswordDialogClosed={() => { setOpenChangePasswordDialog(false); }}
                />
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="secondary">
                            <h4 className={classes.cardTitleWhite}>Listado de responsables</h4>
                            <p className={classes.cardCategoryWhite}>

                            </p>
                        </CardHeader>
                        <CardBody>
                            <QueueAnim>
                                <DataGrid
                                    dataSource={state.users}
                                    showBorders={true}
                                    key="id"
                                    wordWrapEnabled={true}
                                    allowColumnReordering={true}
                                    allowColumnResizing={true}

                                    onToolbarPreparing={onToolbarPreparing}>

                                    {/* <Export enabled={true} fileName={"Administradores"} texts={{exportAll: "Exportar todos lo datos"}}/>   */}

                                    <FilterRow visible={true} applyFilter={"auto"} />

                                    <HeaderFilter visible={true} />

                                    <GroupPanel visible={false} emptyPanelText={"Arrastre una columna hacia aquí para agrupar"} />

                                    <SearchPanel visible={true}
                                        width={240}
                                        placeholder="Buscar..." />

                                    <Paging defaultPageSize={10} />

                                    <Pager
                                        showPageSizeSelector={true}
                                        allowedPageSizes={[5, 10, 20]}
                                        showInfo={true} />

                                    <Sorting mode="multiple" />

                                    <Column
                                        dataField="name"
                                        caption="Nombre" />

                                    <Column
                                        dataField="email"
                                        caption="Correo electrónico" />

                                    <Column
                                        dataField="admin.departments.roles"
                                        cellRender={customDepartments}
                                        caption="Departamento" />

                                    <Column
                                        dataField="admin.campuses"
                                        cellRender={customOffice}
                                        caption="Campus" />

                                    <Column
                                        dataField="roles"
                                        cellRender={customRoles}
                                        caption="Rol" />

                                    <Column
                                        dataField="statusEnum.description"
                                        caption="Estatus" />

                                    <Column type="buttons"
                                        visible={state.displayRole}
                                        caption="Acciones"
                                        width={80}
                                        buttons={
                                            [{
                                                hint: 'Deshabilitar',
                                                icon: 'remove',
                                                visible: (e) => (e.row.data.enabled),
                                                onClick: enable
                                            },
                                            {

                                                hint: 'Habilitar',
                                                icon: 'check',
                                                visible: (e) => (!e.row.data.enabled),
                                                onClick: enable
                                            }, {
                                                hint: 'Editar',
                                                icon: 'edit',
                                                onClick: handleClickOpen
                                            }, {
                                                hint: 'Cambiar Contraseña',
                                                icon: 'paste',
                                                onClick: handleClickEditPassword
                                            }
                                            ]
                                        } />
                                </DataGrid>
                            </QueueAnim>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>

        </div>





    );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};