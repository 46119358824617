import React from 'react';
import moment from 'moment';
import './Message.css';
import 'moment/locale/es'
import JSZip from "jszip";  // without this line it didn't work
moment.locale('es');
import {Link, Typography} from '@material-ui/core';
import {getActivitysFiles} from "../../util/Contexts/TicketContext";
import {saveAs} from 'file-saver';


export default function Message(props) {

    const {
        data,
        isMine,
        startsSequence,
        endsSequence,
        showTimestamp,
        studentView
    } = props;
    moment.locale('es-us');
    const friendlyTimestamp = moment(new Date(data.dateActivity).getTime()).format('LLLL');

    function dowloadFiles() {

        getActivitysFiles(data.id).then(({data}) => {
            if (data.data.length == 1) {
                var a = document.createElement("a"); //Create <a>
                a.href = `data:${data.data[0].fileType};base64,${data.data[0].data}`; //Image Base64 Goes here
                a.download = data.data[0].fileName; //File name Here
                a.click(); //Downloaded file
            } else {
                var zip = new JSZip();
                data.data.forEach(f => {
                    zip.file(f.fileName, f.data, {base64: true});
                })

                zip.generateAsync({type: "blob"}).then(function (content) {
                    // see FileSaver.js
                    saveAs(content, "Archivos.zip");
                });
            }
        })

    }

    return (
        <div className={[
            'message',
            `${isMine ? 'mine' : ''}`,
            `${startsSequence ? 'start' : ''}`,
            `${endsSequence ? 'end' : ''}`
        ].join(' ')}>
            {
                showTimestamp &&
                <div className="timestamp">
                    <strong>{friendlyTimestamp}</strong>

                </div>
            }
            {
                data.ticketActivityType.type.id !== 3 &&
                <div className="system">
                    {data.content}
                </div>
            }

            {
                data.ticketActivityType.type.id === 3 &&
                <div className="bubble-container">
                    <div className="bubble" title={friendlyTimestamp}>
                        <div dangerouslySetInnerHTML={{__html: data.content || ""}}/>
                        {data.hasFiles &&
                        <Link color={"secondary"} onClick={() => dowloadFiles()}>
                            Descargar adjuntos
                        </Link>
                        }
                    </div>

                </div>
            }

            {
                data.ticketActivityType.type.id === 3 &&
                <Typography align={isMine ? "right" : "left"} variant={"caption"}
                            style={{textTransform: "uppercase"}}>{ !isMine && studentView ? "Responsable" : data.user.completeName}</Typography>
            }


        </div>
    );
}