import {API_AUTENTICATION_HEADERS, API_BASE_URL} from "../../assets/constants";
import axios from "axios";
import {trackPromise} from "react-promise-tracker";

const headers = API_AUTENTICATION_HEADERS;

function getAllRoles() {
    const url = API_BASE_URL + "/api/roles/all";
   let result;
    trackPromise(
        result = axios.get(url, {headers} )
    )
    return result;
}

function getAllRolesWithoutUser() {
    const url = API_BASE_URL + "/api/roles";
   let result;
    trackPromise(
        result = axios.get(url, {headers} )
    )
    return result;
}

function updateRole(id, newData) {
    const url = API_BASE_URL + "/api/role/" + id;

   let result;
    trackPromise(
        result = axios.put(url, newData, {headers} )
    )
    return result;
}

export {  getAllRoles, getAllRolesWithoutUser, updateRole};