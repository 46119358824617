import React, { useEffect } from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import CardBody from "../../../components/Card/CardBody.js";
import Alert from "react-s-alert";
import { primaryColor, secondaryColor } from "../../../assets/jss/material-dashboard-react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from "@material-ui/core";
import DataGrid, {
    Column, Export, FilterRow,
    GroupPanel, HeaderFilter,
    Pager, Paging, Editing,
    SearchPanel, Sorting
} from "devextreme-react/data-grid";
import esMessages from 'devextreme/localization/messages/es.json';
import { locale, loadMessages } from 'devextreme/localization';
import QueueAnim from "rc-queue-anim";
import {
    addDepartment,
    getAllDepartmets,
    removeDepartment,
    updateDepartment
} from "../../../util/Contexts/DepartmentContext";
import {handleError} from "../../../util/Contexts/ErrorContext";
import Typography from "@material-ui/core/Typography";
import {removeCategory} from "../../../util/Contexts/CategoryContext";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: primaryColor[0],
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: primaryColor[0]
        }
    },
    cardTitleWhite: {
        color: primaryColor[0],
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const useStyles = makeStyles(styles);

export default function Departments() {

    const classes = useStyles();
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [openConfirmation, setOpenConfirmation] = React.useState(false);
    const [state, setState] = React.useState({
        stateSelected: 0,
        filter: "",
        name: "",
        description: "",
        enabled: true,
        edit: true,
        id: 0,
    });

    loadMessages(esMessages);
    locale("es");

    function handleClickOpen(row) {
        var item = null;
        console.log(row.row)
        if (row.row != null) {
            for (let i = 0; i < data.length; i++) {
                if (row.row.data.id === data[i].id) {
                    item = data[i]
                }
            }
        }
        item ? setState({
            ...state,
            name: item.name,
            id: item.id,
            description: item.description,
            enabled: item.enabled,
            edit: true
        }) : setState({
            ...state,
            name: "",
            description: "",
            enabled: true,
            edit: false
        });
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
        setOpenConfirmation(false);
    }

    const handleChange = event => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        });
    };

    function enable(rowData) {
        rowData.row.data.enabled = !rowData.row.data.enabled
        updateDepartment(rowData.row.data.id, rowData.row.data).then(respuesta => {
            Alert.success(respuesta.data.message);
            getAll();
        })
    }

    function handleDelete(row) {
        setOpenConfirmation(true);
        setState({...state, id: row.row.data.id})
    }

    function deleteObject () {
        removeDepartment(state.id).then(respuesta => {
            Alert.success(respuesta.data.message);
            handleClose();
            getAll();
        } )
    }

    const updateData = (event) => {
        event.preventDefault();
        let newData = {
            name: state.name,
            description: state.description,
            enabled: state.enabled
        }
        updateDepartment(state.id, newData).then(respuesta => {
            Alert.success(respuesta.data.message);
            handleClose();
            getAll();
        })
    };

    const getAll = async () => {
        setLoading(true);
        getAllDepartmets().then(respuesta => {
            setLoading(false);
            setData(respuesta.data.data);
        })
    };

    useEffect(value => {
        getAll();
    }, []);

    const addData = (event) => {
        event.preventDefault();
        console.log(state);
        addDepartment(state).then(respuesta => {
            if (respuesta.data.success) {
                Alert.success(respuesta.data.message);
            } else {
                Alert.error(respuesta.data.message);
            }
            setState({
                ...state,
                name: "",
                description: ""
            });
            handleClose();
            getAll();
        })
    };


    function onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'plus',
                onClick: handleClickOpen.bind()
            }
        });
    }

    return (
        <div>
                    <GridContainer>
                        <Dialog open={open} onClose={handleClose}>
                            <DialogTitle id="form-dialog-title">{state.edit ? "Editar Departamento" : "Agregar Departamento"}</DialogTitle>
                            <form onSubmit={state.edit ? updateData : addData}>
                                <DialogContent>
                                    <TextField
                                        required={true}
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        name="name"
                                        value={state.name}
                                        onChange={handleChange}
                                        label="Nombre"
                                        type="text"
                                        fullWidth
                                    />
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="description"
                                        name="description"
                                        value={state.description}
                                        onChange={handleChange}
                                        label="Descripción"
                                        type="text"
                                        fullWidth
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} color="primary">
                                        Cancelar
                    </Button>
                                    <Button type={"submit"} color="primary">
                                        Guardar
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
                <Dialog open={openConfirmation} onClose={handleClose}>
                    <DialogTitle id="form-dialog-title">Confirmación</DialogTitle>
                    <DialogContent>
                        <Typography> ¿Esta seguro que desea eliminar el departamento? </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancelar
                        </Button>
                        <Button color="primary" onClick={deleteObject}>
                            Aceptar
                        </Button>
                    </DialogActions>
                </Dialog>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="secondary">
                    <h4 className={classes.cardTitleWhite}>Listado de Departamentos</h4>
                    <p className={classes.cardCategoryWhite}>

                                    </p>
                                </CardHeader>
                                <CardBody>
                                    <QueueAnim>
                                        <DataGrid
                                            dataSource={data}
                                            showBorders={true}
                                            key="id"
                                            wordWrapEnabled={true}
                                            allowColumnReordering={true}
                                            allowColumnResizing={true}
                                            onToolbarPreparing={onToolbarPreparing}>

                                            {/* <Export enabled={true} fileName={"Administradores"} texts={{exportAll: "Exportar todos lo datos"}}/>   */}

                                            <FilterRow visible={true} applyFilter={"auto"} />

                                            <HeaderFilter visible={true} />

                                            <GroupPanel visible={false} emptyPanelText={"Arrastre una columna para agrupar"} />

                                            <SearchPanel visible={true}
                                                width={240}
                                                placeholder="Buscar..." />

                                            <Paging defaultPageSize={10} />

                                            <Pager
                                                showPageSizeSelector={true}
                                                allowedPageSizes={[5, 10, 20]}
                                                showInfo={true} />

                                            <Sorting mode="multiple" />

                                            <Column
                                                dataField="name"
                                                caption="Nombre" />

                                            <Column
                                                dataField="description"
                                                caption="Descripción" />

                                            <Column
                                                dataField="statusEnum.description"
                                                caption="Estatus" />

                                            <Column type="buttons"
                                                caption="Acciones"
                                                width={80}
                                                buttons={[{
                                                    hint: 'Deshabilitar',
                                                    icon: 'remove',
                                                    visible: (e) => (e.row.data.enabled),
                                                    onClick: enable
                                                },
                                                {

                                hint: 'Habilitar',
                                icon: 'check',
                                visible: (e) => (!e.row.data.enabled),
                                onClick: enable
                            },
                            {
                          hint: 'Editar',
                          icon: 'edit',
                          onClick: handleClickOpen
                      },
                            {
                                hint: 'Eliminar',
                                icon: 'trash',
                                onClick: handleDelete
                            }]}/>
                    </DataGrid>
                  </QueueAnim>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>


        </div>


    );
}