import React from 'react';
import Chart from 'react-apexcharts';

class BarChartReports extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true
                },
                plotOptions: {
                    bar: {
                        horizontal: false
                    }
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                xaxis: {
                    categories: props.categories,
                },
                title: {
                    text: props.title,
                    align: 'center',
                    style: {
                        fontSize: '20px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 'bold',
                    }
                },
                colors: ['#d17339', '#ffd000', '#259823'],
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val + " reportes"
                        }
                    }
                },
                fill: {
                    opacity: 1
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left',
                    offsetX: 40
                }
            },
            series: props.data
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data || this.props.categories !== prevProps.categories) {
            this.setState({
                series: this.props.data,
                options: {
                    ...this.state.options,
                    xaxis: {
                        categories: this.props.categories
                    }
                }
            });
        }
    }

    render() {
        return (
            <div style={{
                backgroundColor: 'white',
                boxShadow: '0px 3px 15px rgba(0,0,0,0.2)',
                borderRadius: '10px',
                padding: '20px',
                margin: '20px',
                backgroundImage: 'linear-gradient(to bottom right, #fff, #eee)'
            }}>
                <Chart
                    options={this.state.options}
                    series={this.state.series}
                    type="bar"
                    height="350"
                />
            </div>
        )
    }
}

export default BarChartReports;
