import React, { useEffect } from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";

// components
import Layout from "../components/Layout/Layout";

// pages
import Error from "./views/error";
import Login from "./views/user/login/Login";

// context
import { useUserState, hasRole } from "../util/Contexts/UserContext";
import LandingPage from "./views/LandingPage/LandingPage";
import UserTicketCreate from "./views/ticket/UserTicketCreate";
import TicketDetailStudent from "./views/ticket/TicketDetailStudent";
import QualifyTicket from "./views/ticket/QualifyTicket";


export default function App() {
    // global
    var { isAuthenticated } = useUserState();



    return (
        <BrowserRouter>
            <Switch>

                <PublicRoute exact path="/" component={LandingPage} />
                <Route
                    exact
                    path="/admin"
                    render={() => <Redirect to="/admin/Dashboard" />}
                />
                <PrivateRoute path="/admin" component={Layout} />

                <PublicRoute path="/login" component={Login} />
                <PublicRoute path="/consult" component={TicketDetailStudent} />
                <PublicRoute path="/qualify/:id" component={QualifyTicket} />
                <Route component={Error} />
            </Switch>
        </BrowserRouter>
    );

    // #######################################################################

    function PrivateRoute({ component, ...rest }) {
        return (
            <Route
                {...rest}
                render={props =>
                    isAuthenticated ? (
                        React.createElement(component, props)
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: {
                                    from: props.location,
                                },
                            }}
                        />
                    )
                }
            />
        );
    }

    function PublicRoute({ component, ...rest }) {
        return (
            <Route
                {...rest}
                render={props =>
                    isAuthenticated ? (
                        <Redirect
                            to={{
                                pathname: "/admin",
                            }}
                        />
                    ) : (
                        React.createElement(component, props)
                    )
                }
            />
        );
    }
}
