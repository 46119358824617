import React, {useEffect} from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../components/Grid/GridContainer.js";
import Card from "../../../../components/Card/Card.js";
import CardHeader from "../../../../components/Card/CardHeader.js";
import CardBody from "../../../../components/Card/CardBody.js";
import axios from 'axios';
import Alert from "react-s-alert";
import {API_BASE_URL, API_AUTENTICATION_HEADERS} from "../../../../assets/constants";
import {primaryColor, secondaryColor} from "../../../../assets/jss/material-dashboard-react";
import {TraceSpinner} from "react-spinners-kit";
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {
    Button, CircularProgress, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl
} from "@material-ui/core";
import DataGrid, {
  Column, Export, FilterRow,
  GroupPanel, HeaderFilter,
  Pager, Paging, Editing,
  SearchPanel, Sorting
} from "devextreme-react/data-grid";
import QueueAnim from "rc-queue-anim";
import {handleError} from "../../../../util/Contexts/ErrorContext";
import {enabledUserWith, getUsersStudent} from "../../../../util/Contexts/UserContext";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color:  primaryColor[0],
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color:  primaryColor[0]
        }
    },
    cardTitleWhite: {
        color:  primaryColor[0],
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const useStyles = makeStyles(styles);


export default function Users() {

    const classes = useStyles();
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const [state, setState] = React.useState({
        id: 0,
        clients: []
    });

    const enabledUser = async (enabled, id) => {
        enabledUserWith().then(respuesta => {
            Alert.success(respuesta.data.message);
            getAllData();
        } )
    };

    function enable(rowData) {
      enabledUser(!rowData.row.data.enabled, rowData.row.data.id)
    }

    const getAllData = async () => {
        getUsersStudent().then(respuesta => {
            setData(respuesta.data.data);
        } )
    };

    useEffect(value => {
        getAllData();
    },[]);

    return (
      <div>
          <GridContainer>

            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="secondary">
                  <h4 className={classes.cardTitleWhite}>Listado de Alumnos</h4>
                  <p className={classes.cardCategoryWhite}>

                  </p>
                </CardHeader>
                <CardBody>
                  <QueueAnim>
                    <DataGrid
                      dataSource={data}
                      showBorders={true}
                      key="id"
                      wordWrapEnabled={true}
                      allowColumnReordering={true}
                      allowColumnResizing={true}>

                      {/* <Export enabled={true} fileName={"Administradores"} texts={{exportAll: "Exportar todos lo datos"}}/>   */}
                            
                      <FilterRow visible={true} applyFilter={"auto"} />
                            
                      <HeaderFilter visible={true} />

                        <GroupPanel visible={true} emptyPanelText={"Arrastre una columna hacia aquí para agrupar"} />
                            
                      <SearchPanel visible={true}
                        width={240}
                        placeholder="Buscar..." />
                            
                      <Paging defaultPageSize={10} />
                            
                      <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />
                              
                      <Sorting mode="multiple" />

                      <Column
                        dataField="completeName"
                        caption="Nombre"/>

                      <Column
                        dataField="email"
                        caption="Correo electrónico"/>

                        <Column
                            dataField="student.identifier"
                            caption="Matrícula"/>

                        <Column
                            dataField="student.level"
                            caption="Nivel académico"/>

                        <Column
                            dataField="statusEnum.description"
                            caption="Estatus" />

                      <Column
                        dataField="lastAccess"
                        dataType="Timestamp"
                        caption="Último acceso"/>

                      <Column
                        dataField="deviceType"
                        caption="Plataforma de creación"/>

                      <Column type="buttons"
                        visible={state.displayRole}
                        caption="Acciones"
                        width={80}
                        buttons={[{
                            hint: 'Deshabilitar',
                            icon: 'remove',
                            visible: (e) => (e.row.data.enabled),
                            onClick: enable
                        },
                            {

                                hint: 'Habilitar',
                                icon: 'check',
                                visible: (e) => (!e.row.data.enabled),
                                onClick: enable
                            }]}/>
                      </DataGrid>
                    </QueueAnim>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
      </div>
    );
}