import React, {useEffect} from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import CardBody from "../../../components/Card/CardBody.js";
import axios from 'axios';
import Alert from "react-s-alert";
import {API_BASE_URL, API_AUTENTICATION_HEADERS} from "../../../assets/constants";
import {primaryColor, secondaryColor} from "../../../assets/jss/material-dashboard-react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from "@material-ui/core";
import DataGrid, {
  Column, Export, FilterRow,
  GroupPanel, HeaderFilter,
  Pager, Paging, Editing,
  SearchPanel, Sorting
} from "devextreme-react/data-grid";
import QueueAnim from "rc-queue-anim";
import {handleError} from "../../../util/Contexts/ErrorContext";
import esMessages from 'devextreme/localization/messages/es.json';
import { locale, loadMessages } from 'devextreme/localization';
import {createOffice, getAllOffices, updateOffice} from "../../../util/Contexts/OfficeContext";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color:  primaryColor[0],
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color:  primaryColor[0]
        }
    },
    cardTitleWhite: {
        color:  primaryColor[0],
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const useStyles = makeStyles(styles);


export default function OfficeList() {

  const classes = useStyles();
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({
    name: "",
    address: "",
    officeId: '999',
    enabled: true,
    id: 0,
    edit: true
  });

  loadMessages(esMessages);
  locale("es");

  function handleClickOpen(row) {
    var item = null;
    console.log(row.row)
    if(row.row != null){
      for(let i = 0; i < data.length; i++){
        if(row.row.key.id === data[i].id){
          item = data[i]
        }
      }
    }
    item ? setState({
      ...state,
      name: item.name,
      address: item.address,
      enabled: item.enabled,
      officeId: item.office_id,
      id: item.id,
      edit: true
    }) :  setState({
      ...state,
      name: "",
      address: "",
      enabled: true,
      officeId: '0',
      edit: false
    });
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }


  const handleChange = event => {
    setState( {
      ...state,
      [event.target.name] : event.target.value
    });
  };

  function enable(rowData) {
      let newData = {
          name: rowData.row.data.name,
          enabled: !rowData.row.data.enabled
      }

      updateOffice(rowData.row.data.id, newData).then(respuesta => {
          Alert.success(respuesta.data.message);
          getAllData();
          setLoading(false);
      })
  }

  const updateData = async (event) => {
    setLoading(true);

    let newData = {
      name: state.name,
      address: state.address,
      enabled: state.enabled,
      office_id: state.officeId
    }

    updateOffice(state.id, newData).then(respuesta => {
          if(respuesta.status === false){
              Alert.warning(respuesta.data.message);
          } else {
              Alert.success(respuesta.data.message);
          }
          getAllData();
          handleClose();
          setLoading(false);
      })

    };

  const getAllData = () => {
    getAllOffices().then(respuesta => {
      console.log(respuesta.data.data)
      setLoading(false);
      setData(respuesta.data.data);
    } )
    };

  useEffect(value => {
    getAllData();
  }, []);

  const addData = (event) => {
    createOffice(state).then(respuesta => {
      if(respuesta.status === false){
        Alert.warning(respuesta.data.message);
      } else {
        Alert.success(respuesta.data.message);
      }
      setState({
        ...state,
        name: "",
        address: "",
        officeId: 0
      });
      handleClose();
      getAllData();
      setLoading(false);
    } )
  };

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'plus',
        onClick: handleClickOpen.bind()
      }
    });
  }

  return (

            <GridContainer>
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle id="form-dialog-title">{state.edit? "Editar Campus": "Agregar Campus"}</DialogTitle>
                <form onSubmit={state.edit? updateData : addData}>
                  <DialogContent>
                    <TextField
                      required={true}
                      autoFocus
                      margin="dense"
                      id="name"
                      name="name"
                      value={state.name}
                      onChange={handleChange}
                      label="Nombre"
                      type="text"
                      fullWidth
                    />

                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      Cancelar
                    </Button>
                    <Button type={"submit"} color="primary">
                      Guardar
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="secondary">
                    <h4 className={classes.cardTitleWhite}>Listado de Campus</h4>
                    <p className={classes.cardCategoryWhite}>
                    </p>
                  </CardHeader>
                  <CardBody>
                    <QueueAnim>
                      <DataGrid
                        dataSource={data}
                        showBorders={true}
                        key="id"
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        onToolbarPreparing={onToolbarPreparing}>

                        {/* <Export enabled={true} fileName={"Tickets"} texts={{exportAll: "Exportar todos lo datos"}}/>   */}
                        
                        <FilterRow visible={true} applyFilter={"auto"} />
                            
                        <HeaderFilter visible={true} />
                            
                        <GroupPanel visible={false} emptyPanelText={"Arrastre una columna para agrupar"} />
                         
                        <SearchPanel visible={true}
                          width={240}
                          placeholder="Buscar..." />
                            
                        <Paging defaultPageSize={10} />
                            
                        <Pager
                          showPageSizeSelector={true}
                          allowedPageSizes={[5, 10, 20]}
                          showInfo={true} />
                              
                        <Sorting mode="multiple" />

                        <Column
                          dataField="name"
                          caption="Nombre"/>

                          <Column
                              dataField="statusEnum.description"
                              caption="Estatus" />

                        <Column type="buttons"
                          caption="Acciones"
                          width={80}
                          buttons={[{
                              hint: 'Deshabilitar',
                              icon: 'remove',
                              visible: (e) => (e.row.data.enabled),
                              onClick: enable
                          },
                              {

                                  hint: 'Habilitar',
                                  icon: 'check',
                                  visible: (e) => (!e.row.data.enabled),
                                  onClick: enable
                              }, {
                            hint: 'Editar',
                            icon: 'edit',
                            onClick: handleClickOpen
                          }]}
                        />
                      </DataGrid>
                    </QueueAnim>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
  );
}