/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { Link, Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Help, Info, Person } from "@material-ui/icons";
import Alert from "react-s-alert";

// core components

import styles from "../../assets/jss/material-kit-react/components/headerLinksStyle.js";
import { Button, Grid, Popover, TextField, Typography } from "@material-ui/core";
import { getValidTicketStudent } from "../../util/Contexts/TicketContext"

const useStyles = makeStyles(styles);

var ticket;
export default function HeaderLinks(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [redirect, setRedirect] = React.useState(false);
    const [state, setState] = React.useState({
        ticketNumber: "",
        identifier: "",
        error: ""
    });

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleChange = (e) => {
        e.preventDefault()
        setState({
            ...state,
            [e.target.name]: e.target.value,
            error: ""
        })
    };

    const onSubmit = (event) => {
        event.preventDefault()
        getValidTicketStudent(state).then(({ data }) => {
            if (data.success) {
                setRedirect(true)
            } else {
                setState({ ...state, error: data.message });
            }

        })
    };

    const handleChangeIdentifier = event => {
        event.preventDefault()
        const re = /\b[a-zA-Z]{1}\d{1,6}\b/;


        // if value is not blank, then test the regex
        if (event.target.value === '') {
            if ( event.target.value.length > 2) {
                if (re.test(event.target.value)) {
                    setState({...state, [event.target.name]: event.target.value})
                    return
                }
            }
        }

        setState({...state, [event.target.name]: event.target.value})

    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    if (redirect) {
        return (<Redirect push to={`/consult?identifier=${state.identifier}&number=${state.ticketNumber}`} />)
    }
    return (
        <List className={classes.list}>

        {/*    <ListItem className={classes.listItem}>*/}
        {/*        <Button*/}
        {/*            href="/login"*/}
        {/*            color="transparent"*/}
        {/*            className={classes.navLink}*/}
        {/*        >*/}
        {/*            <Person className={classes.icons} color={"secondary"} /> Iniciar sesión*/}
        {/*</Button>*/}
        {/*    </ListItem>*/}
        {/*    <ListItem className={classes.listItem}>*/}
        {/*        <Button*/}
        {/*            href="#requestForm"*/}
        {/*            color="transparent"*/}
        {/*            className={classes.navLink}*/}
        {/*        >*/}
        {/*            <Help className={classes.icons} color={"secondary"}  /> Solicitar ayuda*/}
        {/*</Button>*/}
        {/*    </ListItem>*/}
            <ListItem className={classes.listItem}>

                <Button
                    onClick={handleClick}
                    color="transparent"
                    className={classes.navLink}
                >
                    <Info className={classes.icons} color={"secondary"}  /> Consultar solicitud
                </Button>

                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}

                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <form onSubmit={onSubmit} style={{ padding: "10px", width: "200px" }}>

                        <Grid container>
                            {
                                state.error !== "" &&
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography variant="caption" color="error">{state.error}</Typography>
                                </Grid>
                            }

                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    label="Matricula"
                                    type="text"
                                    placeholder={"m000000"}
                                    value={state.identifier}
                                    onChange={handleChange}
                                    name="identifier"
                                    inputProps={{
                                        pattern: "^[a-zA-Z]{1}[0-9]{6}$"
                                    }}
                                    helperText={"Anteponer la inicial del campus"}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    label="Folio"
                                    type="text"
                                    onChange={handleChange}
                                    value={state.ticketNumber}
                                    name="ticketNumber"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <div aling="right" style={{ padding: "10px" }}>
                                    <Button type="submit" variant="contained" color="secondary">ConsuLtar</Button>
                                </div>

                            </Grid>
                        </Grid>
                    </form>
                </Popover>
            </ListItem>
        </List >
    );
}
