import { container, title } from "../../material-kit-react";

const landingPageStyle = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container,
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none",
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  mainRaised: {
    // margin: "-60px 30px 0px",
    // borderRadius: "6px",
    // boxShadow:
    //   "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  contentList: {
    marginTop: "5px",
    minHeight: "32px",
    textDecoration: "none",
    color: "#FFFFFF",
    fontFamily: 'Avenir Next LT Pro Demi Cn',
  },
  content: {
    marginTop: "5px",
    minHeight: "32px",
    textDecoration: "none",
    color: "#FFFFFF",
    fontFamily: 'Avenir Next LT Pro Regular',
  }
};

export default landingPageStyle;
