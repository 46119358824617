import React from "react";
import {
  ACCESS_TOKEN,
  API_BASE_URL,
  API_HEADERS,
  API_AUTENTICATION_HEADERS,
  DEVICE_ID,
  USER_SESSION,
} from "../../assets/constants";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();
const header = API_HEADERS;
const headerAutentication = API_AUTENTICATION_HEADERS;



function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      return { ...state, isAuthenticated: false };
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem(ACCESS_TOKEN),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut, getUser, hasRole, singUpUser, changePasswordService };

// ###########################################################

function loginUser(dispatch, login, password, history, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);

  if (!!login && !!password) {
    trackPromise(
      axios.post(API_BASE_URL + "/auth/login", Object.assign({}, {email: login, password: password, webDeviceId: localStorage.getItem(DEVICE_ID)}),{header}).then(result => {
        localStorage.setItem(ACCESS_TOKEN, result.data.accessToken);
        localStorage.setItem(USER_SESSION, JSON.stringify(result.data.user));
        window.location.reload();
        dispatch({ type: "LOGIN_SUCCESS" });
        setError(null);
        setIsLoading(false);
        history.push("/admin/Dashboard");

      }).catch(error => {
        setIsLoading(false);
        setError(true);
      })
    );
  } else {

    setError(true);
    setIsLoading(false);
  }
}


function getUser() {
  return JSON.parse(localStorage.getItem(USER_SESSION));
}

function hasRole(rol) {
  let includesRole = getUser() ? getUser().roles.map(r => r.name).includes(rol) : false;
  return includesRole;
}

function signOut(dispatch, history) {
  localStorage.clear();
  dispatch({ type: "SIGN_OUT_SUCCESS" });
}

function changePasswordService(data, useriD) {
  const url = API_BASE_URL + "/api/user/" + useriD + "/change-password";
  let result;
  trackPromise(
      result = axios.put(url,  Object.assign({}, {password: data.password}) , {headers: headerAutentication})
  )
  return result;
}

function singUpUser(data, frontDocument, backDocument){
  let result;
  const url = API_BASE_URL + "/auth/signup/web" ;
  const object = Object.assign({}, {name: data.name, lastName: data.lastname, email: data.email,
    phone: data.phone, gender: data.gender, password: data.password, rfc: data.rfc, birthdate: data.birthdate,
    isMoralClient: data.moral, deviceType: "WEB", authentication: data.authentication,
    colony: data.colony, street: data.street, streetNumber: data.streetNumber,
    referenceType: data.referenceType, referenceName: data.referenceName, referenceNumber: data.referenceNumber,
    referenceRelationship: data.referenceRelationship});

  var bodyFormData = new FormData();
  Array.from(frontDocument).forEach((element, key) => {
    bodyFormData.append("frontDocument", element, element.name + "_" + key);
  });
  Array.from(backDocument).forEach((element, key) => {
    bodyFormData.append("backDocument", element, element.name + "_" + key);
  });

  const json = JSON.stringify(object);
  const blob = new Blob([json], {
  type: 'application/json'
  });
  bodyFormData.append("request", blob)

  trackPromise(
    result = axios.post(url, bodyFormData, { API_AUTENTICATION_HEADERS })
  );
  return result;
}

export function createAdmin (state) {
  const url = API_BASE_URL + "/api/user/admin";
  const headers = API_AUTENTICATION_HEADERS;

  let result;
  trackPromise(
      result = axios.post(url, Object.assign({}, { name: state.name, lastname: state.lastname, enabled: true, phone: state.phone, password: state.password, email: state.email, departments: state.departmentsSelected, rol: state.rolSelected, campuses: state.officeAsigned }), { headers })
  );
  return result;
}

export function editAdmin (state) {
  const url = API_BASE_URL + "/api/user/admin/" + state.id;
  const headers = API_AUTENTICATION_HEADERS;
  let result;
  trackPromise(
      result = axios.put(url, Object.assign({}, { name: state.name, lastname: state.lastname, enabled: true, phone: state.phone, email: state.email, departments: state.departmentsSelected, rol: state.rolSelected, campuses: state.officeAsigned }), { headers })
  );
  return result;
}

export function enabledUserWith (id, enabled) {
  const url = API_BASE_URL + "/api/user/" + id + "/enabled?enabled=" + enabled;
  const headers = API_AUTENTICATION_HEADERS;
  let result;
  trackPromise(
      result = axios.put(url, {}, { headers })
  );
  return result;
}

export function getUsersAdmin () {
  const url = API_BASE_URL + "/api/users/admins";
  const headers = API_AUTENTICATION_HEADERS;

  let result;
  trackPromise(
      result = axios.get(url, { headers })
  );
  return result;
}

export function getUsersStudent () {
  const url = API_BASE_URL + "/api/users/users";
  const headers = API_AUTENTICATION_HEADERS;
  let result;
  trackPromise(
      result =axios.get(url, { headers } )
  );
  return result;
}

export function getUserInformation (id) {
  const url = API_BASE_URL + "/api/user/" + id;
  const headers = API_AUTENTICATION_HEADERS;
  let result;
  trackPromise(
      result =axios.get(url, { headers } )
  );
  return result;
}
