import React, {useEffect} from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import CardBody from "../../../components/Card/CardBody.js";
import Alert from "react-s-alert";
import {primaryColor, secondaryColor} from "../../../assets/jss/material-dashboard-react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, 
    TextField
} from "@material-ui/core";
import DataGrid, {
    Column, Export, FilterRow,
    GroupPanel, HeaderFilter,
    Pager, Paging, Editing,
    SearchPanel, Sorting
} from "devextreme-react/data-grid";
import QueueAnim from "rc-queue-anim";
import {TraceSpinner} from "react-spinners-kit";
import {addServiceType, getAllServiceType, updateServiceType} from "../../../util/Contexts/ServiceTypeContext";
import {handleError} from "../../../util/Contexts/ErrorContext";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color:  primaryColor[0],
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color:  primaryColor[0]
        }
    },
    cardTitleWhite: {
        color:  primaryColor[0],
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const useStyles = makeStyles(styles);

export default function ServiceTypeList() {

    const classes = useStyles();
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [state, setState] = React.useState({
        stateSelected: 0,
        filter: "",
        name: "",
        id: 0,
        description: "",
        insumo: "",
        enabled: true,
        edit: false,
    });

    function handleClickOpen(row) {
      var item = null;
      if(row.row != null){
        for(let i = 0; i < data.length; i++){
          if(row.row.key === data[i].id){
            item = data[i]
          }
        }
      }
        item ? setState({
            ...state,
            name: item.name,
            id: item.id,
            description: item.description,
            insumo: item.insumo,
            enabled: item.enabled,
            edit: true
        }) :  setState({
            ...state,
            name: "",
            description: "",
            insumo: "",
            enabled: true,
            edit: false
        });
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    function disable(id, rowData) {
        rowData.enabled = false;
        updateData(id, rowData);
    }

    const handleChange = event => {
        setState( {
            ...state,
            [event.target.name] : event.target.value
        });
    };

    function enable(rowData) {
      rowData.row.data.enabled = !rowData.row.data.enabled
      updateServiceType(rowData.row.data.id, rowData.row.data).then(respuesta => {
        Alert.success(respuesta.data.message);
        handleClose();
        getAllData();
    } )
    }

    // function getDepartments() {
    //     getAllDepartmets().then(respuesta => {
    //         setLookup(respuesta.data.data.reduce(function(result, item) {
    //             var key = Object.values(item)[0];
    //             result[key] = item.name;
    //             return result;
    //         }, {}));
    //         setOffices(respuesta.data.data);
    //     }).catch(error => {
    //         handleError(error);
    //     });

    // }

    const updateData =  (event)  => {
      event.preventDefault();
      let newData ={
        name: state.name,
        description: state.description,
        insumo: state.insumo,
        enabled: state.enabled
      }
      updateServiceType(state.id,newData).then(respuesta => {
        Alert.success(respuesta.data.message);
        handleClose();
        getAllData();
      } )
    };

    const renderStatus = (cellInfo) => {
        if(cellInfo.data.enabled){
          return(
            <div align={"center"}>
              Activo
            </div>
            );
        } else {
          return(
            <div align={"center"}>
              Inactivo
            </div>
            );
        }
      }

      function onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'plus',
            onClick: handleClickOpen.bind()
          }
        });
      }

    const getAllData = () => {
        setLoading(true);
        getAllServiceType().then(respuesta => {
            setLoading(false);
            setData(respuesta.data.data);
        } )
    };

    useEffect(value => {
        getAllData();
    }, []);

    const addData = (event) => {
        setLoading(true);
        event.preventDefault();
        addServiceType(state).then(respuesta => {
            Alert.success(respuesta.data.message);
            setState({
                ...state,
                name: "",
                description: "",
                enabled: ""
            });
            handleClose();
            getAllData();
            setLoading(false);
        } )
    };

    return (
      <div>
            <GridContainer>
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle id="form-dialog-title">{state.edit? "Editar Tipo de servicio" : "Agregar Tipo de servicio"}</DialogTitle>
                <form onSubmit={state.edit? updateData: addData}>
                <DialogContent>
                  <TextField
                    required={true}
                    autoFocus
                    margin="dense"
                    id="name"
                    name="name"
                    value={state.name}
                    onChange={handleChange}
                    label="Nombre"
                    type="text"
                    fullWidth
                  />
                  <TextField
                    required = {true}
                    margin="dense"
                    id="description"
                    name="description"
                    value={state.description}
                    onChange={handleChange}
                    label="Descripción"
                    type="text"
                    fullWidth
                  />
                  <TextField
                    required = {true}
                    margin="dense"
                    id="insumo"
                    name="insumo"
                    value={state.insumo}
                    onChange={handleChange}
                    label="Insumo"
                    type="number"
                    fullWidth
                  />

                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancelar
                  </Button>
                  
                  <Button type={"submit"}  color="primary">
                    Guardar
                  </Button>

                </DialogActions>
                </form>
              </Dialog>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="secondary">
                    <h4 className={classes.cardTitleWhite}>Listado de tipos de servicios</h4>
                    <p className={classes.cardCategoryWhite}>

                    </p>
                  </CardHeader>
                  <CardBody>
                  <QueueAnim>
                    <DataGrid
                      dataSource={data}
                      showBorders={true}
                      keyExpr="id"
                      wordWrapEnabled={true}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      onToolbarPreparing={onToolbarPreparing}>

                      <Export enabled={true} fileName={"Tipos de Servicio"} texts={{exportAll: "Exportar todos lo datos"}}/>  
                            
                      <FilterRow visible={true} applyFilter={"auto"} />
                            
                      <HeaderFilter visible={true} />
                            
                      <GroupPanel visible={false} emptyPanelText={"Arrastre una columna para agrupar"} />
                            
                      <SearchPanel visible={true}
                        width={240}
                        placeholder="Buscar..." />
                            
                      <Paging defaultPageSize={10} />
                            
                      <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />
                              
                      <Sorting mode="multiple" />

                      <Column
                        dataField="name"
                        caption="Nombre"/>

                      <Column
                        dataField="description"
                        caption="Descripción"/>

                      <Column
                        dataField="insumo"
                        caption="Insumo"/>

                        <Column
                            dataField="statusEnum.description"
                            caption="Estatus"/>

                      <Column type="buttons"
                        visible={state.displayRole}
                        caption="Acciones"
                        width={80}
                        buttons={[{
                          hint: 'Reasignar solicitud',
                          icon: 'detailslayout',
                          onClick: enable
                        }, {
                          hint: 'Editar',
                          icon: 'edit',
                          onClick: handleClickOpen
                      }]}/>
                    </DataGrid>
                  </QueueAnim>
                                    {/* <MaterialTable
                                        columns={[
                                            { title: 'Nombre', field: 'name' },
                                            { title: 'Descripción', field: 'description' },
                                            { title: 'Estatus', field: 'enabled', render: rowData => rowData.enabled ? "Activo" : "Inactivo", editable: 'never' }

                                        ]}
                                        data={data}
                                        title=""
                                        localization={{
                                            body: {
                                                emptyDataSourceMessage: "No se encontraron registros",
                                                editRow: {
                                                    saveTooltip: "Guardar",
                                                    cancelTooltip: "Cancelar"
                                                },
                                                editTooltip: "Editar"
                                            },
                                            header: {
                                                actions: "Acciones"
                                            },
                                            toolbar:{
                                                searchPlaceholder:"Buscar"
                                            },
                                            pagination: {
                                                firstTooltip: "Primera página",
                                                firstAriaLabel: "Primera página",
                                                previousTooltip: "Página anterior",
                                                previousAriaLabel: "Página anterior",
                                                nextTooltip: "Siguiente página",
                                                nextAriaLabel: "Siguiente página",
                                                labelDisplayedRows: "{from}-{to} de {count}",
                                                labelRowsPerPage: "Registros por página",
                                                lastTooltip: "Última página",
                                                lastAriaLabel: "Última página",
                                                labelRowsSelect: "Registros por página",
                                            }

                                        }}
                                        editable={{
                                            onRowUpdate: (newData, oldData) =>
                                                new Promise(resolve => {
                                                    updateData(oldData.id, newData);
                                                    resolve();
                                                })
                                        }}
                                        actions={[
                                            rowData =>  (rowData.enabled ?
                                                    ({
                                                        icon: 'visibility_off',
                                                        tooltip: 'Deshabilitar',
                                                        onClick: (event, rowData) => disable(rowData.id, rowData)
                                                    }) :
                                                    ({
                                                        icon: 'visibility',
                                                        tooltip: 'Habilitar',
                                                        onClick: (event, rowData) => enable(rowData.id, rowData)
                                                    })
                                            ),
                                            {
                                                icon: 'add',
                                                tooltip: 'Agregar Tipo de Servicio',
                                                isFreeAction: true,
                                                onClick: (event) => handleClickOpen()
                                            }
                                        ]}


                                        options={{
                                            actionsColumnIndex: -1,
                                            sorting: true,
                                            showTitle: true,
                                        }}

                                    /> */}
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>


        </div>


    );
}