import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './fonts.css';
import App from './app/App';
import { createBrowserHistory } from "history";
import {  CssBaseline, ThemeProvider } from '@material-ui/core';
import { initializeFirebase } from './push-notification';
import { askForPermissioToReceiveNotifications } from './push-notification';
import { LayoutProvider } from './util/Contexts/LayoutContext';
import { UserProvider } from './util/Contexts/UserContext';
import themes from './assets/themes';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/genie.css';
import { Spinner } from "./components/spinner";
import errorResponseHandler from "./util/errorResponseHandler";
import axios from "axios";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';

const hist = createBrowserHistory();


// apply interceptor on response
axios.interceptors.response.use(
    response => response,
    errorResponseHandler
);

ReactDOM.render(
    <LayoutProvider>
    <UserProvider>
      <ThemeProvider theme={themes.default}>
        <CssBaseline />


            <Spinner/>
                <App/>
            <Alert stack={{limit: 3}}
                   timeout = {8000}
                   position='top-right' effect='genie' offset={65} />



      </ThemeProvider>
    </UserProvider>
  </LayoutProvider>,
    document.getElementById('root')
);

