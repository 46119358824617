import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { Dialog, DialogContent, DialogTitle, DialogActions, TextField, Button, DialogContentText } from "@material-ui/core"
// core components
import Navbar from "../Navbars/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import routes from "../../app/routes";
import useStyles from "./styles";
import logo from "../../assets/img/logo2.png";
import ProfilePage from "../../app/views/user/profile/Profile";
import TicketDetail from "../../app/views/ticket/TicketDetail";
import { changePasswordService, getUser } from "../../util/Contexts/UserContext";
import Alert from "react-s-alert";
import { USER_SESSION } from "../../assets/constants";
import Footer from "../Footer/Footer";
import ChangePasswordDialog from "../../app/views/user/users/ChangePasswordDialog";



let ps;

const switchRoutes = (

  <Switch>
    <Route path="/admin/request/:id" component={TicketDetail} key="request" />
    {routes.map((prop, key) => {
      if (prop.layout === "/admin" && prop.visible === true) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Route path="/admin/profile/:id" component={ProfilePage} key="profile" />

    <Redirect from="/admin" to="/admin/dashboard" />


  </Switch>
);

export default function Admin({ ...rest }) {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image] = React.useState(null);
  const [color] = React.useState("blue");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openChangePassword, setOpenChangePassword] = React.useState(false);
  const [state, setState] = React.useState({
    password: "",
    confirmPassword: "",
  });


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return true;
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const handleChange = event => {
    setState({
      ...state,
      [event.target.name]: event.target.value
    });
  };

  React.useEffect(() => {
    setOpenChangePassword(getUser().firstLogin)
  }, [])

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  return (

    <div className={classes.wrapper}>
      <ChangePasswordDialog
        open={openChangePassword}
        text="Antes de continuar ingresa tu nueva contraseña"
        userId={getUser().id}
        onChangedPassword={() => { window.location.reload(); }}
      />
      <Sidebar
        routes={routes.filter((value) => value.visible)}
        logoText={""}
        image={image}
        logo={logo}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
        {/*{getRoute() ? <Footer /> : null}*/}
        <Footer />
      </div>

    </div>
  );
}
