import React, { useEffect } from 'react';
import {makeStyles, TextField} from "@material-ui/core";
// core components
import GridItem from "../../../../components/Grid/GridItem.js";
import GridContainer from "../../../../components/Grid/GridContainer.js";
import Card from "../../../../components/Card/Card.js";
import CardHeader from "../../../../components/Card/CardHeader.js";
import CardAvatar from "../../../../components/Card/CardAvatar.js";
import CardBody from "../../../../components/Card/CardBody.js";
import {API_BASE_URL, API_AUTENTICATION_HEADERS} from "../../../../assets/constants";
import axios from 'axios';
import Alert from "react-s-alert";
import {primaryColor} from "../../../../assets/jss/material-dashboard-react";
import {getUserInformation} from "../../../../util/Contexts/UserContext";


const styles = {
    cardCategoryWhite: {
        color: primaryColor[0],
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: primaryColor[0],
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        marginBottom: "3px",
        textDecoration: "none"
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: "10px",
        marginRight: "10px",
    },
    dense: {
        marginTop: "10px",
    },
    menu: {
        width: 200,
    },
};

const useStyles = makeStyles(styles);



export default function Profile(...props)  {

    const [state, setState] = React.useState({
        idUser: "",
        user: {}
    });

    function getUser(){
        const { match: { params } } = props[0];

        getUserInformation(params.id).then(respuesta => {
            setState({
                ...state,
                user: respuesta.data.data
            });
        } )

    }

    useEffect(value => {
        getUser();
    },[]);


    const classes = useStyles();

        return (
            <div>
                <Card >
                <GridContainer>
                    <GridItem xs={12} sm={12} md={8}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Perfil</h4>
                            </CardHeader>
                            <CardBody>
                                <form autoComplete="off" onSubmit={() => console.log("summit")}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={7}>
                                        <TextField
                                            disabled={true}
                                            id="filled-full-width"
                                            label="Nombre"
                                            placeholder="Nombre"
                                            fullWidth
                                            margin="normal"
                                            required="true"
                                            value={state.user.name}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={5}>
                                        <TextField
                                            disabled={true}
                                            id="filled-full-width"
                                            label="Correo"
                                            required="true"
                                            value={state.user.email}
                                            placeholder="Correo electronico"
                                            fullWidth
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <TextField
                                            disabled={true}
                                            id="filled-full-width"
                                            label="Apellidos"
                                            required="true"
                                            value={state.user.lastName}
                                            placeholder="Apellidos"
                                            fullWidth
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <TextField
                                            disabled={true}
                                            id="filled-full-width"
                                            label="Teléfono"
                                            value={state.user.phone}
                                            placeholder="Teléfono"
                                            fullWidth
                                            margin="normal"
                                            required="true"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                </form>
                            </CardBody>
                            {/*<CardFooter>*/}
                            {/*    <Button type="submit" color="primary">Actualizar</Button>*/}
                            {/*</CardFooter>*/}
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <Card profile>
                            {/*<CardAvatar profile>*/}
                            {/*    <a href="#" onClick={e => e.preventDefault()}>*/}
                            {/*        {*/}
                            {/*        state.user.imageUrl ? (*/}
                            {/*        <img src={state.user.imageUrl} alt={state.user.name}/>*/}
                            {/*        ) : (*/}
                            {/*        <div className="text-avatar">*/}
                            {/*            <span>{state.user.name && state.user.name[0]}</span>*/}
                            {/*        </div>*/}
                            {/*        )*/}
                            {/*        }*/}
                            {/*    </a>*/}
                            {/*</CardAvatar>*/}
                            <CardBody profile>
                                <h6 className={classes.cardCategory}>{state.user.roles && state.user.roles.map(({showName}) => `${showName} `).join(', x')}</h6>
                                <h4 className={classes.cardTitle}>{state.user.name} {state.user.lastName}</h4>
                                <p className={classes.description}>

                                </p>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                </Card>
            </div>
        );

}
