import React, { useCallback, useEffect } from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import draftToHtml from 'draftjs-to-html';
// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import EditIcon from '@material-ui/icons/Edit';
import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import CardBody from "../../../components/Card/CardBody.js";
import TimerIcon from '@material-ui/icons/Timer';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import axios from 'axios';
import Alert from "react-s-alert";
import {
    API_BASE_URL,
    API_MULTIPART_HEADERS,
    API_AUTENTICATION_HEADERS,
    TOOLBAR_OPTIONS
} from "../../../assets/constants";
import {
    primaryColor,
    secondaryColor,
    tertiaryColor,
    whiteColor
} from "../../../assets/jss/material-dashboard-react";
import {
    Button, Checkbox,
    ButtonGroup, CardContent,
    Dialog, FormControlLabel,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl, Icon, InputAdornment, InputLabel, Paper, Select, TextField,
    Typography, ButtonBase, CircularProgress, Divider
} from "@material-ui/core";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import clsx from "clsx";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Grid from '@material-ui/core/Grid';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import GridItem from "../../../components/Grid/GridItem";
import { useInterval } from "../../../util/util";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import {getUser, hasRole} from "../../../util/Contexts/UserContext";
import {changeCategory, changePriority, getAllPrioritysEnabled} from "../../../util/Contexts/PriorityContext";
import { handleError } from "../../../util/Contexts/ErrorContext";
import { getAllCategoryByDepartment } from "../../../util/Contexts/CategoryContext";
import { Editor } from 'react-draft-wysiwyg';
import {
    commentTicketService,
    getOwnersList,
    getTicketInfo,
    reassignDepartment
} from "../../../util/Contexts/TicketContext.js";
import {getAllDepartmentsEnabled} from "../../../util/Contexts/DepartmentContext";
import MenuItem from "@material-ui/core/MenuItem";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import {getAllFrequentAnswersByDepartment} from "../../../util/Contexts/FrequentAnswersContext";
import htmlToDraft from 'html-to-draftjs';
import {
    closeTicketService,
    getTicketActivitys,
    getTicketDetail, getTicketFiles,
    getTicketImages, reassignDepartmentForDetail, reassignPriority, reassignResponsable,
    reassignTicket, reopenTicket
} from "../../../util/Contexts/TicketContext";
import JSZip from "jszip";
import {saveAs} from "file-saver";
import Link from "@material-ui/core/Link";
import MessageList from "../../../components/MessageList";
import Messenger from "../../../components/Messenger";
import AssignmentIcon from '@material-ui/icons/Assignment';
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Tooltip from "@material-ui/core/Tooltip";
import {Redirect, useHistory} from "react-router-dom";
import MobileStepper from "@material-ui/core/MobileStepper";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";
import useTheme from "@material-ui/core/styles/useTheme";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType);


var refreshActivity = true
export default function TicketDetail(...props) {

    const theme = useTheme();

    const styles = {
        root: {
            width: '100%',
            overflow: 'auto',
            backgroundColor: whiteColor[0],
        },
        inline: {
            display: 'inline',
        },
        cardCategoryWhite: {
            "&,& a,& a:hover,& a:focus": {
                color: primaryColor[0],
                margin: "0",
                fontSize: "14px",
                marginTop: "0",
                marginBottom: "0"
            },
            "& a,& a:hover,& a:focus": {
                color: primaryColor[0]
            }
        },
        input: {
            display: 'none',
        },
        cardTitleWhite: {
            color: primaryColor[0],
            marginTop: "0px",
            minHeight: "auto",
            fontWeight: "300",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#777",
                fontSize: "65%",
                fontWeight: "400",
                lineHeight: "1"
            }
        },
        button: {
            margin: "auto",
        },
        leftIcon: {
            marginRight: "10px",
        },
        iconSmall: {
            fontSize: 20,
        },
        paper: {
            padding: "10px",
            margin: 'auto',
            width: "100%",
        },
        image: {
            width: 128,
            height: 128,
        },
        img: {
            margin: 'auto',
            display: 'block',
            maxWidth: '100%',
            maxHeight: '100%',
        },
        rightIcon: {
            marginLeft: "10px",
        },
        avatarIcon: {
            color: whiteColor,
            backgroundColor: tertiaryColor[0]
        },
        card: {
            margin: 'auto',
            width: "100%",
            padding: "10px",
        },
        gallery: {
            zIndex: 10
        },
        form: {
            width: '100%',
        },
        boldText: {
            fontWeight: "bold"
        },
        stepper: {
            backgroundColor: theme.palette.background.default,
            borderBottomLeftRadius: '20px',
            borderBottomRightRadius: '20px'
        },
    };

    const useStyles = makeStyles(styles);

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [openConfirmOpen, setOpenConfirmOpen] = React.useState(false);
    const [owners, setOwners] = React.useState([]);
    const [openOwners, setOpenOwners] = React.useState(false);
    const [offices, setOffices] = React.useState([]);
    const [prioritys, setPrioritys] = React.useState([]);
    const [categorys, setCategorys] = React.useState([]);
    const [activitys, setActivitys] = React.useState([]);
    const [editPriority, setEditPriority] = React.useState(false);
    const [editCategory, setEditCategory] = React.useState(false);

    const [activeStep, setActiveStep] = React.useState(0);
    const [maxSteps, setMaxSteps] = React.useState(0);
    const [ids, setIds] = React.useState([]);

    const [openReassigDepartment, setOpenReassigDepartment] = React.useState(false);
    const [openReassingned, setOpenReassingned] = React.useState(false);
    const [suggestionOptions, setSuggestionOptions] = React.useState([]);
    const [state, setState] = React.useState({
        ticket: null,
        modalIsOpen: false,
        files: [],
        comment: EditorState.createEmpty(),
        photoIndex: 0,
        isOpen: false,
        classification: "",
        ownerAsigned: 0,
        department: 0,
        departmentAssigned: 0,
        officeAsigned: 0,
        priorityAsigned: 0,
        categoryAsigned: 0,
        checkedF: true,
        checkedPSS: false,
        checkedIntern: false,
        redirect: false,
        reason: "",
        ids: []
    });

    function handleClickOpenConfirm(row) {
        setOpenConfirm(true);
    }

    function handleCloseConfirm() {
        setOpenConfirm(false);
    }

    function reassignedDepartment(event) {
        event.preventDefault()
        reassignDepartmentForDetail(state).then(respuesta => {
            getTicket()
            setOpenReassigDepartment(false);
        } )
    }

    useInterval(() => { if (state.ticket != null && refreshActivity ) {getActivity(state.ticket.id)} }, 5000);

    function handleClickOpenConfirmOpen(row) {
        setOpenConfirmOpen(true);
    }

    function handleCloseConfirmOpen() {
        setOpenConfirmOpen(false);
    }

    function handleClickOpen(row) {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
        setOpenReassigDepartment (false);
        setOpenReassingned(false)
    }

    function getActivity(id) {
        getTicketActivitys(id).then(respuesta => {
            setActivitys(respuesta.data.data);
        })
    }

    function handleClickOpenReasingnedOwners(rowData) {
        getAllCategory(state.ticket.department.id);
        getOwners2(state.ticket.department.id, state.ticket.campus.id, 2);
    }

    function handleReasingnedDepartment(rowData) {
       setOpenReassigDepartment(true);
    }

    const getOwners2 = (id, campus, type) => {
        getOwnersList(id, campus).then(respuesta => {
            setOwners(respuesta.data.data);
            type === 1 ? setOpenOwners(true) : setOpenReassingned(true);
        })
    };

    const getSuggestionOptions = (id) => {
        getAllFrequentAnswersByDepartment(id).then(respuesta => {
            setSuggestionOptions(respuesta.data.data);

        });
    };

    function handleClickCloseReasingnedOwners() {
        setOpenReassingned(false);
    }

    function reassignedReport() {
        var bodyFormData = new FormData();
        bodyFormData.append("priority", state.ticket.priority.id);
        reassignPriority(state, bodyFormData).then(respuesta => {
            setOpenReassingned(false);
            setState({
                ...state,
                redirect: true
            })
            // Alert.success(respuesta.data.message);
            // getTicket();
        })
    }
    function handleCloseOwners() {
        setOpenOwners(false);
    }

    function assingnedTicket(event) {
        event.preventDefault()
        reassignResponsable(state).then(respuesta => {
            setOpen(false);
            Alert.success(respuesta.data.message);
            getTicket();
        })
    }

    function closeTicket(event) {
        event.preventDefault();

        var bodyFormData = new FormData();
        bodyFormData.append("isInternal", false);
        closeTicketService(state, bodyFormData).then(respuesta => {
            Alert.success(respuesta.data.message);
            setOpenConfirm(false);
            getTicket();
        })

    }

    function openTicket() {
        setOpenConfirmOpen(false);
        reopenTicket(state).then(respuesta => {
            getTicket();
        })
    }

    const handleChange = () => event => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        });
    };

    function handleDepartamentChange(event) {
        event.preventDefault();
        setState({
            ...state,
            officeAsigned: event.target.value
        });
        getAllCategory(event.target.value);
        getOwners(event.target.value, state.ticket.campus.id);
    }

    function commentTicket(event) {
        event.preventDefault()
        refreshActivity = false
        commentTicketService(state, state.ticket.id).then(respuesta => {
            Alert.success(respuesta.data.message);
            setState({...state, comment: EditorState.createEmpty(), files: []})
            refreshActivity = true
        })
    }

    const getTicket = () => {
        const { match: { params } } = props[0];
        getTicketDetail(params.id).then(respuesta => {
            console.log(respuesta)
            setState({
                ...state,
                files: [],
                filesShow: [],
                suggestionSelect: "",
                comment: EditorState.createEmpty(),
                ticket: respuesta.data.data,
                officeAsigned: respuesta.data.data.department.id,
                categoryAsigned: respuesta.data.data.category ? respuesta.data.data.category.id : 0
            });

            if (!respuesta.data.data.isDataComplete) {
                getOwners(respuesta.data.data.department.id, respuesta.data.data.campus.id);
            } else {
                getActivity(respuesta.data.data.id);
            }

            getAllCategory(respuesta.data.data.department.id);
            getSuggestionOptions(respuesta.data.data.department.id)

        })

    };

    function assignedReport(event) {
        event.preventDefault();
        reassignTicket(state).then(respuesta => {
            getTicket();
            setState({
                ...state,
                officeAsigned: 0,
                priorityAsigned: 0,
                categoryAsigned: 0,
                ownerAsigned: 0,
            });
        })
    }

    const getAllPrioritys = async () => {
        getAllPrioritysEnabled().then(respuesta => {
            setPrioritys(respuesta.data.data);
        })
    };

    const changePriorityAction = async () => {
        if (state.priorityAsigned !== 0) {
            changePriority(state.ticket.id, state.priorityAsigned).then(respuesta => {
                getTicket();
                setEditPriority(false);
                Alert.success(respuesta.data.message);
            })
        } else {
            setEditPriority(false);
        }
    };

    const changeCategoryAction = async () => {
        if (state.categoryAsigned !== 0) {
            changeCategory(state.ticket.id, state.categoryAsigned).then(respuesta => {
                getTicket();
                setEditCategory(false);
                Alert.success(respuesta.data.message);
            })
        } else {
            setEditCategory(false);
        }
    };

    const getAllCategory = async (id) => {
        getAllCategoryByDepartment(id).then(respuesta => {
            setCategorys(respuesta.data.data);

        })
    };

    const getOwners = (id, campus) => {
        getOwnersList(id, campus).then(respuesta => {
            setOwners(respuesta.data.data);
        })
    };

    function dowloadFiles(){

        getTicketFiles(state.ticket.id).then(({data}) => {

            if(data.data.length === 1) {
                var a = document.createElement("a"); //Create <a>
                a.href = `data:${data.data[0].fileType};base64,${data.data[0].data}`; //Image Base64 Goes here
                a.download = data.data[0].fileName; //File name Here
                a.click(); //Downloaded file
            }else {
                var zip = new JSZip();
                data.data.forEach( f => {
                    zip.file(f.fileName, f.data, {base64: true});
                })

                zip.generateAsync({type:"blob"}).then(function(content) {
                    // see FileSaver.js
                    saveAs(content, "Archivos.zip");
                });
            }
        })

    }

    function createButtons() {
        switch (state.ticket.ticketStatus.id) {
            case 3:
                if (state.ticket.isInternalTicket) {
                    return (
                        <ButtonGroup color="primary" variant="contained" size="small" aria-label="small contained button group">
                            {
                                <Button onClick={handleClickOpenConfirmOpen} color="secondary">
                                    <LockOpenIcon className={clsx(classes.leftIcon, classes.iconSmall)} />
              Abrir
              </Button>
                            }
                        </ButtonGroup>
                    )
                } else {
                    return (
                        <ButtonGroup color="primary" variant="contained" size="small" aria-label="small contained button group">

                            {
                                <Button onClick={handleClickOpenConfirmOpen} color="secondary">
                                    <LockOpenIcon className={clsx(classes.leftIcon, classes.iconSmall)} />
                  Abrir
                  </Button>
                            }
                        </ButtonGroup>
                    )
                }
            default:
                return (
                    <ButtonGroup color="primary" variant="contained" size="small" aria-label="small contained button group">
                        {
                            state.ticket.closed && state.ticket.ticketStatus.id === 9 &&
                            <Button onClick={handleClickOpenConfirmOpen} color="secondary">
                                <LockOpenIcon className={clsx(classes.leftIcon, classes.iconSmall)} />
                Abrir
              </Button>
                        }

                        {
                            !state.ticket.closed &&
                            <Button color="primary" onClick={handleClickOpenConfirm}>
                                <LockIcon className={clsx(classes.leftIcon, classes.iconSmall)} />
                Cerrar
              </Button>
                        }
                        {
                            state.ticket.owner.id !== getUser().id && !state.ticket.closed && (
                                <Button style={{ backgroundColor: "#789D5B" }}
                                    onClick={handleClickOpen}>
                                    <AssignmentIndIcon
                                        className={clsx(classes.leftIcon, classes.iconSmall)} />
                                    Asignarme
                                </Button>
                            )
                        }
                        {
                            !state.ticket.closed && (
                                <Button style={{ backgroundColor: "#e3cf40" }}
                                        onClick={handleReasingnedDepartment}>
                                    <AssignmentIcon
                                        className={clsx(classes.leftIcon, classes.iconSmall)} />
                                    Reasignar departamento
                                </Button>
                            )
                        }
                        {
                            !state.ticket.closed && (
                                <Button style={{ backgroundColor: "#62c5e3" }}
                                    onClick={handleClickOpenReasingnedOwners}>
                                    <AssignmentIndIcon
                                        className={clsx(classes.leftIcon, classes.iconSmall)} />
                  Reasignar responsable
                                </Button>
                            )
                        }
                    </ButtonGroup>
                )
        }
    }

    const getAllDepartmetsData = async () => {
        getAllDepartmentsEnabled().then(respuesta => {
            setOffices(respuesta.data.data);
        })
    };

    const history = useHistory();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        // setState({
        //     ...state,
        //     id: ids[activeStep + 1].id
        // })
        console.log('/admin/request/' + ids[activeStep + 1])
        history.push('/admin/request/' + ids[activeStep + 1])
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        history.push('/admin/request/' + ids[activeStep - 1])
    };


    useEffect(value => {
        const { match: { params } } = props[0];
        let ids = props[0].location && props[0].location.state && props[0].location.state.ids
        let index = ids && ids.findIndex(o => o.id === params.id) + 1

        setIds(ids)
        setMaxSteps(ids ? ids.length : 0)
        setActiveStep(index)

        getTicket();
        getAllPrioritys();
        getAllDepartmetsData();
    }, []);

    useEffect(value => {
        getTicket()
    }, [activeStep])

    const onContentStateChange = (contentState) => {
        setState({
            ...state,
            comment: contentState,
        });
    };

    if (state.redirect) {
        return <Redirect push to={"/admin/request"} />
    }

    if (state.ticket === null) {
        return (<div> </div>);
    }

    return (
        <div>
            <Dialog open={openConfirmOpen} onClose={handleCloseConfirmOpen}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Mensaje de confirmación"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        ¿Está seguro que desea abrir esta solicitud?
          </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirmOpen} color="primary">
                        Cancelar
          </Button>
                    <Button onClick={openTicket} color="primary">
                        Aceptar
          </Button>
                </DialogActions>
            </Dialog>


            <Dialog open={openConfirm} onClose={handleCloseConfirm}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Mensaje de confirmación"}</DialogTitle>
                <form onSubmit={closeTicket}>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            ¿Está seguro que desea cerrar esta solicitud?
              </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseConfirm} color="primary">
                            Cancelar
              </Button>
                        <Button type={"submit"} color="primary">
                            Aceptar
              </Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog open={openReassingned} onClose={handleClose}>
                <form onSubmit={reassignedReport} className={classes.root} autoComplete="off">
                    <DialogTitle id="form-dialog-title">Reasignar responsable</DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth required>
                            <InputLabel htmlFor="ownerAsigned">Responsable</InputLabel>
                            <Select
                                required
                                native
                                value={state.ownerAsigned}
                                onChange={handleChange()}
                                inputProps={{
                                    name: 'ownerAsigned',
                                    id: 'ownerAsigned',
                                }}
                            >
                                <option value="" />
                                {owners.map(function (value) { return (<option key={value.id} value={value.id} > {value.completeName}</option>); })}
                            </Select>
                        </FormControl>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancelar
            </Button>
                        <Button type={"submit"} color="primary">
                            Aceptar
            </Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog open={openReassigDepartment} onClose={handleClose}>
                <form onSubmit={reassignedDepartment} className={classes.root} autoComplete="off">
                    <DialogTitle id="form-dialog-title">Reasignar Departamento</DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth required>
                            <InputLabel htmlFor="departmentAssigned">Departamento</InputLabel>
                            <Select
                                required
                                native
                                value={state.departmentAssigned}
                                onChange={handleChange()}
                                inputProps={{
                                    name: 'departmentAssigned',
                                    id: 'departmentAssigned',
                                }}
                            >
                                <option value="" />
                                {offices.map(function(value) { return (<option key={value.id} value={value.id} > {value.name}</option> );})}
                            </Select>
                        </FormControl>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancelar
                        </Button>
                        <Button type={"submit"} color="primary">
                            Aceptar
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog open={open} onClose={handleClose}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Mensaje de confirmación"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        ¿Está seguro que desea asignarse esta solicitud?
            </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
            </Button>
                    <Button onClick={assingnedTicket} color="primary">
                        Aceptar
            </Button>
                </DialogActions>
            </Dialog>
            <GridContainer>

                <Grid container={true} >
                    {maxSteps > 0 && <Grid item={true} xs={12} sm={12} md={12}>
                        <MobileStepper
                            className={classes.stepper}
                            steps={maxSteps}
                            position="static"
                            variant="text"
                            activeStep={activeStep}
                            nextButton={
                                <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                                    SIGUIENTE
                                    {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                                </Button>
                            }
                            backButton={
                                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                    {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                                    ANTERIOR
                                </Button>
                            }
                        />
                    </Grid>}
                    <Card>
                        <CardHeader color="secondary">
                            <Grid container={true} direction="row" alignItems="flex-start">
                                <Grid item={true} xs={12} sm={12} md={4}>
                                    <h4 className={classes.cardTitleWhite}>#{state.ticket.ticketNumber}</h4>
                                </Grid>
                                <Grid item={true} xs={12} sm={12} md={12}>
                                    <div align="right">
                                        { state.ticket.isDataComplete && createButtons()}
                                    </div>
                                </Grid>
                            </Grid>
                        </CardHeader>
                        <CardBody>
                            <Grid container={true} spacing={1} direction="row" >

                                <Grid item={true} xl={6} md={6} xs={6} >
                                    <Typography style={{ display: 'inline-block' }} className={classes.boldText} >Departamento: </Typography> <Typography style={{ display: 'inline-block' }} gutterBottom>{state.ticket.department ? (state.ticket.department.name) : ""}</Typography>
                                </Grid>

                                <Grid item={true} xl={6} md={6} xs={6}>
                                    <Typography style={{ display: 'inline-block' }} className={classes.boldText} >Estatus: </Typography> <Typography style={{ display: 'inline-block' }} gutterBottom>{state.ticket.ticketStatus ? (state.ticket.ticketStatus.name) : "No identificado"}</Typography>
                                </Grid>

                                <Grid item={true} xl={6} md={6} xs={6}>
                                    <Typography style={{ display: 'inline-block' }} className={classes.boldText} >Enviado: </Typography> <Typography style={{ display: 'inline-block' }} gutterBottom>{state.ticket.createDate}</Typography>
                                </Grid>

                                {/*{(state.ticket.isDataComplete) &&*/}
                                <Grid item={true} xl={6} md={6} xs={6}>
                                    <Typography style={{display: 'inline-block'}}
                                                className={classes.boldText}>Categoría: </Typography> <Typography
                                    style={{display: 'inline-block'}} className={classes.boldText}> </Typography>
                                    {editCategory ?
                                        <div>
                                            <Select
                                                required
                                                native
                                                value={state.categoryAsigned}
                                                onChange={handleChange()}
                                                inputProps={{
                                                    name: 'categoryAsigned',
                                                    id: 'categoryAsigned',
                                                }}
                                            >
                                                <option value=""/>
                                                {categorys.map(function (value) {
                                                    return (<option key={value.name}
                                                                    value={value.id}> {value.name}</option>);
                                                })}
                                            </Select>
                                            <ButtonBase><SaveIcon onClick={() => changeCategoryAction()}/></ButtonBase>
                                            <ButtonBase
                                                onClick={() => setEditCategory(false)}><CancelIcon/></ButtonBase>
                                        </div>
                                        :
                                        <Typography style={{display: 'inline-block'}} gutterBottom>
                                            {state.ticket.category ? state.ticket.category.name : ""}
                                            <ButtonBase onClick={() => setEditCategory(true)}>
                                                <EditIcon fontSize={"small"}/>
                                            </ButtonBase>
                                        </Typography>}
                                </Grid>
                                {/*}*/}

                                {(state.ticket.isDataComplete) &&
                                <Grid item={true} xl={6} md={6} xs={6}>
                                    <Typography style={{display: 'inline-block'}}
                                                className={classes.boldText}>Responsable: </Typography> <Typography
                                    style={{display: 'inline-block'}}
                                    gutterBottom>{state.ticket.owner ? (state.ticket.owner.completeName) : "Sin asignar"}</Typography>
                                </Grid>
                                }

                                {(state.ticket.isDataComplete) &&
                                <Grid item={true} xl={6} md={6} xs={6}>
                                    <Typography style={{ display: 'inline-block' }} className={classes.boldText} >Prioridad: </Typography> <Typography style={{ display: 'inline-block' }} className={classes.boldText} > </Typography>
                                    {editPriority ?
                                        <div>
                                            <Select
                                                required
                                                value={state.priorityAsigned}
                                                onChange={handleChange()}
                                                inputProps={{
                                                    name: 'priorityAsigned',
                                                    id: 'priorityAsigned',
                                                }}
                                            >
                                                <MenuItem value={""}/>
                                                {prioritys.map(function (value) {
                                                        return (
                                                            <MenuItem key={value.id} value={value.id}>
                                                                <FiberManualRecordIcon
                                                                    style={{color: value.color}}/> {value.name}
                                                            </MenuItem>);
                                                    }
                                                )}
                                            </Select>
                                            <ButtonBase><SaveIcon onClick={() => changePriorityAction()} /></ButtonBase>
                                            <ButtonBase onClick={() => setEditPriority(false)}><CancelIcon /></ButtonBase>
                                        </div>
                                        :
                                        <div style={{ display: 'inline-block' }}>
                                        <FiberManualRecordIcon fontSize={"small"}
                                            style={{color: state.ticket.priority.color}}/>
                                            <Tooltip title={ "Una forma de asegurar la calidad del servicio es resolver la solicitud entre " + state.ticket.priority.lowHour + " y " + state.ticket.priority.highHour + " horas."} aria-label="add">

                                            <Typography style={{ display: 'inline-block' }} gutterBottom>
                                            {state.ticket.priority.name}
                                            <ButtonBase onClick={() => setEditPriority(true)}>
                                                <EditIcon fontSize={"small"} />
                                            </ButtonBase>
                                        </Typography>
                                            </Tooltip>
                                        </div>
                                    }
                                </Grid>
                                }

                                {state.ticket.closedDate && (
                                    <Grid item={true} xl={6} md={6} xs={6} >
                                        <Typography style={{ display: 'inline-block' }} className={classes.boldText} >Cerrado: </Typography> <Typography style={{ display: 'inline-block' }} gutterBottom>{state.ticket.closedDate}</Typography>
                                    </Grid>
                                )}

                            </Grid>

                            <br/>

                            <Grid container={true} spacing={1} direction="row" >
                                <Grid item={true} xl={6} md={6} xs={6}>
                                    <Typography style={{ display: 'inline-block' }} className={classes.boldText} >Alumno: </Typography> <Typography style={{ display: 'inline-block' }} gutterBottom>{state.ticket.author ? (state.ticket.author.completeName) : ""}</Typography>
                                </Grid>

                                <Grid item={true} xl={6} md={6} xs={6}>
                                    <Typography style={{ display: 'inline-block' }} className={classes.boldText} >Matrícula: </Typography> <Typography style={{ display: 'inline-block' }} gutterBottom>{ state.ticket.author ? (state.ticket.author.student ? (state.ticket.author.student.identifier) : "No disponible") : "No disponible"}</Typography>
                                </Grid>

                                <Grid item={true} xl={6} md={6} xs={6}>
                                    <Typography style={{ display: 'inline-block' }} className={classes.boldText} >Correo electrónico: </Typography> <Typography style={{ display: 'inline-block' }} gutterBottom>{state.ticket.email ? (state.ticket.email) : (state.ticket.author ? state.ticket.author.email : "Sin correo electrónico") }</Typography>
                                </Grid>

                                <Grid item={true} xl={6} md={6} xs={6}>
                                    <Typography style={{ display: 'inline-block' }} className={classes.boldText} >Teléfono: </Typography> <Typography style={{ display: 'inline-block' }} gutterBottom>{state.ticket.phoneNumber ? (state.ticket.phoneNumber) : "Sin teléfono capturado"}</Typography>
                                </Grid>

                                <Grid item={true} xl={6} md={6} xs={6} >
                                    <Typography style={{ display: 'inline-block' }} className={classes.boldText} >Campus: </Typography> <Typography style={{ display: 'inline-block' }} gutterBottom>{state.ticket.campus ? state.ticket.campus.name : "No disponible"}</Typography>
                                </Grid>

                                <Grid item={true} xl={6} md={6} xs={6} >
                                    <Typography style={{ display: 'inline-block' }} className={classes.boldText} >Nivel académico: </Typography> <Typography style={{ display: 'inline-block' }} gutterBottom>{state.ticket.author ? (state.ticket.author.student ? state.ticket.author.student.level ||  "No disponible" : "No disponible") : "No disponible"}</Typography>
                                </Grid>

                                <Grid item={true} xl={6} md={6} xs={6} >
                                    <Typography style={{ display: 'inline-block' }} className={classes.boldText} >Plan de estudios: </Typography> <Typography style={{ display: 'inline-block' }} gutterBottom>{state.ticket.author ? (state.ticket.author.student ? ((state.ticket.author.student.studyNumber || "No disponible") + "-" + (state.ticket.author.student.studyName || "No disponible") ) : "No disponible") : "No disponible"}</Typography>
                                </Grid>

                                <Grid item={true} xl={6} md={6} xs={6} >
                                    <Typography style={{ display: 'inline-block' }} className={classes.boldText} >Programa: </Typography> <Typography style={{ display: 'inline-block' }} gutterBottom>{state.ticket.author ? (state.ticket.author.student ? ((state.ticket.author.student.programNumber || "No disponible") + "-" + (state.ticket.author.student.programName || "No disponible")) : "No disponible") : "No disponible"}</Typography>
                                </Grid>
                            </Grid>

                        </CardBody>
                    </Card>
                    <Paper className={classes.paper}>
                        <Grid container={true} spacing={2}>
                            <Grid item={true} md={12} xs={12} sm={12} >
                                        <Typography component={'div'} variant="body2" gutterBottom >
                                            <div dangerouslySetInnerHTML={{ __html: state.ticket.content || "" }} />
                                            {state.ticket.hasFile &&
                                            <Link color={"initial"} onClick={() => dowloadFiles()}>
                                                Descargar adjuntos
                                            </Link>
                                            }
                                        </Typography>
                            </Grid>
                        </Grid>
                    </Paper>

                    {(!state.ticket.isDataComplete) &&
                    <Card>
                        <div align="center">
                            <Card className={classes.card}>
                                <CardHeader color="secondary">
                                    <div align="center">
                                        Es necesario asignar el responsable a la solicitud para generar respuestas
                                    </div>
                                </CardHeader>
                                <CardContent>
                                    <form className={classes.form} autoComplete="off" onSubmit={assignedReport}>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <FormControl fullWidth required>
                                                    <InputLabel
                                                        htmlFor="officeAsigned">Departamento</InputLabel>
                                                    <Select
                                                        required
                                                        value={state.officeAsigned}
                                                        onChange={handleDepartamentChange}
                                                        inputProps={{
                                                            name: 'officeAsigned',
                                                            id: 'officeAsigned',
                                                        }}
                                                        style={{textAlign: 'initial'}}
                                                    >
                                                        <option value=""/>
                                                        {offices.map(function (value) {
                                                            return (<option key={value.name}
                                                                            value={value.id}> {value.name}</option>);
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <FormControl fullWidth required>
                                                    <InputLabel htmlFor="ownerAsigned">Responsable</InputLabel>
                                                    <Select
                                                        required
                                                        value={state.ownerAsigned}
                                                        onChange={handleChange()}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            name: 'ownerAsigned',
                                                            id: 'ownerAsigned',
                                                        }}
                                                        style={{textAlign: 'initial'}}
                                                    >
                                                        <option value=""/>
                                                        {owners.map(function (value) {
                                                            return (<option key={value.completeName}
                                                                            value={value.id}> {value.completeName}</option>);
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </GridItem>
                                            <GridItem xs={12} sm={6} md={6}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor="categoryAsigned">Categoria</InputLabel>
                                                    <Select
                                                        value={state.categoryAsigned}
                                                        onChange={handleChange()}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        style={{textAlign: 'initial'}}
                                                        inputProps={{
                                                            name: 'categoryAsigned',
                                                            id: 'categoryAsigned',
                                                        }}>
                                                        <option value=""/>
                                                        {categorys.map(function (value) {
                                                            return (<option key={value.id}
                                                                            value={value.id}> {value.name}</option>);
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </GridItem>
                                            <GridItem xs={12} sm={6} md={6}>
                                                <FormControl fullWidth required>
                                                    <InputLabel htmlFor="priorityAsigned">Prioridad</InputLabel>
                                                    <Select
                                                        required
                                                        style={{textAlign: 'initial'}}
                                                        value={state.priorityAsigned}
                                                        onChange={handleChange()}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            name: 'priorityAsigned',
                                                            id: 'priorityAsigned',
                                                        }}
                                                    >
                                                        {/*<option value="" />*/}
                                                        {/*{prioritys.map(function (value) { return (<option key={value.name} value={value.id} > {value.name}</option>); })}*/}

                                                        <MenuItem value={""}/>
                                                        {prioritys.map(function (value) {
                                                                return (
                                                                    <MenuItem key={value.id} value={value.id}>
                                                                        <FiberManualRecordIcon
                                                                            style={{color: value.color}}/> {value.name}
                                                                    </MenuItem>);
                                                            }
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </GridItem>

                                            <GridItem xs={12} sm={12} md={12}>
                                                <br/>
                                                <Button variant="contained" fullWidth={true} type="submit"
                                                        color="primary">Guardar información</Button>
                                            </GridItem>
                                        </GridContainer>
                                    </form>
                                </CardContent>
                            </Card>
                        </div>
                    </Card>
                    }

                    <Card>
                        <CardHeader color="secondary">
                            <h4 className={classes.cardTitleWhite}>Respuestas</h4>
                        </CardHeader>
                        <CardBody>
                            <Messenger mesagges={activitys} idAdmin={state.ticket.author ? state.ticket.author.id : false} studentView={false}/>

                            { state.ticket.isDataComplete && state.ticket.ticketStatus.id !== 7 && <Paper className={classes.paper}>
                                    <form onSubmit={commentTicket}>
                                        <GridContainer spacing={2} >
                                            <GridItem xs={12} sm={12} md={12}>
                                                <GridContainer spacing={2} justify="flex-start" alignItems="center" style={{padding:"10px"}}>
                                                    <GridItem >
                                                        <Typography>Respuesta rapida:</Typography>
                                                    </GridItem>
                                                    <GridItem >
                                                        <FormControl fullWidth>
                                                            <Select
                                                                native
                                                                value={state.suggestionSelect}
                                                                onChange={(event) => { if(event.target.value !== ""){ setState({...state,suggestionSelect: event.target.value,  comment:  EditorState.createWithContent(
                                                                        ContentState.createFromBlockArray(htmlToDraft( suggestionOptions.find(r => r.id === parseInt(event.target.value)).content)))})}}}
                                                                inputProps={{
                                                                    name: 'suggestionSelect',
                                                                    id: 'suggestionSelect',
                                                                }}
                                                            >
                                                                <option value="" />
                                                                {suggestionOptions.map(function (value) { return (<option key={value.id} value={value.id} > {value.title}</option>); })}
                                                            </Select>
                                                        </FormControl>
                                                    </GridItem>
                                                </GridContainer>

                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={3}>
                                                <FilePond
                                                    id="contained-button-file"
                                                    files={state.files}
                                                    allowMultiple={true}
                                                    maxFiles={3}
                                                    allowImagePreview={false}
                                                    imagePreviewHeight={170}
                                                    onwarning={() => Alert.info("Solo puedes adjuntar 3 archivos a la respuesta")}
                                                    onupdatefiles={(files) =>
                                                        setState({
                                                        ...state,
                                                        files: files.map(fileItem => fileItem.file)
                                                    })}
                                                    // acceptedFileTypes={['image/*', 'application/vnd.ms-excel', 'application/msword', 'application/pdf']}
                                                    labelIdle='<span class="filepond--label-action">Adjuntar archivos</span>'
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={9}>
                                                <Editor
                                                    wrapperClassName="wrapper-class"
                                                    editorClassName="editor-class"
                                                    toolbarClassName="toolbar-class"
                                                    placeholder="Escriba su respuesta"
                                                    editorState={state.comment}
                                                    onEditorStateChange={onContentStateChange}
                                                    toolbar={TOOLBAR_OPTIONS}

                                                />

                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={12}>
                                            <div align="right" style={{padding:"10px"}}>
                                                <Button type={"submit"} variant="contained" color="primary">
                                                    Responder solicitud
                                                    <Icon className={classes.rightIcon}>send</Icon>
                                                </Button>
                                            </div>
                                            </GridItem>
                                        </GridContainer>

                                    </form>
                                </Paper>
                            }

                        </CardBody>
                    </Card>
                </Grid>
                {window.scrollTo(0, window.innerWidth)}
            </GridContainer>
        </div>
    );
}

