
import Dashboard from "@material-ui/icons/Dashboard";
import DashboardPage from "./views/Dashboard/Dashboard";
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import TicketPage from "./views/ticket/Ticket.js";
import ApartmentIcon from '@material-ui/icons/Apartment';
import DepartmentsPage from "./views/departamets/departmets";
import AdminsPage from "./views/user/admins/admins";
import UsersPage from "./views/user/users/users";
import OfficePage from "./views/office/officeList";
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import BusinessIcon from '@material-ui/icons/Business';
import PriorityPage from "./views/priority/priorityList";
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import {hasRole} from "../util/Contexts/UserContext";
import CreateTicketPage from "./views/ticket/CreateTicket";
import CreateIcon from '@material-ui/icons/Create';
import CategoryPage from "./views/category/categoryList";
import CategoryIcon from '@material-ui/icons/Category';
import ServiceTypePage from "./views/serviceType/serviceType";
import ServiceTypeIcon from "@material-ui/icons/Apps";
import ResolvedTicketPage from "./views/ticket/ResolvedTicket";
import RolesPage from "./views/roles/roleList";
import ResolvedTicketIcon from "@material-ui/icons/QuestionAnswer"
import GroupIcon from '@material-ui/icons/Group';
import TuneIcon from '@material-ui/icons/Tune';
import FrequentAnswersList from "./views/frequentAnswers/frequentAnswers";
import NoteAddIcon from '@material-ui/icons/NoteAdd';


import RateReviewIcon from '@material-ui/icons/RateReview';

const dashboardRoutes = [
  {
    path: "/Dashboard",
    name: "Tablero de control",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
    visible: true
  },
  {
    path: "/request",
    name: "Solicitudes",
    icon: ConfirmationNumberIcon,
    component: TicketPage,
    layout: "/admin",
    visible: hasRole("SUPER_USER") || hasRole("OPERATOR") || hasRole("SPECIALIST") || hasRole("ADMIN")
  },
  {
    path: "/resolved",
    name: "Evaluaciones",
    icon: ResolvedTicketIcon,
    component: ResolvedTicketPage,
    layout: "/admin",
    visible: hasRole("SUPER_USER") || hasRole("OPERATOR") || hasRole("SPECIALIST") || hasRole("ADMIN")
  },
  {
    path: "/createRequest",
    name: "Crear solicitud",
    icon: NoteAddIcon,
    component: CreateTicketPage,
    layout: "/admin",
    visible: hasRole("SPECIALIST") || hasRole("SUPER_USER") ||  hasRole("OPERATOR") || hasRole("ADMIN")
  },
  {
    path: "/admins",
    name: "Responsables",
    icon: SupervisedUserCircleIcon,
    component: AdminsPage,
    layout: "/admin",
    visible: hasRole("SUPER_USER")
  },
  // {
  //   path: "/serviceTypes",
  //   name: "Tipo de Servicios",
  //   icon: ServiceTypeIcon,
  //   component: ServiceTypePage,
  //   layout: "/admin",
  //   visible: hasRole("SUPER_USER") || hasRole("ADMIN")
  // },
  {
    path: "/users",
    name: "Alumnos",
    icon: GroupIcon,
    component: UsersPage,
    layout: "/admin",
    visible: hasRole("SUPER_USER")
  },
  {
    path: "/departmets",
    name: "Departamentos",
    icon: ApartmentIcon,
    component: DepartmentsPage,
    layout: "/admin",
    visible: hasRole("SUPER_USER")
  },

  {
    path: "/frequent-answers",
    name: "Respuestas rápidas",
    icon: RateReviewIcon,
    component: FrequentAnswersList,
    layout: "/admin",
    visible: hasRole("SPECIALIST") || hasRole("SUPER_USER") ||  hasRole("OPERATOR") || hasRole("ADMIN")
  },
  {
    path: "/categorys",
    name: "Categorias",
    icon: CategoryIcon,
    component: CategoryPage,
    layout: "/admin",
    visible: hasRole("SUPER_USER")
  },
  {
    path: "/prioritys",
    name: "Prioridades",
    icon: PriorityHighIcon,
    component: PriorityPage,
    layout: "/admin",
    visible: hasRole("SUPER_USER")
  },
  {
    path: "/campuses",
    name: "Campus",
    icon: BusinessIcon,
    component: OfficePage,
    layout: "/admin",
    visible: hasRole("SUPER_USER")
  },
  {
    path: "/roles",
    name: "Roles",
    icon: TuneIcon,
    component: RolesPage,
    layout: "/admin",
    visible: hasRole("SUPER_USER")
  },
];

export default dashboardRoutes;
