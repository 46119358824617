
import React from "react";
import classNames from "classnames";
// @material-ui/core components
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge"
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
// core components
import Button from "../CustomButtons/Button.js";

import useStyles from "./NavBarLinks";
import {Link} from "react-router-dom";
// context
import { useUserDispatch, signOut, getUser } from "../../util/Contexts/UserContext";
import CropFreeIcon from "@material-ui/icons/CropFree";
import IconButton from "@material-ui/core/IconButton";


export default function AdminNavbarLinks(props) {
  const classes = useStyles();
  const [badgeAux, setBadgeAux] = React.useState([]);
  const [badge, setBadge] = React.useState(0);
  const [notifications, setNotifications] = React.useState([]);
  const [openProfile, setOpenProfile] = React.useState(null);
  const [openNotification, setOpenNotification] = React.useState(null);
  var userDispatch = useUserDispatch();

  const handleClickProfile = event => {

    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleClickNotification = event => {
    setBadgeAux(notifications.map(n => n.id));
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };


  return (
    <div>

      <div className={classes.manager}>


        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          size={"lg"}
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <Person fontSize="large" className={classes.icons}/>
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Perfil</p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({[classes.popperClose]: !openProfile}) +
            " " +
            classes.popperNav
          }
        >
          {({TransitionProps, placement}) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    
                    <Link style={{ textDecoration: 'none', color: 'white' }} to={"/admin/profile/" + (getUser() ? getUser().id : 0) }>
                      <MenuItem
                        key={"1"}
                        href={"/admin/profile/" + (getUser() ? getUser().id : 0)}
                        onClick={handleCloseProfile}
                        className={classes.dropdownItem}
                      >
                        Perfil
                      </MenuItem>
                    </Link>
                    <Divider light/>
                    <MenuItem
                      key={"2"}
                      onClick={() => signOut(userDispatch, props.history) }
                      className={classes.dropdownItem}
                    >

                      Salir
                    </MenuItem>

                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  );
}
