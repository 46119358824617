import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {whiteColor} from "../../../assets/jss/material-dashboard-react";
import {
    Button,
    Grid,
    List,
    ListItem,
    TextField,
    Typography,
    withStyles,
    ListItemText,
    RadioGroup, Radio, FormControlLabel, FormControl, Dialog, DialogContent, DialogActions, Modal
} from "@material-ui/core";
import {APP_TITLE} from "../../../assets/constants";
import Header from "../../../components/Header/Header";
import {getTicketStudentQualify} from "../../../util/Contexts/TicketContext";
import Alert from "react-s-alert";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import {getQuestions, sendAnswers} from "../../../util/Contexts/QualifyContext";
import {FilePond} from "react-filepond";
import successData from "../../../assets/animation/success";
import errorData from "../../../assets/animation/error";
import Lottie from "react-lottie-player";
import GridItem from "../../../components/Grid/GridItem";

const useStyles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: whiteColor,
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: whiteColor
        }
    },
    cardTitleWhite: {
        color: whiteColor,
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    root: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        '& > *': {
            margin: 1,
        },
    }
});

class QualifyTicket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            ticket: {},
            questions: [],
            responses:[],
            openDialog: false
        }
        this.getData = this.getData.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.sendQualify = this.sendQualify.bind(this)
    }

   componentDidMount() {
        this.getData()
   }

   getData(){
       getTicketStudentQualify(this.state.id).then(({data}) => {
           if(!data.success) {

               Alert.warning(data.message)
               window.location.href = "/"
           }else {
               getQuestions().then(response => {
                   this.setState({ticket: data.data, questions: response.data.data, responses: response.data.data.map(q => ({question: q.id, answer: "", comments:""}))})
               })
           }

       })
   }

   sendQualify(event) {
       event.preventDefault()
       sendAnswers(this.state.id, this.state.responses).then(({data}) => {
           this.setState({openDialog: true, succes: data.success, message: data.message})
       })
   }
   handleChange(event, index, question){
        event.preventDefault()
       var { responses } = this.state
       if (question.questionType.id === 1) {
           responses[index].comments = event.target.value
       } else {
           responses[index].answer = event.target.value
       }
       this.setState({responses})

   }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Dialog open={this.state.openDialog} >
                    <DialogContent >
                        <Grid container justify="center" alignItems={"center"}>
                            <Grid item xs={12}>
                                <Lottie
                                    lopp={false}
                                    animationData={this.state.succes ? successData : errorData}
                                    style={{ height: "100px" }}
                                    play
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{textAlign:"center"}} >{this.state.message}</Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {if(this.state.succes) {window.location.href = "/"}else {this.setState({openDialog: false})}} }>
                            Continuar
                        </Button>

                    </DialogActions>
                </Dialog>
                <Header
                    color="primary"
                    routes={[]}
                    brand={APP_TITLE}
                    rightLinks={
                        <div></div>
                    }
                    fixed

                    {...this.props.rest}
                />
                <Grid container style={{paddingTop:"80px"}}>
                    <Grid item sm={12}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Información de la solicitud</h4>
                                <p className={classes.cardCategoryWhite}>

                                </p>
                            </CardHeader>
                            <CardBody>
                                <Grid container={true} spacing={1} direction="row" >
                                    <Grid item={true} xl={6} md={6} xs={6}>
                                        <Typography style={{ display: 'inline-block' }} className={classes.boldText} >Nombre Completo: </Typography> <Typography style={{ display: 'inline-block' }} gutterBottom>{this.state.ticket.author ? (this.state.ticket.author.completeName) : ""}</Typography>
                                    </Grid>

                                    <Grid item={true} xl={6} md={6} xs={6}>
                                        <Typography style={{ display: 'inline-block' }} className={classes.boldText} >Correo electrónico: </Typography> <Typography style={{ display: 'inline-block' }} gutterBottom>{this.state.ticket.email ? (this.state.ticket.email) : (this.state.ticket.author ? this.state.ticket.author.email : "Sin correo electrónico") }</Typography>
                                    </Grid>

                                    <Grid item={true} xl={6} md={6} xs={6}>
                                        <Typography style={{ display: 'inline-block' }} className={classes.boldText} >Enviado: </Typography> <Typography style={{ display: 'inline-block' }} gutterBottom>{this.state.ticket.createDate}</Typography>
                                    </Grid>

                                    <Grid item={true} xl={6} md={6} xs={6} >
                                        <Typography style={{ display: 'inline-block' }} className={classes.boldText} >Campus: </Typography> <Typography style={{ display: 'inline-block' }} gutterBottom>{this.state.ticket.campus ? this.state.ticket.campus.name : "No disponible"}</Typography>
                                    </Grid>
                                    <Grid item={true} xl={12} md={12} xs={12} >
                                        <Typography component={'div'} variant="body2" gutterBottom >
                                            <div dangerouslySetInnerHTML={{ __html: this.state.ticket.content || "" }} />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardBody>
                        </Card>
                    </Grid>
                    <Grid item sm={12}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Evaluación</h4>
                                <p className={classes.cardCategoryWhite}>

                                </p>
                            </CardHeader>
                            <CardBody>
                                <form onSubmit={this.sendQualify}>
                                <List>
                                    {
                                        this.state.questions.map((q,index) => (
                                            <ListItem key={q.index}>
                                                <ListItemText primary={q.title}  secondary={
                                                    <div >
                                                        {
                                                            q.questionType.id === 1 &&
                                                                <TextField

                                                                    value={this.state.responses[index].comments}
                                                                    onChange={(event) => this.handleChange(event, index, q)}
                                                                    fullWidth
                                                                />
                                                        }
                                                        {
                                                            q.questionType.id === 2 &&
                                                            <FormControl required component="fieldset" >
                                                                <RadioGroup required value={this.state.responses[index].answare}  onChange={(event) => this.handleChange(event, index, q)}>
                                                                    <FormControlLabel value="Sí" control={<Radio required />} label="Sí" />
                                                                    <FormControlLabel value="Parcialmente" control={<Radio required />} label="Parcialmente" />
                                                                    <FormControlLabel value="No" control={<Radio required />} label="No" />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        }
                                                    </div>
                                                }/>
                                            </ListItem>
                                        ))
                                    }

                                </List>
                                <div align={"right"}>
                                    <Button type={"submit"} variant="contained" color="secondary">Evaluar solicitud</Button>
                                </div>
                                </form>
                            </CardBody>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

QualifyTicket.propTypes = {};

export default withStyles(useStyles) (QualifyTicket);
