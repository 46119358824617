import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Grid, Typography, TextField, Button, withStyles, ListItem, List} from "@material-ui/core";
import CustomInput from "../../../components/CustomInput/CustomInput";
import {APP_TITLE} from "../../../assets/constants";
import HeaderLinks from "../../../components/Header/HeaderLinks";
import Header from "../../../components/Header/Header";
import dashboardRoutes from "../../routes";
import {primaryColor, whiteColor} from "../../../assets/jss/material-dashboard-react";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import Card from "../../../components/Card/Card";
import Footer from "../../../components/Footer/Footer";
import {
    commentStudentTicketService,
    commentTicketService, getActiitysFiles,
    getTicketActivitys,
    getTicketDetailStudent, getTicketFiles
} from "../../../util/Contexts/TicketContext";
import queryString from 'query-string';
import Messenger from "../../../components/Messenger";
import {handleError} from "../../../util/Contexts/ErrorContext";
import Alert from "react-s-alert";
import Link from "@material-ui/core/Link";
import JSZip from "jszip";
import {saveAs} from "file-saver";
import {useInterval} from "../../../util/util";


const useStyles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: whiteColor,
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: whiteColor
        }
    },
    cardTitleWhite: {
        color: whiteColor,
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    root: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        '& > *': {
            margin: 1,
        },
    },
    listItem: {
        float: "left",
        color: "inherit",
        position: "relative",
        display: "block",
        width: "auto",
        padding: "0",
        paddingRight:"2px",
        margin: "0",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            "&:after": {
                width: "calc(100% - 30px)",
                content: '""',
                display: "block",
                height: "1px",
                marginLeft: "15px",
                backgroundColor: "#e5e5e5",
            },
        },
    },
});

var refreshActivity = true
class TicketDetailStudent extends Component {
    constructor(props) {
        super(props);
        let params = queryString.parse(this.props.location.search)
        this.state = {
            ticketNumber: params.number,
            identifier: params.identifier,
            ticket: {},
            activitys: []
        }
        this.handleChange = this.handleChange.bind(this)
        this.getActivity = this.getActivity.bind(this)
        this.getData = this.getData.bind(this)
        this.commentTicket = this.commentTicket.bind(this)
    }


    componentDidMount() {
        this.getData()
        this.intervalId = setInterval(this.timer.bind(this), 5000);
    }
    timer() {
        if (refreshActivity) {
            this.getActivity(this.state.ticket.id)
        }
    }


    getData(){
        let params = queryString.parse(this.props.location.search)
        getTicketDetailStudent({identifier: this.state.identifier || params.identifier , ticketNumber: this.state.number || params.number}).then(({data}) => {
            if(data.success) {
                this.setState({ticket: data.data})
                this.getActivity(data.data.id)
            }else {
                Alert.warning(data.message)
            }

        })
    }
    componentWillUnmount(){
        clearInterval(this.intervalId);
    }

    handleChange(event){
        event.preventDefault()
        this.setState({[event.target.name] : event.target.value})

    }

    getActivity(id) {
        getTicketActivitys(id).then(({data}) => {
            this.setState({activitys: data.data.filter(a => a.ticketActivityType.type.id === 3)})
        });
    }

    commentTicket(data, files) {
        refreshActivity = false
        commentStudentTicketService(data, files, this.state.ticket.id).then(respuesta => {
            Alert.success(respuesta.data.message);
            this.getData();
            refreshActivity = true
        })
    }

    dowloadFiles(){
        getTicketFiles(this.state.ticket.id).then(({data}) => {

            if(data.data.length == 1) {
                var a = document.createElement("a"); //Create <a>
                a.href = `data:${data.data[0].fileType};base64,${data.data[0].data}`; //Image Base64 Goes here
                a.download = data.data[0].fileName; //File name Here
                a.click(); //Downloaded file
            }else {
                var zip = new JSZip();
                data.data.forEach( f => {
                    zip.file(f.fileName, f.data, {base64: true});
                })

                zip.generateAsync({type:"blob"}).then(function(content) {
                    // see FileSaver.js
                    saveAs(content, "Archivos.zip");
                });
            }
        })

    }





    render() {
        const { classes } = this.props;
        return (
            <div>
                <Header
                    color="primary"
                    routes={[]}
                    brand={APP_TITLE}
                    rightLinks={
                        <form onSubmit={(event) => { event.preventDefault(); this.getData()}}>
                        <List>
                            <ListItem className={classes.listItem}>
                                <TextField
                                    variant={"outlined"}
                                    label="Matricula"
                                    id="text"
                                    size="small"
                                    name={"identifier"}
                                    fullWidth
                                    value={this.state.identifier}
                                    onChange={this.handleChange}
                                />
                            </ListItem>
                                   <ListItem  className={classes.listItem}>
                                       <TextField
                                           variant={"outlined"}
                                           label="Folio"
                                           id="text"
                                           size="small"
                                           name={"ticketNumber"}
                                           fullWidth
                                           value={this.state.ticketNumber}
                                           onChange={this.handleChange}
                                       />

                            </ListItem>
                            <ListItem  className={classes.listItem}>
                                    <Button type={"submit"}>Buscar</Button>
                            </ListItem>

                    </List>
                        </form>
                    }
                    fixed

                    {...this.props.rest}
                />
                <Grid container style={{paddingTop:"90px", paddingLeft: '20px', paddingRight: '20px'}}>
                    <Grid item sm={12}>
                <Card>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>Información de la solicitud</h4>
                        <p className={classes.cardCategoryWhite}>

                        </p>
                    </CardHeader>
                    <CardBody>
                        <Grid container={true} spacing={1} direction="row" >
                            <Grid item={true} xl={6} md={6} xs={6}>
                                <Typography style={{ display: 'inline-block' }} className={classes.boldText} >Nombre Completo: </Typography> <Typography style={{ display: 'inline-block' }} gutterBottom>{this.state.ticket.author ? (this.state.ticket.author.completeName) : ""}</Typography>
                            </Grid>

                            <Grid item={true} xl={6} md={6} xs={6}>
                                <Typography style={{ display: 'inline-block' }} className={classes.boldText} >Correo electrónico: </Typography> <Typography gutterBottom>{this.state.ticket.email ? (this.state.ticket.email) : (this.state.ticket.author ? this.state.ticket.author.email : "Sin correo electrónico") }</Typography>
                            </Grid>

                            <Grid item={true} xl={6} md={6} xs={6}>
                                <Typography style={{ display: 'inline-block' }} className={classes.boldText} >Enviado: </Typography> <Typography style={{ display: 'inline-block' }} gutterBottom>{this.state.ticket.createDate}</Typography>
                            </Grid>

                            <Grid item={true} xl={6} md={6} xs={6} >
                                <Typography style={{ display: 'inline-block' }} className={classes.boldText} >Campus: </Typography> <Typography style={{ display: 'inline-block' }} gutterBottom>{this.state.ticket.campus ? this.state.ticket.campus.name : "No disponible"}</Typography>
                            </Grid>
                            <Grid item={true} xl={12} md={12} xs={12} >
                            <Typography component={'div'} variant="body2" gutterBottom >
                                <div dangerouslySetInnerHTML={{ __html: this.state.ticket.content || "" }} />
                                {this.state.ticket.hasFiles &&
                                <Link onClick={() => this.dowloadFiles()}>
                                    Descargar adjuntos
                                </Link>
                                }
                            </Typography>
                            </Grid>
                        </Grid>
                    </CardBody>
                </Card>
                    </Grid>

                    <Grid item sm={12}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Mensajes</h4>
                                <p className={classes.cardCategoryWhite}>

                                </p>
                            </CardHeader>
                            <CardBody>
                                <Messenger mesagges={this.state.activitys} sendAction={this.commentTicket} idAdmin={this.state.ticket.author ? this.state.ticket.author.id : 0} studentView={true}/>

                            </CardBody>
                        </Card>
                    </Grid>
                </Grid>
                <Footer />
            </div>

        );
    }
}

TicketDetailStudent.propTypes = {

};
export default withStyles(useStyles) (TicketDetailStudent);