import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import QueueAnim from "rc-queue-anim";
import DataGrid, {
    Column, Export, FilterRow,
    GroupPanel, HeaderFilter,
    Pager, Paging, Lookup,
    SearchPanel, Sorting, Summary, TotalItem, Scrolling, StateStoring
} from "devextreme-react/data-grid";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import {
  Button, Dialog,
  DialogActions,
  DialogContent, FormControl,
  DialogTitle, InputLabel, Select
} from "@material-ui/core";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { handleError } from "../../../util/Contexts/ErrorContext";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Alert from "react-s-alert";
import { hasRole } from "../../../util/Contexts/UserContext";
import {API_BASE_URL, API_AUTENTICATION_HEADERS } from "../../../assets/constants";
import axios from 'axios';
import { makeStyles } from "@material-ui/core/styles";
import {primaryColor, secondaryColor} from "../../../assets/jss/material-dashboard-react";
import {TraceSpinner} from "react-spinners-kit";
import {getAllCategoryByDepartment} from "../../../util/Contexts/CategoryContext";
import { getAllPrioritys } from "../../../util/Contexts/PriorityContext";
import esMessages from 'devextreme/localization/messages/es.json';
import { locale, loadMessages } from 'devextreme/localization';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import { getUser } from "../../../util/Contexts/UserContext";
import {
    assignedTicket,
    getAllDepartmensByUser,
    getAllTickets, getAllTicketsFilterBy,
    getOwnersList,
    reassignDepartment, reassignPriority, reassignResponsableFromList
} from "../../../util/Contexts/TicketContext";
import {getAllDepartmets, getAllDepartmentsEnabled} from "../../../util/Contexts/DepartmentContext";
import MenuItem from "@material-ui/core/MenuItem";

const styles = {
  cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
          color:  primaryColor[0],
          margin: "0",
          fontSize: "14px",
          marginTop: "0",
          marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
          color:  primaryColor[0]
      }
  },
  cardTitleWhite: {
      color:  primaryColor[0],
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
          color: "#777",
          fontSize: "65%",
          fontWeight: "400",
          lineHeight: "2"
      }
  },
  formControl: {
      margin: 1,
      minWidth: 140,
      maxWidth: "100%",
  },
  chips: {
      display: 'flex',
      flexWrap: 'wrap',
  },
  chip: {
      margin: 2,
  },
  margin: {
      margin: 1,
  }
};

const useStyles = makeStyles(styles);

export default function TicketList() {
  const classes = useStyles();
  const [detail, setDetail] = React.useState(false);
  const [id, setId] = React.useState(0);
  const [data, setData] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [state, setState] = React.useState({
      priorityAsigned: 0,
      reportId: 0,
      department: 0,
      ownerAsigned: 0,
      newCheckBox: false,
      openTicketBox: false,
      closeCheckBox: false,
      serviceSheetCheckbox: false,
      allCheckBox: true,
      displayRole: true,
      isAsesor: false,
      statusAsigned: (hasRole("OPERATOR")) ? 3 : 1,
  });
  const [status] = React.useState(hasRole("SPECIALIST") ?
      [{id: 0, name: 'Todos'}, {id: 1, name: 'Activos'}, {id: 2, name: 'Cerrados'}] :
      [{id: 0, name: 'Todos'}, {id: 1, name: 'Activos'}, {id: 2, name: 'Cerrados'}, {id: 3, name: 'Sin asignar'}]);
  const [categorys, setCategorys] = React.useState([]);
  const [openOwners, setOpenOwners] = React.useState(false);
  const [departments, setDepartments] = React.useState([]);
  const [owners, setOwners] = React.useState([]);
  const [openReassingned, setOpenReassingned] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [prioritys, setPrioritys] = React.useState([]);

  loadMessages(esMessages);
  locale("es");
  
  function getAll() {
      // statusAsigned: (hasRole("OPERATOR")) ? 3 : 1,
      getAllTicketsFilterBy(state.statusAsigned).then(respuesta => {
          setData(respuesta.data.data);
      });
  }

  function openDetail(row) {
    setId(row.id);
    setDetail(true);
  }

  const getAllCategory= async (id) => {
    getAllCategoryByDepartment(id).then(respuesta => {
        setCategorys(respuesta.data.data);
    })
  };

  const getAllPriority = async => {
      getAllPrioritys().then(respuesta => {
          setPrioritys(respuesta.data.data);
      })
  }

  const getAllDepartments = async () => {
      getAllDepartmentsEnabled().then(respuesta => {
        setDepartments(respuesta.data.data);
    })
  };

  const getOwners = (id, campus, type) => {
    getOwnersList(id, campus).then(respuesta => {
        setOwners(respuesta.data.data);
        type === 1 ? setOpenOwners(true) : setOpenReassingned(true);
    })
  };

  const handleChange = (event) => {
      event.preventDefault();
    setState({
        ...state,
        [event.target.name] : event.target.value
    });
  };

  function handleClickOpenReasingnedOwners(rowData) {
    var asignarAsesor = 1;
    if(rowData.row.data.owner != null){
      asignarAsesor = 2;
    }
    if(rowData.row.data.priority != null){
      setState({
        ...state,
        priorityAsigned: rowData.row.data.priority.id
      })
    }
    setState({
        ...state,
        reportId: rowData.row.data.id
    });
    getAllCategory(rowData.row.data.department.id);
    getOwners(rowData.row.data.department.id, rowData.row.data.campus.id, asignarAsesor);
  }

  function handleClickOpen(rowData) {
    setState({
        ...state,
        reportId: rowData.row.data.id
    });

    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleCloseOwners() {
    setOpenOwners(false);
  }

  function handleClickCloseReasingnedOwners() {
    setOpenReassingned(false);
  }

  function reassignedDepartment(event) {
      event.preventDefault()

      reassignDepartment(state).then(respuesta => {
        getAll();
        setOpen(false);
    } )
  }

  function reassignedReport(event) {
      event.preventDefault()

    reassignResponsableFromList(state).then(respuesta => {
        getAll();
        setOpenReassingned(false);
    } )
  }

  function assignedReport(event) {
      event.preventDefault()

      assignedTicket(state).then(respuesta => {
        getAll();
        setState({
            ...state,
            officeAsigned: 0,
            priorityAsigned: 0,
            categoryAsigned: 0,
            ownerAsigned: 0,
        });
        setOpenOwners(false);
    } )
  }

  const renderCell = (cellInfo) => {
    return(
      <div align={"center"} style={{display: 'flex'}}>
                
      {cellInfo.data.priority && 
      <FiberManualRecordIcon style={{width: 20, height: 20, color:
           cellInfo.data.priority.color}}/>
      }
      
      {cellInfo.data.unreadAdmin ? <VisibilityIcon color="disabled" />
      : <VisibilityOffIcon color="disabled"/>}
      
      {cellInfo.data.closed ?  (<LockIcon color="disabled"/>)
      :(<LockOpenIcon color="disabled"/>)}

          {/*{cellInfo.data.isInternalTicket && <LocalActivityIcon color="disabled"/>}*/}

  </div>
      );
  }

  function getFileTitle(){
    if(state.displayRole){
      return "Solicitudes"
    } else {
      return "Solicitudes_" + getUser().name
    }
  }

  function customizeExcelCell(options) {
    var gridCell = options.gridCell;
    if(!gridCell) {
      return;
    }

    if(gridCell.rowType === 'data') {
      if(gridCell.column.dataField === 'priority.color') {
        options.backgroundColor = gridCell.data.priority.color;
        options.value = ""
      }
      // options.backgroundColor = gridCell.data.priority.color
    }

  }

  useEffect(value => {

      if(window.location.search === '?filter=closed'){
          setState({
              ...state,
              statusAsigned: 2
          })
      } else if(window.location.search === '?filter=open'){
          setState({
              ...state,
              statusAsigned: 1
          })
      } else if(window.location.search === '?filter=all'){
          setState({
              ...state,
              statusAsigned: 0
          })
      } else {
          setState({
              ...state,
              statusAsigned: (hasRole("OPERATOR")) ? 3 : 1
          })
      }

      getAllDepartments();
      getAllPriority();

  }, []);

    useEffect(value => {
        getAll();

    }, [state.statusAsigned]);

  if (detail) {
    return (
      <Redirect push to={{
          pathname: "/admin/request/" + id,
          state: {ids: data.map(ticket => ticket.id)}
      }} />
    );
  }


  return (
    <GridContainer>
      <Dialog open={openOwners} onClose={handleCloseOwners}>
            <DialogTitle id="form-dialog-title">Asignar responsable</DialogTitle>
            <DialogContent>
              <form className={classes.root} autoComplete="off" onSubmit={assignedReport}>
                <FormControl fullWidth required>
                  <InputLabel htmlFor="ownerAsigned">Responsable</InputLabel>
                  <Select
                    required
                    native
                    value={state.ownerAsigned}
                    onChange={handleChange}
                    inputProps={{
                      name: 'ownerAsigned',
                      id: 'ownerAsigned',
                    }}
                  >
                    <option value="" />
                      {owners.map(function(value) { return (<option key={value.id} value={value.id} > {value.completeName}</option> );})}
                  </Select>
                </FormControl>
                    <br/> <br/>
                <FormControl fullWidth required>
                  <InputLabel htmlFor="priorityAsigned">Prioridad</InputLabel>
                    <Select
                      value={state.priorityAsigned}
                      onChange={handleChange}
                      inputProps={{
                        name: 'priorityAsigned',
                        id: 'priorityAsigned',
                      }}
                    >
                      {/*<option value="" />*/}
                      {/*  {prioritys.map(function(value) { return (<option key={value.id} value={value.id} > {value.name}</option> );})}*/}
                        <MenuItem value={""} />
                        {prioritys.map(function (value) {
                            return (
                                <MenuItem key={value.id} value={value.id}>
                                    <FiberManualRecordIcon style={{ color: value.color }}/> {value.name}
                                </MenuItem>); }
                        )}
                    </Select>
                  </FormControl>
                                    
                  <DialogActions>
                        <Button onClick={handleCloseOwners} color="primary">
                            Cancelar
                        </Button>
                                    
                        <Button type="submit" color="primary">
                            Aceptar
                        </Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
      <Dialog open={openReassingned} onClose={handleCloseOwners}>
      <form onSubmit={reassignedReport} className={classes.root} autoComplete="off">
        <DialogTitle id="form-dialog-title">Reasignar responsable</DialogTitle>
          <DialogContent>
              <FormControl fullWidth required>
                <InputLabel htmlFor="ownerAsigned">Responsable</InputLabel>
                <Select
                  required
                  native
                  value={state.ownerAsigned}
                  onChange={handleChange}
                  inputProps={{
                    name: 'ownerAsigned',
                    id: 'ownerAsigned',
                  }}
                >
                  <option value="" />
                  {owners.map(function(value) { return (<option key={value.id} value={value.id} > {value.completeName}</option> );})}
                </Select>
              </FormControl>
            
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickCloseReasingnedOwners} color="primary">
              Cancelar
            </Button>
            <Button type={"submit"} color="primary">
              Aceptar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog open={open} onClose={handleClose}>
      <form onSubmit={reassignedDepartment} className={classes.root} autoComplete="off">
        <DialogTitle id="form-dialog-title">Reasignar Departamento</DialogTitle>
          <DialogContent>
              <FormControl fullWidth required>
                <InputLabel htmlFor="department">Departamento</InputLabel>
                <Select
                  required
                  native
                  value={state.department}
                  onChange={handleChange}
                  inputProps={{
                    name: 'department',
                    id: 'department',
                  }}
                >
                  <option value="" />
                  {departments.map(function(value) { return (<option key={value.id} value={value.id} > {value.name}</option> );})}                </Select>
              </FormControl>
            
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button type={"submit"} color="primary">
              Aceptar
            </Button>
          </DialogActions>
        </form>
        </Dialog>

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="secondary" spacing={10}>
            <GridContainer>
              <GridItem xl={8} md={8} xs={8}>
                <h4 className={classes.cardTitleWhite}>Solicitudes</h4>
                <p className={classes.cardCategoryWhite}></p>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <QueueAnim>
              <div>
                  <GridContainer>
                      <GridItem xs={12} sm={6} md={2}>
                          <FormControl fullWidth>
                              <InputLabel htmlFor="statusAsigned">Estatus</InputLabel>
                              <Select
                                  style={{textAlign: 'initial'}}
                                  value={state.statusAsigned}
                                  onChange={handleChange}
                                  InputLabelProps={{
                                      shrink: true,
                                  }}
                                  inputProps={{
                                      name: 'statusAsigned',
                                      id: 'statusAsigned',
                                  }}
                              >
                                  <MenuItem value={""}/>
                                  {status.map(function (value) {
                                          return (
                                              <MenuItem key={value.id} value={value.id}>
                                                 {value.name}
                                              </MenuItem>);
                                      }
                                  )}
                              </Select>
                          </FormControl>
                      </GridItem>
                  </GridContainer>
                <DataGrid
                  onRowClick={(e) => {e.rowType === "data" &&  openDetail(e.data)}}
                  dataSource={data}
                  keyExpr="id"
                  showBorders={true}
                  columnAutoWidth={true}
                  allowColumnResizing={true}
                  allowColumnReordering={true}
                  noDataText={"No se encontraron solicitudes"}
                  >
                    <StateStoring
                        enabled={true}
                    />
                    <Export enabled={true} fileName={getFileTitle()} texts={{exportAll: "Exportar todos los datos"}}
                      customizeExcelCell={customizeExcelCell} />
                    <FilterRow visible={true} applyFilter={"auto"} />
                    <GroupPanel visible={true} emptyPanelText={"Arrastre una columna hacia aquí para agrupar"} />
                    <HeaderFilter visible={true} />
                    <SearchPanel visible={true}
                      width={240}
                      placeholder="Buscar..." />

                    <Paging defaultPageSize={10} />

                    <Pager
                      showPageSizeSelector={true}
                      allowedPageSizes={[5, 10, 20]}
                      showInfo={true} />
                    
                    <Sorting mode="multiple" />
                    <Column
                      caption="Indicadores"
                      allowExporting={false}
                      cellRender={renderCell}
                    />
                    
                    <Column dataField="ticketNumber"
                      caption="Folio"/>

                    <Column dataField="campus.name"
                            caption="Campus"/>

                    <Column dataField="department.name"
                    caption="Departamento"/>

                    <Column dataField="category.name"
                            caption="Categoría"/>

                    <Column dataField="author.student.identifier"
                            caption="Matrícula"/>

                    <Column dataField="author.completeName"
                    caption="Alumno"/>

                    <Column dataField="owner.completeName"
                    visible={!state.isAsesor}
                    caption="Atendido por"/>

                    <Column dataField="priority.name"
                    caption="Prioridad"/>

                    <Column dataField="ticketStatus.name"
                    caption="Estatus"/>

                    {/*<Column dataField="internalText"*/}
                    {/*        width={100}*/}
                    {/*        caption="Interna"/>*/}

                    <Column dataField="createDate"
                      dataType="date"
                      caption="Fecha"
                      allowHeaderFiltering = {false}
                      />  

                    <Column type="buttons"
                      visible={state.displayRole}
                      caption="Acciones"
                      buttons={[{
                        hint: 'Reasignar solicitud',
                        icon: 'detailslayout',
                        onClick: handleClickOpenReasingnedOwners
                      }, {
                        hint: 'Cambiar departamento',
                        icon: 'paste',
                        onClick: handleClickOpen
                        }, {
                          hint: 'Borrar solicitud',
                          icon: 'trash',
                          visible: false,
                          onClick: handleClickOpen
                      }]}/>

                    <Summary recalculateWhileEditing={true}>
                        <TotalItem

                            displayFormat={'Total: {0} Horas'}
                            column="timeWork"
                            summaryType="sum"
                            valueFormat={'decimal'}
                        precision={2} />
                    </Summary>
                </DataGrid>
              </div>
            </QueueAnim>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>

  );
}