import React, {useEffect} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
// @material-ui/icons

// core components
import CustomInput from "../../../../components/CustomInput/CustomInput.js";
import Button from "../../../../components/CustomButtons/Button.js";

import styles from "../../../../assets/jss/material-kit-react/views/landingPageSections/workStyle.js";
import {FormControl, Grid, InputLabel, Typography} from "@material-ui/core";
import "../../ticket/style.css"
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import TextField from "@material-ui/core/TextField";
import { TOOLBAR_OPTIONS } from "../../../../assets/constants";
import { createTicketForm } from "../../../../util/Contexts/TicketContext";
import Alert from "react-s-alert";
import { EditorState } from "draft-js";
import Select from "@material-ui/core/Select";
import {getAllDepartmentsEnabled} from "../../../../util/Contexts/DepartmentContext";
import {getUser} from "../../../../util/Contexts/UserContext";
import GridItem from "../../../../components/Grid/GridItem";
import {getAllCategoryByDepartment} from "../../../../util/Contexts/CategoryContext";
const useStyles = makeStyles(styles);

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType);


export default function WorkSection() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    content: EditorState.createEmpty(),
    departmentSelected: 0
  });
  const [files, setFiles] = React.useState([]);
  const [categorys, setCategorys] = React.useState([]);

  const onContentStateChange = (contentState) => {
    setState({
      ...state,
      content: contentState,
    });
  };

  const handlePhoneChange = () => event => {
    const onlyNums = event.target.value.replace(/[^0-9]/g, '');
    if (onlyNums.length < 10) {
      setState({ ...state, phone: onlyNums });
    } else if (onlyNums.length === 10) {
      const number = onlyNums.replace(
        /(\d{3})(\d{3})(\d{4})/,
        '($1) $2-$3'
      );
      setState({
        ...state,
        phone: number
      });
    }
  }

  const handleChange = event => {
    event.stopPropagation();
    setState({
      ...state,
      [event.target.name]: event.target.value
    });
  };

  const getAllCategory= async (id) => {
    getAllCategoryByDepartment(id).then(respuesta => {
      setCategorys(respuesta.data.data);
    }).catch(error => {
      setCategorys([]);
    });
  };

  const onSubmit = (event) => {
    event.preventDefault()

    createTicketForm(state, files).then(respuesta => {
      if (respuesta.data.success) {
        Alert.success(respuesta.data.message);
        setState({
          ...state,
          identifier: "",
          email: "",
          phone: "",
          content: EditorState.createEmpty(),
          name: "",
          lastName: "",
          category: 0,
        });
        setFiles([]);
      } else {
        Alert.warning(respuesta.data.message || "Ha ocurrido un error al crear la solicitud, intente de nuevo");
      }
    });

  };

  useEffect(value => {
    getAllCategory(4);
  }, []);

  return (
    <div className={classes.section} id="requestForm">
      <Grid container justify="center">
        <Grid item cs={12} sm={12} md={12}>
          <Typography variant="h3" className={classes.title}>Cuéntanos ¿Cuál es tu problema?</Typography>
          <Typography variant="h6" className={classes.description}>
            Completa el siguiente formulario y posteriormente recibirás una notificación en tu correo electrónico con
            actualización y seguimiento por el equipo de IT.
          </Typography>
          <Typography className={classes.descriptionItalic}>
            *Recuerda adjuntar imágenes o screenshots que faciliten la comprensión de tus problemas o duda.
          </Typography>
          <form onSubmit={onSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4}>
                <TextField id={"identifier"} placeholder={"m000000"} helperText={"Anteponer la inicial del campus"} name={"identifier"} type={"text"} label={"Matrícula"} required fullWidth value={state.identifier} onChange={handleChange}
                  inputProps={{
                    pattern: "^[a-zA-Z]{1}[0-9]{6}$"
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <TextField id={"name"} name={"name"} type={"text"} label={"Nombre(s)"} required fullWidth value={state.name} onChange={handleChange} />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <TextField id={"lastName"} name={"lastName"} type={"text"} label={"Apellido(s)"} required fullWidth value={state.lastName} onChange={handleChange} />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <TextField id={"email"} name={"email"} type={"email"} label={"Correo electrónico"} required fullWidth value={state.email} onChange={handleChange} />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  id="phone"
                  name="phone"
                  value={state.phone}
                  onChange={handlePhoneChange()}
                  label="Teléfono fijo/celular"
                  type="text"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <FormControl fullWidth required>
                  <InputLabel htmlFor="typeReport">Categoría</InputLabel>
                  <Select
                      fullWidth
                      value={state.category}
                      onChange={handleChange}
                      required={true}
                      inputProps={{
                        required: true,
                        name: 'category',
                        id: 'typeReport'
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}>
                    <option value="" />
                    {categorys.map(function (value) { return (<option key={value.id} value={value.id} > {value.name}</option>); })}
                  </Select>
                </FormControl >
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Editor
                  className={classes.textArea}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  placeholder="Escribe la descripción de tu problema aquí"
                  editorState={state.content}
                  onEditorStateChange={onContentStateChange}
                  toolbar={TOOLBAR_OPTIONS}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FilePond
                  id="contained-button-file"
                  files={files}
                  allowMultiple={true}
                  onupdatefiles={(files) => setFiles(files.map(fileItem => fileItem.file))}
                  labelIdle='Arrastre y suelte sus archivos o <span class="filepond--label-action">adjunte desde su dispositivo</span>'
                />
              </Grid>
              {/*<Grid item xs={12} sm={12} md={9} />*/}
              <Grid item xs={12} sm={12} md={12}>
                <Grid container direction="row" spacing={1} alignItems={'center'} justify={'center'} style={{ width: "100%" }}>
                  <Grid item>
                    <Button color={"primary"} type={"submit"} style={{ width: '100' }} style={{ color: '#000000', backgroundColor: '#FFDD00', fontFamily: 'Avenir Next LT Pro Cn' }}> C r e a r  &nbsp;  s o l i c i t u d</Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}></Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </div>
  );
}
