import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import PostAddIcon from '../../../../assets/img/icons/icono-ayuda.jpg';
import SpeakerNotesIcon from '../../../../assets/img/icons/icono-seguimiento.jpg';
import ReportIcon from '../../../../assets/img/icons/icono-evaluacion.jpg';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';

// core components
import InfoArea from "../../../../components/InfoArea/InfoArea.js";

import styles from "../../../../assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h3" className={classes.title}>Continuamos mejoranto para ti</Typography>
          <Typography variant="h6" className={classes.description}>
          <br/> Estos son los pasos a seguir al presentar tus dudas o problemas con alguno de los servicios del catálogo digital de CETYS.
          </Typography>
        </Grid>
      </Grid>
      <div>
        <Grid container>
          <Grid item xs={12} sm={12} md={4}>
            <InfoArea
              title="Solicitar apoyo"
              description="Presenta una breve descripción de tu problema y si es posible, una captura de pantalla para su visualización."
              icon={PostAddIcon}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <InfoArea
              title="Seguimiento por IT"
              description="Nuestro equipo se encargará de atender la solicitud y tú podrás retroalimentarnos hasta que solucionemos tu problema."
              icon={SpeakerNotesIcon}
              iconColor="success"
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <InfoArea
              title="Evaluación"
              description="Una vez terminado el proceso, te pediremos que evalúes nuestra atención para  seguir mejorando."
              icon={ReportIcon}
              iconColor="warning"
              vertical
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
