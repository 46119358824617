import React, {useEffect} from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import CardBody from "../../../components/Card/CardBody.js";
import axios from 'axios';
import Alert from "react-s-alert";
import {API_BASE_URL, API_AUTENTICATION_HEADERS} from "../../../assets/constants";
import {primaryColor, secondaryColor} from "../../../assets/jss/material-dashboard-react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from "@material-ui/core";
import DataGrid, {
    Column, Export, FilterRow,
    GroupPanel, HeaderFilter,
    Pager, Paging, Editing,
    SearchPanel, Sorting
} from "devextreme-react/data-grid";
import QueueAnim from "rc-queue-anim";
import {TraceSpinner} from "react-spinners-kit";
import {handleError} from "../../../util/Contexts/ErrorContext";
import esMessages from 'devextreme/localization/messages/es.json';
import { locale, loadMessages } from 'devextreme/localization';
import {createOffice, getAllOffices, updateOffice} from "../../../util/Contexts/OfficeContext";
import {getAllRoles, updateRole} from "../../../util/Contexts/RolesContext";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color:  primaryColor[0],
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color:  primaryColor[0]
        }
    },
    cardTitleWhite: {
        color:  primaryColor[0],
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const useStyles = makeStyles(styles);


export default function RoleList() {

    const classes = useStyles();
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [state, setState] = React.useState({
        name: "",
        id: 0,
        edit: true
    });

    loadMessages(esMessages);
    locale("es");

    function handleClickOpen(row) {
        var item = null;
        console.log(row.row)
        if(row.row != null){
            for(let i = 0; i < data.length; i++){
                if(row.row.key.id === data[i].id){
                    item = data[i]
                }
            }
        }
        item ? setState({
            ...state,
            name: item.showName,
            id: item.id,
            edit: true
        }) :  setState({
            ...state,
            name: "",
            id: 0,
            edit: false
        });
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    const handleChange = event => {
        setState( {
            ...state,
            [event.target.name] : event.target.value
        });
    };

    const updateData = async (event) => {
        setLoading(true);

        let newData = {
            name: state.name
        }

        updateRole(state.id, newData).then(respuesta => {
            if(respuesta.status === false){
                Alert.warning(respuesta.data.message);
            } else {
                Alert.success(respuesta.data.message);
            }
            getAllData();
            handleClose();
            setLoading(false);
        })

    };

    const getAllData = () => {
        getAllRoles().then(respuesta => {
            setLoading(false);
            setData(respuesta.data.data);
        } )
    };

    useEffect(value => {
        getAllData();
    }, []);

    function onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {}
        });
    }

    const renderStatus = (cellInfo) => {
        if(cellInfo.data.enabled){
            return(
                <div align={"center"}>
                    Verdadero
                </div>
            );
        } else {
            return(
                <div align={"center"}>
                    Falso
                </div>
            );
        }
    }

    return (
        <div>
                    <GridContainer>
                        <Dialog open={open} onClose={handleClose}>
                            <DialogTitle id="form-dialog-title">{state.edit? "Editar Rol": "Agregar Rol"}</DialogTitle>
                            <form onSubmit={updateData}>
                                <DialogContent>
                                    <TextField
                                        required={true}
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        name="name"
                                        value={state.name}
                                        onChange={handleChange}
                                        label="Nombre"
                                        type="text"
                                        fullWidth
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} color="primary">
                                        Cancelar
                                    </Button>
                                    <Button type={"submit"} color="primary">
                                        Guardar
                                    </Button>
                                </DialogActions>
                            </form>
                        </Dialog>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="secondary">
                                    <h4 className={classes.cardTitleWhite}>Listado de Roles</h4>
                                    <p className={classes.cardCategoryWhite}>
                                    </p>
                                </CardHeader>
                                <CardBody>
                                    <QueueAnim>
                                        <DataGrid
                                            dataSource={data}
                                            showBorders={true}
                                            key="id"
                                            allowColumnReordering={true}
                                            allowColumnResizing={true}
                                            onToolbarPreparing={onToolbarPreparing}>

                                            <FilterRow visible={true} applyFilter={"auto"} />

                                            <HeaderFilter visible={true} />

                                            <SearchPanel visible={true}
                                                         width={240}
                                                         placeholder="Buscar..." />

                                            <Paging defaultPageSize={10} />

                                            <Pager
                                                showPageSizeSelector={true}
                                                allowedPageSizes={[5, 10, 20]}
                                                showInfo={true} />

                                            <Sorting mode="multiple" />

                                            <Column
                                                dataField="showName"
                                                caption="Nombre"/>

                                            <Column type="buttons"
                                                    caption="Acciones"
                                                    width={80}
                                                    buttons={[{
                                                        hint: 'Editar',
                                                        icon: 'edit',
                                                        onClick: handleClickOpen
                                                    }]}
                                            />
                                        </DataGrid>
                                    </QueueAnim>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
        </div>
    );
}