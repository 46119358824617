import React, { useEffect } from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import CardBody from "../../../components/Card/CardBody.js";
import { primaryColor, secondaryColor } from "../../../assets/jss/material-dashboard-react";
import { TraceSpinner } from "react-spinners-kit";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import {
    Button, CircularProgress, FormControl,
    Icon,
    InputLabel,
    NativeSelect, TextField,Typography, FormControlLabel, Checkbox
} from "@material-ui/core";
import {
    API_AUTENTICATION_HEADERS,
    API_BASE_URL,
    API_BASIC_HEADERS, TOOLBAR_OPTIONS,
} from "../../../assets/constants";
import axios from "axios";
import { Editor } from 'react-draft-wysiwyg';
import Alert from "react-s-alert";
import { Redirect } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import {getUser, hasRole} from "../../../util/Contexts/UserContext";
import { handleError } from "../../../util/Contexts/ErrorContext";
import { getAllCategoryByDepartment } from "../../../util/Contexts/CategoryContext";
import {createTicket, getOwnersList, getPrioritys, getStudentInfo} from "../../../util/Contexts/TicketContext";
import { getAllOfficesEnabled } from "../../../util/Contexts/OfficeContext";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import "./style.css"
import InputAdornment from "@material-ui/core/InputAdornment";
import {getAllDepartmentsEnabled} from "../../../util/Contexts/DepartmentContext";
import Select from "@material-ui/core/Select";
import themes from "devextreme/ui/themes";
import {EditorState, ContentState} from "draft-js";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from '@material-ui/icons/Info';
import Fab from "@material-ui/core/Fab";
import MenuItem from "@material-ui/core/MenuItem";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType);
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: primaryColor[0],
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: primaryColor[0]
        }
    },
    cardTitleWhite: {
        color: primaryColor[0],
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    button: {
        margin: "dense",
    },
    rightIcon: {
        marginLeft: "10px",
    },
    dot: {
        height: '25px',
        width: '25px',
        backgroundColor: '#bbb',
        borderRadius: '50%',
        display: 'inline-block'
    }
};


const useStyles = makeStyles(styles);


export default function CreateTicket() {

    const classes = useStyles();
    const [campus, setCampus] = React.useState([]);
    const [departments, setDepartments] = React.useState([]);
    const [newUser, setNewUser] = React.useState(false);
    const [owners, setOwners] = React.useState([]);
    const [prioritys, setPrioritys] = React.useState([]);
    const [categorys, setCategorys] = React.useState([]);
    const [files, setFiles] = React.useState([]);
    const [userSelect, setUserSelect] = React.useState({});
    const [users, setUsers] = React.useState([]);
    const [loadingAutocomplete, setLoadingAutocomplete] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [createOther, setCreateOther] = React.useState(true);

    const [state, setState] = React.useState({
        name: "",
        lastname: "",
        phone: "",
        email: "",
        content: EditorState.createEmpty(),
        identifier: "",
        alternateEmail: "",
        campusInitial: "",
        completeIdentifier: "",
        ownerSelected: 0,
        department: 0,
        prioritySelected: 0,
        departmentSelected: 0,
        categoryAsigned: 0,
        campusSelect: 0,
        level: "",
        program: "",
        type: "",
        studyName: "",
        periodName: "",
        departmentName: "",
        student: null,
        redirect: false
    });

    function studentInfoSearch(event) {
        event.preventDefault()
        getStudentInfo(state.campusInitial + state.identifier).then(({data}) => {
            if(data.success) {
                setState({...state, student: data.data, name: data.data.Nombres,lastname: data.data.Apellidos, email: data.data.Email_Institucional || "", alternateEmail: data.data.Email, phone: data.data.Celular, level: data.data.Programa.Nivel,program: data.data.Programa.ProgramaNombre,type: data.data.TipoPersona,  studyName: data.data.Programa.PlanEstudioNombre, periodName: data.data.Programa.PeriodoMeses, departmentName: data.data.Programa.DepartamentoNombre })
            }
        })
    }

    const onContentStateChange = (contentState) => {
        setState({
            ...state,
            content: contentState,
        });
    };

    const getAllDepartmets = () => {

        getAllDepartmentsEnabled().then(respuesta => {
            setDepartments(respuesta.data.data);
        })
    };

    const getAllPrioritys = async () => {
        getPrioritys().then(respuesta => {
            setPrioritys(respuesta.data.data);
        })
    };

    const getAllCategory = async (id) => {
        getAllCategoryByDepartment(id).then(respuesta => {
            setCategorys(respuesta.data.data);
        })
    };

    const getAllCampus = () => {
        getAllOfficesEnabled().then(respuesta => {
            setCampus(respuesta.data.data);
        })
    };

    const getOwners = (id, campuseId) => {
        getOwnersList(id, campuseId).then(respuesta => {
            setOwners(respuesta.data.data);

        })
    };

    const handleDepartementChange = event => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        });
        getAllCategory(event.target.value);
        getOwners(event.target.value, state.campusSelect);
    };

    const handlePhoneChange = event => {
        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
        if (onlyNums.length < 10) {
            setState({...state, phone: onlyNums });
        } else if (onlyNums.length === 10) {
            const number = onlyNums.replace(
                /(\d{3})(\d{3})(\d{4})/,
                '($1) $2-$3'
            );
            setState({ ...state,
                phone: number });
        }
    }

    const handleChange = event => {
        event.stopPropagation();
        setState({
            ...state,
            [event.target.name]: event.target.value
        });
    };

    const handleChangeIdentifier = event => {
        const re = /^\d{1,6}$/;

        // if value is not blank, then test the regex
        if (event.target.value === '' || re.test(event.target.value)) {
            setState({...state, [event.target.name]: event.target.value, completeIdentifier: state.campusInitial + event.target.value})
        }
    }

    const handleChangeCampus = event => {
        event.stopPropagation();
        let id = event.target.value
        let campusName = campus.find((item) => item.id === Number(id)).initial
        setState({
            ...state,
            [event.target.name]: id,
            campusInitial: campusName
        });
        getOwners(state.departmentSelected, id)
    };

    function getData() {
        getAllDepartmets();
        getAllPrioritys();
        getAllCampus();
    }

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(!open);
    };

    useEffect(value => {
        getData();

        let user = getUser();
        let departmentId = user.admin && user.admin.departments.length !== 0 && user.admin.departments[0].id;
        let campuseId = user.admin && user.admin.campuses.length !== 0 && user.admin.campuses[0].id;
        let initial = user.admin && user.admin.campuses.length !== 0 && user.admin.campuses[0].name.charAt(0).toLowerCase()
        setState({
            ...state,
            ownerSelected: user.id,
            campusSelect: campuseId,
            campusInitial: initial,
            departmentSelected: departmentId ? departmentId : 0,
        })

        if (departmentId !== null) {
            getAllCategory(departmentId);
            getOwners(departmentId, campuseId);
        }
    }, []);

    const handleAutocompleteUserChange = event => {
        getAutocompleteUserSugestion(event.target.value);
    };


    function createReport(event) {
        event.preventDefault();
        createTicket(state, userSelect, newUser, files).then(respuesta => {
            if (respuesta.data.success) {
                Alert.success(respuesta.data.message);
                setState({
                    ...state,
                    redirect: !createOther
                });
            } else {

                Alert.warning(respuesta.data.message || "Error al conectarse al servidor");
            }
        });

    }

    const handleFormUserChange = (event, value) => {
        if (value != null) {
            setUserSelect(value);
            setUsers([]);
        } else {
            setUserSelect("");
            setUsers([]);
        }
    };

    if (state.redirect) {
        return <Redirect push to={"/admin/request"} push={true} />
    }

    function userForm() {
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                    <FormControl fullWidth required>
                        <InputLabel htmlFor="campusSelect">Campus</InputLabel>
                        <Select
                            fullWidth
                            value={state.campusSelect}
                            onChange={handleChangeCampus}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                name: 'campusSelect',
                                id: 'campusSelect'
                            }}>
                            <option value="" />
                            {campus.map(function (value) { return (<option key={value.id} value={value.id} > {value.name}</option>); })}
                        </Select>
                    </FormControl >
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                    <TextField
                        margin="dense"
                        id="identifier"
                        name="identifier"
                        value={state.identifier}
                        onChange={handleChangeIdentifier}
                        label="Matricula"
                        type="text"
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{state.campusInitial}</InputAdornment>,
                            endAdornment: <InputAdornment position="start" style={{marginRight: "0px", marginBottom:"5px"}} ><Button variant={"contained"} color={"secondary"}  size={"small"} onClick={ studentInfoSearch}>Buscar</Button> </InputAdornment>
                        }}
                        required
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                    <TextField
                        margin="dense"
                        id="lastname"
                        name="lastname"
                        value={state.lastname}
                        onChange={handleChange}
                        label="Apellidos"
                        type="text"
                        required
                        fullWidth
                        disabled
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                    <TextField
                        margin="dense"
                        id="name"
                        name="name"
                        value={state.name}
                        label="Nombre"
                        type="text"
                        fullWidth
                        disabled
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </GridItem>


                <GridItem xs={12} sm={12} md={3}>
                    <FormControl fullWidth required>
                        <TextField
                            margin="dense"
                            id="email"
                            name="email"
                            value={state.email}
                            onChange={handleChange}
                            label="Correo electrónico institucional"
                            type="email"
                            required
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                </GridItem>

                <GridItem xs={12} sm={12} md={3}>
                    <FormControl fullWidth required>
                        <TextField
                            margin="dense"
                            id="alternateEmail"
                            name="alternateEmail"
                            value={state.alternateEmail}
                            onChange={handleChange}
                            label="Correo electrónico alterno"
                            type="email"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                </GridItem>

                <GridItem xs={12} sm={12} md={3}>
                    <TextField
                        margin="dense"
                        id="phone"
                        name="phone"
                        value={state.phone}
                        onChange={handlePhoneChange}
                        label="Teléfono fijo/celular"
                        type="text"
                        fullWidth
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </GridItem >
                <GridItem xs={12} sm={12} md={3}>
                    <TextField
                        margin="dense"
                        id="type"
                        name="type"
                        value={state.type}
                        label="Tipo"
                        type="text"
                        fullWidth
                        disabled
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </GridItem >
                <GridItem xs={12} sm={12} md={4}>
                    <TextField
                        margin="dense"
                        id="level"
                        name="level"
                        value={state.level}
                        label="Nivel"
                        type="text"
                        fullWidth
                        disabled
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </GridItem >

                <GridItem xs={12} sm={12} md={4}>
                    <TextField
                        margin="dense"
                        id="program"
                        name="program"
                        value={state.program}
                        label="Programa"
                        type="text"
                        fullWidth
                        disabled
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </GridItem >
                {/*<GridItem xs={12} sm={12} md={3}>*/}
                {/*    <TextField*/}
                {/*        margin="dense"*/}
                {/*        id="departmentName"*/}
                {/*        name="departmentName"*/}
                {/*        value={state.departmentName}*/}
                {/*        label="Departamento"*/}
                {/*        type="text"*/}
                {/*        fullWidth*/}
                {/*        disabled*/}
                {/*        InputLabelProps={{*/}
                {/*            shrink: true,*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</GridItem >*/}
                <GridItem xs={12} sm={12} md={4}>
                    <TextField
                        margin="dense"
                        id="studyName"
                        name="studyName"
                        value={state.studyName}
                        label="Plan estudio"
                        type="text"
                        fullWidth
                        disabled
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </GridItem >
                <GridItem xs={12} sm={12} md={12}>
                    <FormControl fullWidth>
                        <FormControlLabel
                            control={<Checkbox checked={createOther} onChange={(e) => setCreateOther(e.target.checked)} color="primary" />}
                            label="Mantener información"
                            labelPlacement="end"
                        />

                    </FormControl >
                </GridItem>

                <GridItem xs={12} sm={12} md={1}>
                    <br/>
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                        <div>
                            <Tooltip placement="right"
                                PopperProps={{
                                    disablePortal: true,
                                }}
                                onClose={handleTooltipClose}
                                open={open}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title="Recomendación: Si usted está creando una solicitud recibida vía telefónica, presencial o cualquier otro medio distinto al sistema, recuerde informar al Alumno que las respuestas y seguimiento de la solicitud serán enviadas al correo electrónico registrado, además podrá ingresar  al a dirección: incluir URL y continuar la comunicación a través de su interfaz de seguimiento.
"
                            >
                                <InfoIcon color={"secondary"} onClick={handleTooltipOpen}>Click</InfoIcon>
                            </Tooltip>
                        </div>
                    </ClickAwayListener>

                </GridItem>
                {/*<GridItem xs={12} sm={12} md={12}>*/}
                {/*    <br />*/}
                {/*    <div align={"right"}>*/}
                {/*        <Button variant="contained" color="primary" className={classes.button} onClick={() => setNewUser(false)} >*/}
                {/*            REGRESAR A BÚSQUEDA DE ALUMNOS*/}
                {/*    </Button>*/}
                {/*    </div>*/}
                {/*</GridItem>*/}

            </GridContainer>
        );
    }

    function userSearch() {
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={9}>
                    <FormControl fullWidth required>
                        <Autocomplete
                            loading={loadingAutocomplete}
                            noOptionsText={"No se encontraron coincidencias"}
                            options={users}
                            loadingText={"Buscando..."}
                            onKeyUp={handleAutocompleteUserChange}
                            value={userSelect}
                            getOptionLabel={option => (option.email && option.email + " (" + option.completeName + ")")}
                            onChange={handleFormUserChange}
                            autoSelect
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    margin="normal"
                                    placeholder="Buscar alumno registrado"
                                    fullWidth
                                    required={true}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loadingAutocomplete ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                    <Button fullWidth={true} variant="contained" color="primary" className={classes.button} onClick={() => setNewUser(true)}>
                        Alta de alumno
                    </Button>
                </GridItem>
            </GridContainer>
        )
    }

    const getAutocompleteUserSugestion = (query) => {
        setLoadingAutocomplete(true);
        const url = API_BASE_URL + "/api/users?query=" + query;
        const headers = API_AUTENTICATION_HEADERS;
        axios.get(url, { headers }).then(respuesta => {
            if (respuesta.data.success) {
                setUsers(respuesta.data.data);
            }

            setLoadingAutocomplete(false);
        })
    };

    return (
        <div>
                <form onSubmit={createReport}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="primary">
                                    <div align="center">
                                        Información del alumno
                    </div>
                                </CardHeader>
                                <CardBody>
                                    {userForm()}
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="primary">
                                    <div align="center">
                                        Información de la solicitud
                      </div>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <GridContainer>

                                                <GridItem xs={12} sm={12} md={6}>
                                                    <FormControl fullWidth required>
                                                        <InputLabel htmlFor="typeReport">Departamento</InputLabel>
                                                        <Select
                                                            fullWidth
                                                            value={state.departmentSelected}
                                                            onChange={handleDepartementChange}
                                                            required={true}
                                                            inputProps={{
                                                                required: true,
                                                                name: 'departmentSelected',
                                                                id: 'typeReport'
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}>
                                                            <option value="" />
                                                            {departments.map(function (value) { return (<option key={value.id} value={value.id} > {value.name}</option>); })}
                                                        </Select>
                                                    </FormControl >
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={6}>
                                                    <FormControl fullWidth required>
                                                        <InputLabel htmlFor="asesorReport">Asignar como responsable a</InputLabel>
                                                        <Select
                                                            fullWidth
                                                            required={true}
                                                            value={state.ownerSelected}
                                                            onChange={handleChange}
                                                            inputProps={{
                                                                required: true,
                                                                name: 'ownerSelected',
                                                                id: 'asesorReport'
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}>
                                                            <option value="" />
                                                            {owners.map(function (value) { return (<option key={value.id} value={value.id} > {value.completeName} </option>); })}
                                                        </Select>
                                                    </FormControl >
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={6}>
                                                    <FormControl fullWidth required={true}>
                                                        <InputLabel htmlFor="priorityReport">Prioridad</InputLabel>
                                                        <Select
                                                            fullWidth
                                                            autoFocus={false}
                                                            required={true}
                                                            value={state.prioritySelected}
                                                            onChange={handleChange}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            inputProps={{
                                                                required: true,
                                                                name: 'prioritySelected',
                                                                id: 'priorityReport'
                                                            }}>

                                                            <option value="" />
                                                            {prioritys.map(function (value) {
                                                                return (
                                                                <MenuItem key={value.id} value={value.id}>
                                                                    <FiberManualRecordIcon style={{ color: value.color }}/> {value.name}
                                                                </MenuItem>); }
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={6}>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor="categoryReport">Categoría (opcional)</InputLabel>
                                                        <Select
                                                            fullWidth
                                                            value={state.categoryAsigned}
                                                            onChange={handleChange}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            inputProps={{
                                                                name: 'categoryAsigned',
                                                                id: 'categoryReport'
                                                            }}>
                                                            <option value="" />
                                                            {categorys.map(function (value) { return (<option key={value.id} value={value.id} > {value.name}</option>); })}
                                                        </Select>
                                                    </FormControl >
                                                </GridItem>

                                            </GridContainer>
                                            <br />

                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={9}>
                                            <Editor
                                                wrapperClassName="wrapper-class"
                                                editorClassName="editor-class"
                                                toolbarClassName="toolbar-class"
                                                placeholder="Escriba aquí la solicitud"
                                                // initialContentState={state.content}
                                                // onContentStateChange={onContentStateChange}
                                                toolbar={TOOLBAR_OPTIONS}
                                                editorState={state.content}
                                                onEditorStateChange={onContentStateChange}
                                            />
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={3} >
                                            <FilePond
                                                id="contained-button-file"
                                                files={files}
                                                allowMultiple={true}
                                                maxFiles={3}
                                                onupdatefiles={(files) => setFiles(files.map(fileItem => fileItem.file))}
                                                acceptedFileTypes={['image/*', 'application/vnd.ms-excel', 'application/msword', 'application/pdf']}
                                                labelIdle='<i class="material-icons" style="font-size: 50px">add_photo_alternate</i>'
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <div align="center" style={{padding:"10px"}}>
                                <Button type="submit" fullWidth={true} variant="contained" color="" className={classes.button} >
                                    Crear solicitud
                    <Icon className={classes.rightIcon}>send</Icon>
                                </Button>
                            </div>
                        </GridItem>
                    </GridContainer>
                </form>
        </div>
    );
}