import {API_AUTENTICATION_HEADERS, API_BASE_URL} from "../../assets/constants";
import axios from "axios";
import draftToHtml from "draftjs-to-html";
import {trackPromise} from "react-promise-tracker";
import {convertToRaw} from "draft-js";

const headers = API_AUTENTICATION_HEADERS;

function updateFrequentAnswers(id, newData){
    const url = API_BASE_URL + "/api/frequent-answers/" + id;

    let result;
    trackPromise(
        result = axios.put(url,Object.assign({}, {title: newData.title, content:  draftToHtml(convertToRaw(newData.content.getCurrentContent())) , enabled: newData.enabled, department: newData.department.id}),{ headers } )
    )
    return result;
}

function getAllFrequentAnswers() {
    const url = API_BASE_URL + "/api/frequent-answers/all";
    let result;
    trackPromise(
        result = axios.get(url, {headers} )
    )
    return result;
}
function getAllFrequentAnswersByDepartment(id) {
    const url = API_BASE_URL + "/api/frequent-answers/department/" + id;
    let result;
    trackPromise(
        result = axios.get(url, {headers} )
    )
    return result;
}

function addFrequentAnswers(state) {
    const url = API_BASE_URL + "/api/frequent-answers";
    let result;
    trackPromise(
        result = axios.post(url, Object.assign({}, {title: state.name, content:draftToHtml(convertToRaw(state.description.getCurrentContent())), enabled: true, department: state.officeAsigned})  ,{ headers } )
    )
    return result;
}

export {  getAllFrequentAnswers, updateFrequentAnswers, addFrequentAnswers, getAllFrequentAnswersByDepartment};