import React from 'react';
import Chart from 'react-apexcharts';

class PieChartPriorityReports extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                chart: {
                    toolbar: {
                        show: true,
                        tools: {
                            download: true,
                            selection: true,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: true,
                            reset: true
                        },
                        autoSelected: 'zoom'
                    },
                },
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'center',
                    fontSize: '14px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 'bold',
                },
                title: {
                    text: 'Reportes por Prioridad',
                    align: 'center',
                    style: {
                        fontSize: '20px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 'bold',
                    }
                }
            },
            series: [0, 0, 0]
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data || this.props.labels !== prevProps.labels || this.props.colors !== prevProps.colors) {
            this.setState({
                options: {
                    ...this.state.options,
                    labels: this.props.labels,
                    colors: this.props.colors,
                },
                series: this.props.data
            });
        }
    }

    render() {
        return (
            <div style={{
                backgroundColor: 'white',
                boxShadow: '0px 3px 15px rgba(0,0,0,0.2)',
                borderRadius: '10px',
                padding: '20px',
                margin: '20px',
                backgroundImage: 'linear-gradient(to bottom right, #fff, #eee)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Chart
                    options={this.state.options}
                    series={this.state.series}
                    type="pie"
                    width="500"
                />
            </div>
        )
    }
}

export default PieChartPriorityReports;
