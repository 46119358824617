import {API_AUTENTICATION_HEADERS, API_BASE_URL} from "../../assets/constants";
import axios from "axios";
import {trackPromise} from "react-promise-tracker";

const headers = API_AUTENTICATION_HEADERS;

function updateCategory(id, newData){
    console.log(newData)
    const url = API_BASE_URL + "/api/category/" + id;
    let result;
    trackPromise(
        result = axios.put(url,newData,{ headers})
    )
    return result;
}

function removeCategory(id){
    const url = API_BASE_URL + "/api/category/" + id;

    let result;
    trackPromise(
        result = axios.delete(url,{ headers})
    )
    return result;
}

function getAllCategory() {
    const url = API_BASE_URL + "/api/categorys/all";
    let result;
    trackPromise(
        result = axios.get(url, {headers})
    )
    return result;
}
function getAllCategoryByDepartment(id) {
    const url = API_BASE_URL + "/api/categorys/department/" + id;
    let result;
    trackPromise(
        result = axios.get(url, {headers})
    )
    return result;

}

function addCategory(state) {
    const url = API_BASE_URL + "/api/category";
    let result;
    trackPromise(
        result = axios.post(url, Object.assign({}, {name: state.name, description: state.description, enabled: true, departmentId: state.officeAsigned})  ,{ headers})
    )
    return result;
}

export {  getAllCategory, updateCategory, addCategory, getAllCategoryByDepartment, removeCategory};