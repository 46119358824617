import { title } from "../../../material-kit-react";
import {grayColor} from "../../../material-dashboard-react";

const workStyle = {
  section: {
    padding: "10px 0",
  },
  title: {
    marginBottom: "40px",
    marginTop: "10px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center",
    color: '#282828',
    fontFamily: 'Avenir Next LT Pro Bold'
  },
  description: {
    color: "#282828",
    marginBottom: "30px",
    textAlign: "center",
    fontFamily: 'Avenir Next LT Pro Regular'
  },
  descriptionItalic: {
    color: "#282828",
    marginBottom: "30px",
    textAlign: "center",
    fontFamily: 'Avenir Next LT PRO IT'
  },
  textCenter: {
    textAlign: "center",
  },
  textArea: {
    marginRight: "15px",
    marginLeft: "15px",
  },
};

export default workStyle;
