import {API_AUTENTICATION_HEADERS, API_BASE_URL} from "../../assets/constants";
import axios from "axios";
import {trackPromise} from "react-promise-tracker";

const headers = API_AUTENTICATION_HEADERS;

function getAllOffices() {
    const url = API_BASE_URL + "/api/campus";
    let result;
    trackPromise(
        result = axios.get(url, {headers} )
    )
    return result;
}

function getAllOfficesEnabled() {
    const url = API_BASE_URL + "/api/campus/enabled";
    let result;
    trackPromise(
        result = axios.get(url, {headers} )
    )
    return result;
}

function createOffice(data) {
    const url = API_BASE_URL + "/api/campus";
    let result;
    trackPromise(
        result = axios.post(url, Object.assign({}, {name: data.name}), {headers} )
    )
    return result;
}

function updateOffice(id, newData) {
    const url = API_BASE_URL + "/api/campus/" + id;
    const headers = API_AUTENTICATION_HEADERS;

    let result;
    trackPromise(
        result = axios.put(url, newData, {headers} )
    )
    return result;
}

export {  getAllOffices, getAllOfficesEnabled, createOffice, updateOffice};