import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    DialogContentText,
    TextField,
    Button
} from "@material-ui/core"
import React, {useEffect, useState} from "react"
import {changePasswordService, getUser} from "../../../../util/Contexts/UserContext";
import {USER_SESSION} from "../../../../assets/constants";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";


const ChangePasswordDialog = (props) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [values, setValues] = React.useState({
        showPassword: false,
        showConfirmPassword: false
    });

    const changePassword = event => {
        event.preventDefault()
        if (password.localeCompare(confirmPassword) !== 0) {
            Alert.warning("Las contraseñas no coinciden, favor de verificar")
            return
        }
        setPassword("")
        setConfirmPassword("")
        changePasswordService({password}, props.userId).then(({data}) => {
            localStorage.setItem(USER_SESSION, JSON.stringify(data.data));
            props.onChangedPassword();
        })
    };

    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };

    const handleClickShowPassword = () => {
        setValues({...values, showPassword: !values.showPassword});
    };

    const handleClickShowConfirmPassword = () => {
        setValues({...values, showConfirmPassword: !values.showConfirmPassword});
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Dialog open={props.open}>
            <DialogTitle>Cambiar contraseña</DialogTitle>
            <DialogContent>
                {props.text ? (
                    <DialogContentText>
                        {props.text}
                    </DialogContentText>
                ) : <div/>}
                <form onSubmit={changePassword}>
                    <TextField
                        fullWidth
                        required
                        id="password-input"
                        label="Contraseña"
                        type={values.showPassword ? 'text' : 'password'}
                        name="password"
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                        autoComplete="current-password"
                        margin="normal"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {values.showPassword ? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                            </InputAdornment>
                        }}
                    />
                    <TextField
                        fullWidth
                        required
                        id="confirm-password-input"
                        label="Confirmar"
                        type={values.showConfirmPassword ? 'text' : 'password'}
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => {
                            setConfirmPassword(e.target.value)
                        }}
                        autoComplete="current-password"
                        margin="normal"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {values.showConfirmPassword ? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                            </InputAdornment>
                        }}
                    />
                    <DialogActions>
                        <Button onClick={() => props.onChangePasswordDialogClosed()}>Cancelar</Button>
                        <Button type={"submit"}>Guardar</Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default ChangePasswordDialog;
