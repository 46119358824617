import {
    API_AUTENTICATION_HEADERS,
    API_BASE_URL,
    API_BASIC_HEADERS,
    API_MULTIPART_HEADERS
} from "../../assets/constants";
import axios from "axios";
import draftToHtml from 'draftjs-to-html';
import {  convertToRaw } from 'draft-js';
import {handleError} from "./ErrorContext";
import imageCompression from "browser-image-compression";
import {trackPromise} from "react-promise-tracker";
import {getUser} from "./UserContext";
import {format} from "date-fns";


const headers = API_AUTENTICATION_HEADERS;
const headersMultipart = API_MULTIPART_HEADERS;


function getTicketInfo(id) {
    const url = API_BASE_URL + "/api/ticketImage/"+ id;

    let result;
    trackPromise(
        result = axios.get(url, {headers} )
    )
    return result;
}
function getTicketsQualify() {
    const url = API_BASE_URL + "/api/qualified/tickets";

    let result;
    trackPromise(
        result = axios.get(url, {headers} )
    )
    return result;
}
function getTicketActivitys(id) {
    const url = API_BASE_URL + "/api/ticket/" + id + "/activity";
    let result;
    trackPromise(
        result = axios.get(url, {headers} ), "none"
    )
    return result;
}

function getValidTicketStudent(data) {
    const url = API_BASE_URL + "/api/tickets/search/exist";

    let result;
    trackPromise(
        result = axios.post(url, Object.assign({}, {identifier: data.identifier, ticketNumber: data.ticketNumber}), {headers} )
    )
    return result;
}


function getTicketStudentQualify(id) {
    const url = API_BASE_URL + `/api/ticket/${id}/complete-qualify`;

    let result;
    trackPromise(
        result = axios.get(url, {headers} )
    )
    return result;
}

function getTicketDetailStudent(data) {
    const url = API_BASE_URL + "/api/tickets/search/student";

    let result;
    trackPromise(
        result = axios.post(url, Object.assign({}, {identifier: data.identifier, ticketNumber: data.ticketNumber}), {headers} )
    )
    return result;
}

async function createTicket(state, userSelect, newUser, files) {
    var bodyFormData = new FormData();
    var json = JSON.stringify({
        content: draftToHtml(convertToRaw(state.content.getCurrentContent())), departamentId: state.departmentSelected,
        name: state.name, lastName: state.lastname, email: state.email, alternateEmail: state.alternateEmail,
        phoneNumber: state.phone, campusId: state.campusSelect, identifier: state.completeIdentifier,
        priority: state.prioritySelected, category: state.categoryAsigned,  userAssigned: state.ownerSelected});
    var blob = new Blob([json] , { type: 'application/json' });
    bodyFormData.append("request", blob);

    if (files !== null) {
        for (const element of Array.from(files)) {
            let finalFile = await handleImageUpload(element);
            bodyFormData.append("file", finalFile, element.name);
        }
    }

    const url = API_BASE_URL + "/api/ticket/customer";
    const headers = API_BASIC_HEADERS;
    let result;
    trackPromise(
        result = axios.post(url, bodyFormData, {headers})
    )
    return result;
}

async function createTicketForm(state, files) {
    var bodyFormData = new FormData();
    var json = JSON.stringify({
        content: draftToHtml(convertToRaw(state.content.getCurrentContent())),
        name: state.name, lastName: state.lastName, alternateEmail: state.email, phoneNumber: state.phone, identifier: state.identifier, category: state.category});

    var blob = new Blob([json] , { type: 'application/json' });
    bodyFormData.append("request", blob);

    if (files !== null) {
        for (const element of Array.from(files)) {
            let finalFile = await handleImageUpload(element);
            bodyFormData.append("file", finalFile, element.name);
        }
    }

    const url = API_BASE_URL + "/api/ticket/user";
    const headers = API_BASIC_HEADERS;

    let result;
    await trackPromise(
        result = axios.post(url, bodyFormData ,{headers})
    )
    return result;
}

function getImageTicket(id) {
    const url = API_BASE_URL + "/api/ticket/"+ id + "/image";
    let result;
    trackPromise(
        result = axios.get(url, {headers})
    )
    return result;
}

async function commentTicketService(state, id) {
    var bodyFormData = new FormData();
    bodyFormData.append("comment", draftToHtml(convertToRaw(state.comment.getCurrentContent())));

    if (state.files !== null) {
        for (const element of Array.from(state.files)) {
            let finalFile = await handleImageUpload(element);
            bodyFormData.append("files", finalFile, element.name);
        }
    }

    const url = API_BASE_URL + "/api/ticket/"+ id + "/comment";
    let result;
    trackPromise(
        result = axios.put(url, bodyFormData ,{headers: headersMultipart})
    )
    return result;
}

async function commentStudentTicketService(message, files, id) {
    var bodyFormData = new FormData();
    bodyFormData.append("comment", message);

    if (files !== null) {
        for (const element of Array.from(files)) {
            let finalFile = await handleImageUpload(element);
            bodyFormData.append("files", finalFile, element.name);
        }
    }

    const url = API_BASE_URL + "/api/ticket/"+ id + "/comment";
    let result;
    trackPromise(
        result = axios.put(url, bodyFormData ,{headers: headersMultipart})
    )
    return result;
}

function openTicket(id) {
    const url = API_BASE_URL + "/api/ticket/" + id + "/reopen";
    let result;
    trackPromise(
        result = axios.put(url ,{headers})
    )
    return result;
}
function assignedTicket(state) {
    const url = API_BASE_URL + "/api/ticket/" + state.reportId + "/data";
    let result;
    trackPromise(
        result = axios.put(url, Object.assign({}, {priority: state.priorityAsigned, category: state.categoryAsigned, userAssigned: state.ownerAsigned, department: state.officeAsigned}),{ headers } )
    )
    return result
}

function  getPrioritys () {
    const url = API_BASE_URL + "/api/prioritys/enabled";
    const headers = API_AUTENTICATION_HEADERS;
    let result;
    trackPromise(
        result = axios.get(url, { headers })
    )
    return result;
};

function getOwnersList(id, idCampus) {
    const url = API_BASE_URL + "/api/users/department/" + id + "/campus/" + idCampus;
    const headers = API_AUTENTICATION_HEADERS;
    let result;
    trackPromise(
        result = axios.get(url, { headers })
    )
    return result;
}

function getStudentInfo(id) {
    const url = API_BASE_URL + "/api/ticket/student/search/" + id;
    const headers = API_AUTENTICATION_HEADERS;
    let result;
    trackPromise(
        result = axios.get(url, { headers })
    )
    return result;
}


function getActivitysFiles(id) {
    const url = API_BASE_URL + "/api/ticket/activity/" + id + "/files";
    const headers = API_AUTENTICATION_HEADERS;
    let result;
    trackPromise(
        result = axios.get(url, { headers })
    )
    return result;
}

function getTicketFiles(id) {
    const url = API_BASE_URL + "/api/ticket/" + id + "/files";
    const headers = API_AUTENTICATION_HEADERS;
    let result;
    trackPromise(
        result = axios.get(url, { headers })
    )
    return result;
}

function getTicketDetail (id) {
    const url = API_BASE_URL + "/api/ticket/" + id;
    const headers = API_AUTENTICATION_HEADERS;
    let result;
    trackPromise(
        result = axios.get(url, { headers })
    )
    return result;
}

function getTicketImages (id) {
    const url = API_BASE_URL + "/api/ticket/" + id + "/files";
    const headers = API_AUTENTICATION_HEADERS;
    let result;
    trackPromise(
        result = axios.get(url, { headers })
    )
    return result;
}

function reassignTicket (state) {
    const url = API_BASE_URL + "/api/ticket/" + state.ticket.id + "/data";
    const headers = API_AUTENTICATION_HEADERS;
    let result;
    trackPromise(
        result = axios.put(url, Object.assign({}, { priority: state.priorityAsigned, category: state.categoryAsigned, userAssigned: state.ownerAsigned, department: state.officeAsigned }), { headers })
    )
    return result;
}

function reassignPriority (state, bodyFormData) {
    const url = API_BASE_URL + "/api/ticket/" + state.ticket.id + "/assigned/" + state.ownerAsigned;
    const headers = API_AUTENTICATION_HEADERS;

    let result;
    trackPromise(
        result = axios.put(url, bodyFormData, { headers })
    )
    return result;
}

function reassignResponsableFromList (state) {
    const url = API_BASE_URL + "/api/ticket/" + state.reportId + "/assigned/" + state.ownerAsigned;
    const headers = API_AUTENTICATION_HEADERS;

    let result;
    trackPromise(
        result = axios.put(url, {}, { headers })
    )
    return result;
}

function reassignResponsable (state) {
    const url = API_BASE_URL + "/api/ticket/" + state.ticket.id + "/assigned";
    const headers = API_AUTENTICATION_HEADERS;
    let result;
    trackPromise(
        result = axios.put(url, {}, { headers })
    )
    return result;
}

function closeTicketService (state, bodyFormData) {
    const url = API_BASE_URL + "/api/ticket/" + state.ticket.id + "/close";
    const headers = API_AUTENTICATION_HEADERS;
    let result;
    trackPromise(
        result = axios.put(url, bodyFormData, { headers })
    )
    return result;
}

function reopenTicket (state) {
    const url = API_BASE_URL + "/api/ticket/" + state.ticket.id + "/reopen";
    const headers = API_AUTENTICATION_HEADERS;
    let result;
    trackPromise(
        result = axios.put(url, {}, { headers })
    )
    return result;
}

function getAllDepartmensByUser () {
    const url = API_BASE_URL + "/api/user/ " + getUser().id +  "/departments";
    const headers = API_AUTENTICATION_HEADERS;
    let result;
    trackPromise(
        result = axios.get(url, {headers})
    )
    return result;
}

function getAllTicketsFilterBy (option) {
    const url = API_BASE_URL + "/api/tickets/filter";
    const headers = API_AUTENTICATION_HEADERS;

    let result;
    trackPromise(
        result = axios.post(url, Object.assign({}, {status: option}), {headers})
    )
    return result;
}

function getAllTickets () {
    const url = API_BASE_URL + "/api/tickets";
    const headers = API_AUTENTICATION_HEADERS;

    let result;
    trackPromise(
        result = axios.get(url, {headers})
    )
    return result;
}

function reassignDepartment (state) {
    const url = API_BASE_URL + "/api/ticket/" + state.reportId + "/assigned/department/" + state.department;
    const headers = API_AUTENTICATION_HEADERS;
    let result;
    trackPromise(
        result = axios.put(url, {},{ headers } )
    )
    return result;
}

function reassignDepartmentForDetail (state) {
    const url = API_BASE_URL + "/api/ticket/" + state.ticket.id + "/assigned/department/" + state.departmentAssigned;
    const headers = API_AUTENTICATION_HEADERS;
    let result;
    trackPromise(
        result = axios.put(url, {},{ headers } )
    )
    return result;
}


export { getTicketInfo, getImageTicket, commentTicketService, openTicket ,assignedTicket, createTicket, getPrioritys, getOwnersList,
    createTicketForm, getTicketDetailStudent, commentStudentTicketService, getTicketsQualify, getTicketActivitys, getValidTicketStudent, getTicketStudentQualify, getActivitysFiles, getTicketFiles, getTicketDetail, getTicketImages, reassignTicket, getStudentInfo,
    reassignPriority, reassignResponsable, closeTicketService, reopenTicket, getAllDepartmensByUser, getAllTickets, reassignDepartment, reassignDepartmentForDetail, reassignResponsableFromList, getAllTicketsFilterBy};

export async function handleImageUpload(imageFile) {

    //const imageFile = event.target.files[0];
    // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
    }
    try {
        const compressedFile = await imageCompression(imageFile, options);
        // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        // console.log(compressedFile.type)

        return (compressedFile); // write your own logic
    } catch (error) {
        // console.log("ERROR IN COMPRESS")
        // console.log(error);
        return imageFile
    }

}



