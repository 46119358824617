import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import landingImage from "../../../assets/img/faces/landingPage.jpeg"
// @material-ui/icons

// core components
import Header from "../../../components/Header/Header.js";
import Footer from "../../../components/Footer/Footer.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import Button from "../../../components/CustomButtons/Button.js";
import HeaderLinks from "../../../components/Header/HeaderLinks.js";
import Parallax from "../../../components/Parallax/Parallax.js";

import styles from "../../../assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import TeamSection from "./Sections/TeamSection.js";
import WorkSection from "./Sections/WorkSection.js";
// import { APP_TITLE } from "../../../assets/constants";
import Logo from '../../../assets/img/logo2.png';
import { Typography } from "@material-ui/core";


const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={Logo}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax filter image={landingImage}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={10}>
              <Typography variant="h1" className={classes.title}><br /><br />Escritorio de Ayuda </Typography>
              <Typography variant="h6" className={classes.content}>
                Damos solución a los problemas frecuentes de los estudiantes de <br /> CETYS en el uso del catálogo de <font face="Avenir Next LT Pro Bold"><b><h7 style={{ color: '#FFDD00' }}>Servicios Digitales</h7></b></font>,<br/> brindando un seguimiento y respuesta oportunos.<br />
              </Typography>
              <Typography variant="h6" className={classes.contentList}>
                <br />Inscripciones en línea <h8 style={{ color: '#FFDD00' }}>&bull;</h8> Correo electrónico <h9 style={{ color: '#FFDD00' }}>&bull;</h9> Portal Mi Campus <h10 style={{ color: '#FFDD00' }}>&bull;</h10> App CETYS Móvil <h11 style={{ color: '#FFDD00' }}>&bull;</h11> Blackboard <h12 style={{ color: '#FFDD00' }}>&bull;</h12> Biblioteca digital
              </Typography>
              <br />
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductSection />
          <WorkSection />
        </div>
      </div>
      <Footer />
    </div>
  );
}
