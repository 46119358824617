import { title } from "../../../material-kit-react";

const productStyle = {
  section: {
    padding: "50px 0",
    textAlign: "center",
  },
  title: {
    marginBottom: "1rem",
    marginTop: "10px",
    minHeight: "32px",
    textDecoration: "none",
    fontFamily: 'Avenir Next LT Pro Bold',
    color: '#000000',
  },
  content: {
    fontFamily: 'Avenir Next LT Pro Bold',
    color: '#000000',
  },
  description: {
    color: '#000000',
    fontFamily: 'Avenir Next LT Pro Regular'
  },
  yellow_text: {
    color: '#FFDD00',
    fontFamily: 'Avenir Next LT Pro Regular'
  }
};

export default productStyle;
